<button
    #menuTrigger="matMenuTrigger"
    mat-button
    color="primary"
    [disabled]="isDisabled"
    data-unit="channel-button"
    [matMenuTriggerFor]="channelsMenu"
    [class.active]="menuTrigger.menuOpen"
    class="u-button flat-btn h-32 root-button {{ 'type-' + 'buttonType' }}"
    (keydown.esc)="menuTrigger.closeMenu()"
>
    <channel-icon [channel]="value" [size]="16"></channel-icon>
    <span>{{ !value ? 'N/A' : value.title }}</span>
    <mat-icon class="s-12 ml-4" svgIcon="expandMore" iconPositionEnd></mat-icon>
</button>

<mat-menu #channelsMenu="matMenu" overlapTrigger="false" class="u-dropdown theme-light small select-channel-dropdown">
    <button
        *ngFor="let item of availableChannels"
        mat-menu-item
        fxLayoutAlign="space-between center"
        class="menu-item-button"
        [ngClass]="{ disabled: item?.disabled }"
        (click)="_select($event, item)"
        [matTooltip]="
            item.restricted && showRestrictedTooltip
                ? 'Contact us to open ' + item.title + ' advertising channel.'
                : null
        "
    >
        <span>
            <channel-icon [channel]="item"></channel-icon>
            {{ item.title }}
        </span>
    </button>

    <ng-container *ngIf="!promotionMode">
        <button
            *ngFor="let item of restrictedChannels"
            mat-menu-item
            fxLayoutAlign="space-between center"
            class="menu-item-button"
            [ngClass]="{ disabled: item?.disabled }"
            (click)="_select($event, item)"
            [matTooltip]="
                item.restricted && showRestrictedTooltip
                    ? 'Contact us to open ' + item.title + ' advertising channel.'
                    : null
            "
        >
            <span class="disablable">
                <channel-icon [channel]="item"></channel-icon>
                {{ item.title }}
            </span>
            <span class="disablable">
                <mat-icon *ngIf="item?.restricted" class="s-16 ml-4 mr-0 mb-0" iconPositionEnd>lock</mat-icon>
            </span>
        </button>
    </ng-container>

    <ng-container *ngIf="promotionMode">
        <div class="unlock-box">
            <p class="label">Unlock {{ lockedChannelsText }}</p>

            <button (click)="onUnlockClick()" class="btn-unlock">
                <span>Unlock</span>
            </button>
        </div>
    </ng-container>
</mat-menu>
