import { Component, ContentChild, ElementRef, EventEmitter, HostBinding, Input, OnInit, Output } from '@angular/core';
import { WIDGET_STATUS, WidgetInfo } from '@shared/widgets/core/widgets.types';
import { ExportAsImageHeaderOptions } from '@shared/components/widget';
import { FullscreenViewService } from '@main/shared/widget-full-screen-view/services/fullscreen-view.service';
import { EntityHelpers } from '@main/helpers/entity.helpers';
import { MenuSchema } from '@main/dashboards/interfaces';
import { WIDGET_VIEW_MODE } from '@main/widgets/shared/widget.types';
import { Helpers } from './widget-card.helpers';
import { LayoutPreset } from '@shared/components/widget/widget-card/layout-widget-preset.types';
import { Subject } from 'rxjs';
import { AppConfig } from '@app/app.config';
import { INTEGRATION } from '@app/integrations/integrations.types';

@Component({
    selector: 'widget-card',
    templateUrl: './widget-card.component.html',
    styleUrls: ['./widget-card.component.scss'],
})
export class WidgetCardComponent implements OnInit {
    @Input() title: string;
    @Input() widgetInfo: WidgetInfo;
    @Input() status: WIDGET_STATUS;
    @Input() hasData: boolean;
    @Input() extraExportImageOptions: ExportAsImageHeaderOptions;
    @Input() layoutPreset: LayoutPreset = LayoutPreset.Large;

    @Input() showHeader = true;
    @Input() footer: boolean;
    @Input() contentCls: string;
    grabbing = false;

    @Output() menuAction = new EventEmitter<MenuSchema>();

    @ContentChild('cardHeaderSearch') set cardHeaderSearch(element: ElementRef | undefined) {
        this.hasCardHeaderSearchProjection = !!element;
    }
    hasCardHeaderSearchProjection: boolean;

    @ContentChild('cardHeaderTabs') set cardHeaderTabs(element: ElementRef | undefined) {
        this.hasCardHeaderTabsProjection = !!element;
    }
    hasCardHeaderTabsProjection: boolean;

    @ContentChild('cardHeaderExport') set cardHeaderExport(element: ElementRef | undefined) {
        this.hasCardHeaderExportProjection = !!element;
    }
    hasCardHeaderExportProjection: boolean;

    @HostBinding('class.full-screen-view-mode')
    get fullScreenViewMode(): boolean {
        return this.fullScreenViewService.isFullscreenView;
    }

    get showMenu(): boolean {
        return this.widgetInfo.data.viewMode === WIDGET_VIEW_MODE.DASHBOARD && !this.fullScreenViewMode;
    }

    get isDashboardViewMode(): boolean {
        return this.widgetInfo.data.viewMode === WIDGET_VIEW_MODE.DASHBOARD;
    }

    get canDrag(): boolean {
        return this.widgetInfo.data.canDrag;
    }

    get canResize(): boolean {
        return this.widgetInfo.componentRef?.instance?.canResize && !this.fullScreenViewMode;
    }

    get isWinmoIntegration(): boolean {
        return this.appConfig.appInfo.integrationId === INTEGRATION.WINMO;
    }

    entityTypeName: string;

    menuSchema: MenuSchema[] = [];

    entityListLoaded$ = new Subject<boolean>();

    constructor(private fullScreenViewService: FullscreenViewService, private appConfig: AppConfig) {}

    onMenuEvent(event: MenuSchema): void {
        this.menuAction.emit(event);
    }

    ngOnInit() {
        const { entityType, canDelete, canDuplicate } = this.widgetInfo.data;

        this.entityTypeName = EntityHelpers.getEntityInfo(entityType).text.fewUpper;
        this.menuSchema = Helpers.generateItemMenuSchema(canDelete, canDuplicate);
    }

    dragStart() {
        const bodyElement: HTMLElement = document.body;
        bodyElement.style.cursor = 'grabbing';
        this.grabbing = true;
    }

    drop() {
        const bodyElement: HTMLElement = document.body;
        bodyElement.style.cursor = 'unset';
        this.grabbing = false;
    }

    protected readonly LayoutPreset = LayoutPreset;
}
