<div
    class="adc-card auto-width px-24 py-20"
    *ngIf="isDashboardViewMode; else noDragTemplate"
    cdkDrag
    (cdkDragStarted)="dragStart()"
    (cdkDragDropped)="drop()"
>
    <ng-container *ngTemplateOutlet="widgetTemplate"></ng-container>
    <div class="drag-wrapper">
        <div
            *ngIf="canDrag"
            cdkDragHandle
            [class.clicked]="grabbing"
            (mousedown)="grabbing = true"
            (mouseup)="grabbing = false"
            class="action-icon drag"
        >
            <mat-icon class="s-16" svgIcon="dragIndicator"></mat-icon>
        </div>
        <div *ngIf="canResize" class="action-icon resize" mwlResizeHandle [resizeEdges]="{ right: true }">
            <mat-icon class="s-16" svgIcon="resize"></mat-icon>
        </div>
    </div>
</div>
<ng-template #noDragTemplate>
    <div class="adc-card auto-width px-24 py-20">
        <ng-container *ngTemplateOutlet="widgetTemplate"></ng-container>
    </div>
</ng-template>

<ng-template #widgetTemplate>
    <widget-export-as-image-header [widgetInfo]="widgetInfo" [title]="title" [options]="extraExportImageOptions">
        <div filters fxLayout="row" fxLayoutAlign="start center">
            <ng-content select="[export-as-image-header-filters]"></ng-content>
        </div>
    </widget-export-as-image-header>

    <div class="header-wrapper">
        <div class="header-title">
            <ng-content *ngIf="showHeader" select="[card-header-title]"></ng-content>
            <ng-container *ngIf="layoutPreset !== LayoutPreset.Small">
                <div *ngIf="hasCardHeaderSearchProjection" class="widget-toolbar-divider-v export-hide"></div>
                <ng-content select="[card-header-search]"></ng-content>
            </ng-container>
        </div>
        <div class="tabs-and-controls__l">
            <ng-container class="controls" *ngIf="layoutPreset !== LayoutPreset.Small">
                <ng-content select="[card-header-tabs]"></ng-content>
                <div
                    *ngIf="
                        hasCardHeaderExportProjection &&
                        hasCardHeaderTabsProjection &&
                        !fullScreenViewMode &&
                        !isWinmoIntegration
                    "
                    class="widget-toolbar-divider-v export-hide"
                ></div>
            </ng-container>
            <ng-content select="[card-header-export]"></ng-content>
        </div>
        <menu-button
            *ngIf="showMenu"
            [isStroked]="true"
            [menuSchema]="menuSchema"
            (onEvent)="onMenuEvent($event)"
        ></menu-button>
    </div>

    <ng-container *ngIf="isDashboardViewMode">
        <div class="sub-header">
            <div class="entityType">{{ entityTypeName }}</div>

            <div class="circle"></div>
            <div fxLayout fxLayoutAlign="start center">
                <channel-icon [channel]="widgetInfo.data.filters.channel"></channel-icon>
                <div>{{ widgetInfo.data.filters.channel.title }}</div>
            </div>
            <div class="circle"></div>
            <div fxLayout fxLayoutAlign="start center">
                <device-icon [device]="widgetInfo.data.filters.device"></device-icon>
                <div>{{ widgetInfo.data.filters.device.title }}</div>
            </div>
        </div>
    </ng-container>

    <div class="tabs-and-controls__s" *ngIf="layoutPreset === LayoutPreset.Small">
        <ng-content select="[card-header-tabs-s]"></ng-content>
        <ng-content select="[card-header-search-s]"></ng-content>
    </div>

    <div class="entities-container" *ngIf="isDashboardViewMode">
        <div class="flex mbs mt-24" *ngIf="!(entityListLoaded$ | async)">
            <div class="ssc-square tag"></div>
            <div class="ssc-square tag"></div>
            <div class="ssc-square tag"></div>
            <div class="ssc-square tag"></div>
        </div>
        <div *ngIf="widgetInfo.data?.entities" class="entities-wrapper mt-24" #wrapper>
            <multiline-entities-list
                [entities]="widgetInfo.data.entities"
                [reloadOnResize]="true"
                [isComparison]="widgetInfo.data.comparison"
                [parent]="wrapper"
                (animationDone)="entityListLoaded$.next($event)"
            ></multiline-entities-list>
        </div>
    </div>

    <div class="content-area w-100-p" [ngClass]="contentCls">
        <widget-status [status]="status" [title]="title" [hasData]="hasData" [type]="widgetInfo.type"></widget-status>

        <ng-content [ngIf]="hasData" select="[card-content]"></ng-content>
    </div>

    <ng-content *ngIf="footer" select="[card-footer]"></ng-content>

    <widget-export-as-image-footer></widget-export-as-image-footer>
</ng-template>
