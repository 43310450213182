<button
    #menuTrigger="matMenuTrigger"
    mat-button
    color="primary"
    [disabled]="isDisabled || value?.restricted"
    data-unit="country-button"
    [matMenuTriggerFor]="countriesMenu"
    [ngClass]="'buttonClass'"
    [class.active]="menuTrigger.menuOpen"
    class="u-button flat-btn h-32 export-button"
    (keydown.esc)="menuTrigger.closeMenu()"
    (menuOpened)="onOpen()"
>
    <img
        *ngIf="value"
        alt="flag"
        src="/assets/images/flags_iso/24/{{ flagCodeByCountry(value) }}.png"
        data-unit="country-flag-image"
    />

    <span>{{ !value ? 'N/A' : value.code.toUpperCase() }}</span>
    <mat-icon class="s-12 ml-4" svgIcon="expandMore" iconPositionEnd></mat-icon>
</button>

<mat-menu #countriesMenu="matMenu" overlapTrigger="false" class="u-dropdown theme-light small select-country-dropdown">
    <search-input-control
        size="small"
        (searchChange)="onSearchChange($event)"
        [focused]="true"
        [keepFocus]="true"
        [stopClickPropagation]="true"
        [value]="searchValue"
        data-unit="country-menu-search"
    >
    </search-input-control>

    <h5 *ngIf="!shownCountries.length" class="empty" data-unit="country-menu-empty">No countries found</h5>

    <div
        class="items scrollable-container"
        #scrollBar
        perfectScrollbar
        [perfectScrollbarOptions]="{ suppressScrollX: true }"
    >
        <ng-template ngFor let-i="index" let-item [ngForOf]="shownCountries">
            <button
                mat-menu-item
                [ngClass]="{ disabled: item?.disabled }"
                *ngIf="!item.restricted"
                (click)="_select($event, item)"
                fxLayoutAlign="space-start center"
                matTooltip="{{ item?.disabled ? 'No data available for this country' : '' }}"
                [attr.tabindex]="i"
                data-unit="country-menu-item"
            >
                <img
                    *ngIf="value"
                    alt="flag"
                    class="mr-8 mb-0"
                    src="/assets/images/flags_iso/24/{{ flagCodeByCountry(item) }}.png"
                    data-unit="country-menu-item-image"
                />
                <span data-unit="country-menu-item-title">{{ item.title }}</span>
            </button>
        </ng-template>
    </div>
</mat-menu>
