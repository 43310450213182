import { Component, Input, OnChanges, OnInit } from '@angular/core';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import Highcharts, { GradientColorObject, PatternObject } from 'highcharts';
import { GenericChart } from '../../libs/generic-chart';

interface LegendItem {
    name: string;
    color: string | GradientColorObject | PatternObject;
    hovered: boolean;
}

@UntilDestroy()
@Component({
    selector: 'adc-chart-legend',
    templateUrl: './legend.component.html',
    styleUrls: ['./legend.component.scss'],
})
export class ChartLegendComponent implements OnInit, OnChanges {
    @Input() adcChart: GenericChart;

    items: LegendItem[];

    get chartInstance(): Highcharts.Chart {
        return this.adcChart.chartInstance;
    }

    ngOnInit(): void {
        this.adcChart.pointHover$.pipe(untilDestroyed(this)).subscribe(([index, hovered]) => {
            this.items.forEach((v) => (v.hovered = false));
            this.items[index].hovered = hovered;
        });
    }

    ngOnChanges(): void {
        const data = this.chartInstance && this.chartInstance.series[0] && this.chartInstance.series[0].data;

        this.items = data?.map(({ name, color }) => ({ name, color, hovered: false })) || [];
    }

    hover(index: number, hovered: boolean): void {
        this.adcChart.legendHover$.next([index, hovered]);
    }
}
