export enum WIDGET {
    ESTIMATED_EXPENDITURE = 'ESTIMATED_EXPENDITURE',
    AD_TYPES_DISTRIBUTION = 'AD_TYPES_DISTRIBUTION',
    AD_PLACEMENT = 'AD_PLACEMENT',
    TRANSACTION_METHODS = 'TRANSACTION_METHODS',
    TRANSACTION_METHODS_DETAILS = 'TRANSACTION_METHODS_DETAILS',
    TOP_ADVERTISERS_PUBLISHERS = 'TOP_ADVERTISERS_PUBLISHERS',
    TOP_ADS = 'TOP_ADS',
    TOP_CAMPAIGNS = 'TOP_CAMPAIGNS',
    EXPENDITURE_TREND_AND_BREAKDOWN = 'EXPENDITURE_TREND_AND_BREAKDOWN',
    EXPENDITURE_TREND = 'EXPENDITURE_TREND',
    EXPENDITURE_BREAKDOWN = 'EXPENDITURE_BREAKDOWN',
    YOUTUBE_CHANNELS = 'YOUTUBE_CHANNELS',
    MOBILE_PLATFORMS = 'MOBILE_PLATFORMS',
    TOP_APPS = 'TOP_APPS',
    CAMPAIGN_HEADER = 'CAMPAIGN_HEADER',
    BRAND_HEADER = 'BRAND_HEADER',
    KEYWORDS_SUMMARY = 'KEYWORDS_SUMMARY',
    CATEGORY_CHANNEL_SOV_BREAKDOWN = 'CATEGORY_CHANNEL_SOV_BREAKDOWN',
    CATEGORY_MARKET_LEADER_BRANDS = 'CATEGORY_MARKET_LEADER_BRANDS',
    CATEGORY_MARKET_LEADERS = 'CATEGORY_MARKET_LEADERS',
    CATEGORY_PUBLISHERS = 'CATEGORY_PUBLISHERS',
    COMPARISON_AD_BUYING_METHODS = 'COMPARISON_AD_BUYING_METHODS',
    COMPARISON_SOV = 'COMPARISON_SOV',
    COMPARISON_EXPENDITURE_TREND = 'COMPARISON_EXPENDITURE_TREND',
    COMPARISON_EXPENDITURE_BREAKDOWN = 'COMPARISON_EXPENDITURE_BREAKDOWN',
    COMPARISON_EXPENDITURE_TREND_AND_BREAKDOWN = 'COMPARISON_EXPENDITURE_TREND_AND_BREAKDOWN',
    COMPARISON_ADVERTISERS_PUBLISHERS = 'COMPARISON_ADVERTISERS_PUBLISHERS',
    STARTPAGE_TOP_ITEMS = 'STARTPAGE_TOP_ITEMS',
}
