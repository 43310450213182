import { Params, Router } from '@angular/router';
import { inject, Injectable } from '@angular/core';
import { forkJoin, Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';

import { AppConfig } from '@app/app.config';
import { FiltersValue } from '@app/shared/services/filters/filters.types';
import { FeatureToggleService } from '@main/shared/feature-toggle';
import { Country, Currency, Period } from '@shared/models';
import { PeriodHelpers } from '@shared/helpers/period.helpers';
import { PersistedSettingsService, SETTINGS } from '@shared/services';
import { CommonHelpers } from '@shared/helpers/common.helpers';
import { REPORT_CONFIG } from '@app/shared/const/report-config';
import { RouteIdsItem } from '../types/types';
import { Channel, Device, EntityItem, TaxonomyTopic } from '../models';
import { BUSINESS_ENTITY, CHANNEL } from '../enums';
import { DatasetsData } from '../core/report.types';
import { ReportApiService } from '../core/report.api-service';
import { EntityHelpers } from '../helpers/entity.helpers';
import { EntityBase } from '../models/EntityBase';
import { ReportService } from '../core/report.service';
import { NavigationService } from './navigation.service';
import { SerializedFiltersParams } from '@main/helpers/entity.helpers.types';
import { ITaxonomyTopic } from '@app/shared/interfaces';
import { Feature } from '../shared/feature-toggle/feature-toggle.model';

@Injectable()
export abstract class EntityService {
    protected readonly reportApiService = inject(ReportApiService);
    protected readonly persistedSettingsService = inject(PersistedSettingsService);
    protected readonly featureToggleService = inject(FeatureToggleService);
    protected readonly router = inject(Router);
    protected readonly navigationService = inject(NavigationService);
    protected readonly reportService = inject(ReportService);
    protected readonly reportConfig = inject(REPORT_CONFIG);
    protected readonly appConfig = inject(AppConfig);

    get queryParams(): Params {
        return this.router.routerState.snapshot.root.queryParams;
    }

    parseRouteIds(str: string): RouteIdsItem[] {
        const parsed = str.match(/\(.+?\)|([^,]+?:)|([a-z0-9\-]+)|(\d+)/g);
        let result: RouteIdsItem[] = [];

        if (!parsed) {
            return result;
        }

        for (const v of parsed) {
            /* virtual group name */
            if (v.slice(-1) === ':') {
                result = this.parseRouteIds(parsed.slice(1).join(','));
                result[Symbol.for('name')] = decodeURI(v.slice(0, -1));

                break;
            }

            /* virtual group */
            if (v[0] === '(' && v.slice(-1) === ')') {
                const r = this.parseRouteIds(v.substring(1, v.length - 1));
                result.push(r);
                continue;
            }

            if (EntityHelpers.isSingleInstanceId(v)) {
                result.push(Number(v));
                continue;
            }

            if (EntityHelpers.isGroupId(v)) {
                result.push(v);
            }
        }

        return result;
    }

    getDatasetsData(entities?: EntityItem[]): Observable<DatasetsData> {
        const entitiesIdsPairs =
            entities &&
            EntityHelpers.serializeEntitiesIdsPairs(
                EntityHelpers.isItemsSingleGroup(entities) ? EntityHelpers.unwrapSingleGroup(entities) : entities,
            );

        return forkJoin([
            this.reportApiService.getChannels(entitiesIdsPairs),
            this.reportApiService.getPeriods(entitiesIdsPairs),
            this.reportApiService.getCountries(entitiesIdsPairs),
            this.reportApiService.getCountries(),
        ]).pipe(
            map(([channels, periods, countries, allCountries]) => ({
                channels,
                periods,
                countries,
                allCountries,
            })),
        );
    }

    getDefaultChannel(
        channels: Channel[],
        usePersistedChannel = true,
        preferredFilters: Partial<SerializedFiltersParams>,
        preserveQueryParams: boolean,
    ): Channel {
        const { channel: queryValue } = this.queryParams;
        const [channelType = null] = queryValue?.split('-') || [];

        const [preferredChannelType = null] = preferredFilters?.channel?.split('-') || [];

        const availableChannels = channels.filter((v) => !v.disabled && !v.restricted);
        const availableChannelsExceptAll = availableChannels.filter((v) => v.type !== CHANNEL.ALL);

        let channel: Channel;

        if (channelType) {
            channel = availableChannels.find((v) => v.type === channelType);
            !preserveQueryParams && this.navigationService.removeQueryParam('channel', true);
        }

        if (availableChannelsExceptAll.length === 1) return availableChannelsExceptAll[0];

        if (!channel && preferredChannelType) {
            channel = availableChannels.find((v) => v.type === preferredChannelType);
        }

        if (!channel && usePersistedChannel) {
            const persistedId = this.persistedSettingsService.get<number>(SETTINGS.ROOT_CHANNEL);

            if (persistedId) channel = availableChannels.find((v) => v.id === persistedId);
        }

        if (!channel) channel = availableChannels.find((v) => v.type === CHANNEL.ALL);

        if (!channel) channel = availableChannels[0];

        return channel;
    }

    getDefaultDevice(
        devices: Device[],
        usePersistedDevice = true,
        preferredFilters: Partial<SerializedFiltersParams>,
        preserveQueryParams: boolean,
    ): Device {
        const { channel: queryValue } = this.queryParams;
        const [, deviceCode = null] = queryValue?.split('-') || [];

        const [, preferredDeviceCode] = preferredFilters?.channel?.split('-') || [];

        let device: Device;
        const availableDevices = devices.filter((v) => !v.disabled && !v.restricted);

        if (deviceCode) {
            device = availableDevices.find((v) => v.code === deviceCode);
            !preserveQueryParams && this.navigationService.removeQueryParam('device', true);
        }

        if (!device && preferredDeviceCode) {
            device = availableDevices.find((v) => v.code === preferredDeviceCode);
        }

        if (!device && usePersistedDevice) {
            const persistedId = this.persistedSettingsService.get<string>(SETTINGS.ROOT_DEVICE);

            if (persistedId) device = availableDevices.find((v) => v.code === persistedId);
        }

        if (!device) device = availableDevices[0];

        return device;
    }

    getDefaultCountry(
        countries: Country[],
        usePersistedCountry = true,
        preferredFilters: Partial<SerializedFiltersParams>,
        preserveQueryParams: boolean,
    ): Country {
        const { country: queryValue } = this.queryParams;

        const preferredCountry = preferredFilters?.country || null;
        const availableCountries = countries.filter((v) => !v.disabled && !v.restricted);
        const nonRestrictedCountries = countries.filter((v) => !v.restricted);

        let bestCountry;

        if (queryValue) {
            bestCountry = availableCountries.find(({ id, code }) => `${id}` === queryValue || code === queryValue);
            !preserveQueryParams && this.navigationService.removeQueryParam('country', true);
        }

        if (!bestCountry && preferredCountry) {
            bestCountry = availableCountries.find(
                ({ id, code }) => `${id}` === preferredCountry || code === preferredCountry,
            );
        }

        if (!bestCountry && usePersistedCountry) {
            const persistedId = this.persistedSettingsService.get<number>(SETTINGS.ROOT_COUNTRY);

            if (persistedId) bestCountry = availableCountries.find((v) => v.id === persistedId);
        }

        const configValue = this.appConfig.appInfo.controlsToolbar.defaultFilters.country?.toLowerCase();
        if (!bestCountry && configValue) bestCountry = availableCountries.find((v) => v.code === configValue);

        if (!bestCountry) bestCountry = availableCountries[0];

        if (!bestCountry) bestCountry = nonRestrictedCountries[0];

        return bestCountry;
    }

    getDefaultPeriod(
        periods: Period[],
        usePersistedPeriod = true,
        preferredFilters: Partial<SerializedFiltersParams>,
        preserveQueryParams: boolean,
    ): Period {
        const { period: queryValue } = this.queryParams;
        const hasDataPeriods = periods.filter((v) => !v.restricted && !v.disabled);
        const preferredPeriod = preferredFilters?.period || null;

        let period: Period;

        if (queryValue) {
            period = periods.find((v) => v.id.toString() === queryValue);
            !preserveQueryParams && this.navigationService.removeQueryParam('period', true);
        }

        if (!period && preferredPeriod) {
            period = hasDataPeriods.find((v) => v.id === preferredPeriod);
        }

        if (!period && usePersistedPeriod) {
            const persistedId = this.persistedSettingsService.get<string>(SETTINGS.ROOT_PERIOD);

            if (persistedId) period = PeriodHelpers.getPeriodByPersistedValue(hasDataPeriods, persistedId);
        }

        const configValue = this.appConfig.appInfo.controlsToolbar.defaultFilters.period;
        if (!period && configValue) {
            period = hasDataPeriods.find((v) => v.code === configValue);
        }

        if (!period) {
            period = CommonHelpers.findMinimalDefaultPeriod(hasDataPeriods);
        }

        const periodMaxMonths = this.getPeriodMaxMonthsLimit();

        if (periodMaxMonths) {
            if (!period || CommonHelpers.getPeriodMonthsCount(period) > periodMaxMonths) {
                period = CommonHelpers.findPeriodToFitMonthsCount(hasDataPeriods, periodMaxMonths);
            }
        } else {
            if (!period) {
                period = hasDataPeriods[0];
            }
        }

        return period;
    }

    getTaxonomyTopics(entities: EntityItem[], filters: FiltersValue, currency: Currency): Observable<TaxonomyTopic[]> {
        const entitiesIdsPairs =
            entities &&
            EntityHelpers.serializeEntitiesIdsPairs(
                EntityHelpers.isItemsSingleGroup(entities) ? EntityHelpers.unwrapSingleGroup(entities) : entities,
            );

        const taxonomyTopics$: Observable<ITaxonomyTopic[]> =
            this.featureToggleService.isEnabled(Feature.TAXONOMY) && this.reportConfig.hasTaxonomy
                ? this.reportApiService.getTaxonomyTopics(entitiesIdsPairs, filters, currency)
                : of([]);

        return taxonomyTopics$.pipe(map((topics) => topics.map(TaxonomyTopic.fromData)));
    }

    getDefaultTaxonomyTopic(
        topics: TaxonomyTopic[],
        usePersistedTaxonomyTopic = true,
        preserveQueryParams: boolean,
    ): TaxonomyTopic | undefined {
        const { taxonomy: queryValue } = this.queryParams;
        let topic: TaxonomyTopic;

        if (queryValue) {
            topic = this.findTopicById(topics, queryValue);
            !preserveQueryParams && this.navigationService.removeQueryParam('taxonomy', true);
        }

        if (!topic && usePersistedTaxonomyTopic) {
            const persistedId = this.persistedSettingsService.get<string>(SETTINGS.ROOT_TAXONOMY_ID);

            if (persistedId) {
                topic = this.findTopicById(topics, persistedId);
            }
        }

        return topic;
    }

    getPeriodMaxMonthsLimit(): number {
        return this.reportConfig.periodMaxMonths;
    }

    abstract resolveEntitiesByIds<T extends EntityBase>(
        ids: RouteIdsItem[],
        entityType?: BUSINESS_ENTITY,
    ): Observable<T[]>;

    private findTopicById(topics: TaxonomyTopic[], id: string): TaxonomyTopic | undefined {
        for (const topic of topics) {
            if (topic.id === id) {
                return topic;
            }
            if (topic.children && topic.children.length > 0) {
                const foundTopic = this.findTopicById(topic.children, id);
                if (foundTopic) {
                    return foundTopic;
                }
            }
        }
        return undefined;
    }
}
