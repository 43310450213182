import { ComponentRef } from '@angular/core';
import { Observable } from 'rxjs';

import { FiltersValue } from '@shared/services/filters/filters.types';
import { Currency } from '@shared/models';
import { BUSINESS_ENTITY, CHANNEL, WIDGET } from '@main/enums';
import { EntityItem } from '@main/models';
import { WidgetBase } from '@main/widgets/shared/widget-base';
import { WIDGET_VIEW_MODE } from '@main/widgets/shared/widget.types';
import { BenchmarkInfo, ReportDeviationData } from '@main/core/report.types';

export enum WIDGET_STATUS {
    INIT = 'INIT',
    LOADING = 'LOADING',
    SUCCESS = 'SUCCESS',
    ERROR = 'ERROR',
}

export type WidgetSize = 4 | 6 | 8 | 12;

export type WidgetConfig = Record<string, unknown>;

export type WidgetSpecialProps = Partial<{
    noWinmoPreviewMode: boolean;
    isWinmoBluredView: boolean;
}>;

export interface WidgetDefinition {
    type: WIDGET;
    component: any;
    rule?: WidgetVisibilityRule;
    rulesAnd?: WidgetVisibilityRule[];
    rulesOr?: WidgetVisibilityRule[];
    config?: WidgetConfig;
    props?: WidgetDefinitionProps;
    wrapperFor?: WIDGET[];
    wrapperGroupId?: number;
    size?: WidgetSize;
    index?: number;
}

export interface WidgetInfo<C = WidgetConfig> {
    id: string;
    type: WIDGET;
    component: any;
    componentRef: ComponentRef<WidgetBase>;
    elementRef: HTMLElement;
    status: WIDGET_STATUS;
    config: C;
    props: WidgetDefinitionProps;
    wrapperFor: WIDGET[];
    wrapperGroupId: number;
    size: number;
    index: number;
    data: WidgetData;
}

export interface WidgetData {
    entityType: BUSINESS_ENTITY;
    entities: EntityItem[];
    filters: FiltersValue;
    currency: Currency;
    viewMode: WIDGET_VIEW_MODE;
    comparison: boolean;
    isBenchmark: boolean;
    benchmarkInfo: BenchmarkInfo;
    deviation$: Observable<ReportDeviationData>;

    canDelete?: boolean;
    canDuplicate?: boolean;
    canDrag?: boolean;
}

export type WidgetDefinitionProps = Record<string, any>;

/*
 * Rules:
 *   Rule: object of criterias or array of them
 *
 *   Filtering criterias:
 *     channels: [<Channel>, ...]
 *     devices: [<Device>, ...]
 *     custom: (entities: EntityItem: [], filters: FiltersValue) => boolean
 *
 *   Rules filtering behavior is "AND"
 *   To filter by OR use "anyOf" wrapper
 *
 *   Operators:
 *     not: true if all criterias are false
 */

export interface WidgetVisibilityRule {
    channels?: CHANNEL[];
    devices?: string[];
    custom?: (entities: EntityItem[], filters: FiltersValue) => boolean;
    not?: WidgetVisibilityRule;
}
