import { Component, Input, Output, EventEmitter, SimpleChanges, OnChanges, AfterViewInit } from '@angular/core';
import { _MatAutocompleteBase } from '@angular/material/autocomplete';
import { UntypedFormGroup, UntypedFormBuilder } from '@angular/forms';
import { debounceTime, distinctUntilChanged, map } from 'rxjs/operators';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { isNil } from 'lodash';

@UntilDestroy()
@Component({
    selector: 'search-input-control',
    templateUrl: './search-input-control.component.html',
    styleUrls: ['./search-input-control.component.scss'],
})
export class SearchInputControlComponent implements OnChanges, AfterViewInit {
    @Input() focused = false;
    @Input() keepFocus = false;
    @Input() stopClickPropagation = false;
    @Input() canClose = false;
    @Input() controlCls = '';
    @Input() resetOnBlur = false;
    @Input() value: string;
    @Input() size = 'normal';
    @Input() searchIconSize = 18;
    @Input() closeIconSize = 10;
    @Input() placeholderText = 'Search...';
    @Input() autocomplete: _MatAutocompleteBase;
    @Input() iconPosition: 'left' | 'right' = 'left';

    @Output() searchChange = new EventEmitter<string>();
    @Output() valueChange = new EventEmitter<string>();
    @Output() focus = new EventEmitter<void>();
    @Output() blur = new EventEmitter<void>();
    @Output() close = new EventEmitter<void>();

    searchForm: UntypedFormGroup;

    private inFocus = false;

    constructor(private formBuilder: UntypedFormBuilder) {
        this.searchForm = this.formBuilder.group({
            value: [''],
        });

        this.searchForm.valueChanges
            .pipe(
                untilDestroyed(this),
                debounceTime(500),
                map((form) =>
                    form.value
                        ?.trim()
                        .toLowerCase()
                        .replace(/[\'\"\%]/g, ''),
                ),
                distinctUntilChanged(),
            )
            .subscribe((value) => {
                if (!isNil(value)) {
                    this.searchChange.emit(value);
                    this.valueChange.emit(value);
                }
            });
    }

    onInputClick($event: Event): void {
        if (this.inFocus) {
            $event.stopPropagation();
        }
    }

    onFocus(): void {
        setTimeout(() => {
            this.inFocus = true;

            this.focus.emit();
        }, 300);
    }

    onBlur(): void {
        this.inFocus = false;

        if (this.keepFocus) {
            this.setFocus();
            return;
        }

        // delay to allow emit 'searchChange' first
        setTimeout(() => {
            this.blur.emit();

            if (this.resetOnBlur) {
                this.resetValue();
            }
        }, 300);
    }

    onCloseClick(): void {
        this.close.emit();
    }

    onFormClick($event: Event): void {
        if (this.stopClickPropagation) {
            $event.stopPropagation();
        }
    }

    ngAfterViewInit(): void {
        if (this.focused) {
            this.setFocus();
        }
    }

    ngOnChanges(changes: SimpleChanges): void {
        const searchControl = this.searchForm.get('value');

        if (changes.value) {
            searchControl.setValue(this.value, { emitEvent: false });

            if (this.focused) {
                this.setFocus();
            }
        }
    }

    private setFocus(): void {
        const searchControl = this.searchForm.get('value') as any;

        setTimeout(() => {
            searchControl.nativeElement?.focus();
            searchControl.nativeElement?.click();
        }, 100);
    }

    private resetValue(): void {
        this.searchForm.get('value').setValue('');
    }
}
