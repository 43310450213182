import { Injectable } from '@angular/core';
import { forkJoin, from, Observable, partition } from 'rxjs';
import { groupBy, map, mergeMap, reduce, shareReplay, switchMap } from 'rxjs/operators';

import { Category, CategoryDictionary, CategoryDictionaryMap, CategoryMap, SubcategoryMap } from '../models';
import { AllCategoriesApiService } from './all-categories.api.service';
import { categoryDictionary } from '@main/reports/categories/data/category.data';

@Injectable()
export class AllCategoriesService {
    private categories$: Observable<Category[]>;
    private categoryMap$: Observable<CategoryMap>;
    private subCategoryMap$: Observable<SubcategoryMap>;

    private categoriesRawData$ = this.apiService.fetchCategories().pipe(shareReplay(1));

    dictionaries$: Observable<CategoryDictionary[]>;

    constructor(private apiService: AllCategoriesApiService) {
        this.initCategoryMaps();
        this.initCategories();
        this.initDictionaries();
    }

    private initCategoryMaps(): void {
        const [categories$, subcategories$] = partition(
            this.categoriesRawData$.pipe(switchMap((data) => from(data))),
            ({ parent }) => !parent,
        );

        this.categoryMap$ = categories$.pipe(
            reduce((acc, current) => {
                acc[current.id] = current;

                return acc;
            }, <CategoryMap>{}),
        );

        this.subCategoryMap$ = subcategories$.pipe(
            map(({ parent, ...rest }) => ({ ...rest, parentId: parent.id })),
            groupBy(({ parentId }) => parentId),
            mergeMap((group$) =>
                group$.pipe(
                    reduce((acc, current) => {
                        const key = current.parentId;

                        if (!acc[key]) acc[key] = [];

                        const { parentId, ...actualCategoryDto } = current;

                        acc[key].push(actualCategoryDto);

                        return acc;
                    }, <SubcategoryMap>{}),
                ),
            ),
            reduce((acc, current) => ({ ...acc, ...current })),
        );
    }

    private initCategories(): void {
        this.categories$ = forkJoin([this.categoryMap$, this.subCategoryMap$]).pipe(
            map(([categories, subcategories]) =>
                Object.entries(categories).map(([categoryId, category]) => {
                    const targetSubcategories = subcategories[categoryId] || [];

                    return { ...category, subcategories: targetSubcategories };
                }),
            ),
            map((categories) => categories.sort((prev, next) => (prev.name > next.name ? 1 : -1))),
        );
    }

    private initDictionaries(): void {
        this.dictionaries$ = this.categories$.pipe(
            switchMap((categories) => from(categories)),
            groupBy(({ name }) => this.findDictionaryWithTargetRange(name)),
            mergeMap((group$) =>
                group$.pipe(
                    reduce((acc: CategoryDictionaryMap, current) => {
                        const key = group$.key;
                        const categories = acc.get(key) || [];

                        categories.push(current);

                        acc.set(key, categories);

                        return acc;
                    }, new Map()),
                ),
            ),
            reduce((acc, current) => [...acc, current], []),
            map((dictionaryData: CategoryDictionaryMap[]) => dictionaryData.map((dictionary) => [...dictionary])),
            map((dictionaryData) =>
                dictionaryData.map(([[dictionary, categories]]) => ({ ...dictionary, categories })),
            ),
        );
    }

    private findDictionaryWithTargetRange(categoryName: string): CategoryDictionary {
        const dictionary = categoryDictionary.find(({ range: [start, end] }) => {
            const firstLetter = categoryName[0].toLowerCase();
            const isWithinRange = firstLetter >= start && firstLetter <= end;

            return isWithinRange;
        });

        return dictionary;
    }
}
