import { Class } from '@shared/core/class';
import { BUSINESS_ENTITY, ENTITY_ITEM_TYPE } from '@main/enums';
import { IEntityItemId } from '@main/types';
import { IEntityBase } from '../interfaces';
import { FormatHelpers } from '../helpers/format.helpers';
import { SerializedFiltersParams } from '@main/helpers/entity.helpers.types';
import { defaultPredefinedFilter } from '@main/features/report-home-page/predefined-entites.config';

export abstract class EntityBase<D = unknown> extends Class {
    id: IEntityItemId;
    entityType: BUSINESS_ENTITY;
    type: ENTITY_ITEM_TYPE;
    icon: string;
    homePageUrl: string;
    title: string;
    fullTitle: string;
    fullTitleHtml: string;
    inactive: boolean;
    _dto: D;

    preferredFilters: SerializedFiltersParams = null;
    dateTime: number = null;

    titlePrefix = { text: '', html: '' };
    titleSuffix = { text: '', html: '' };

    constructor(params?: Partial<IEntityBase>) {
        super();

        if (params) {
            const { id, type, entityType, icon, title, dto } = params;

            this.id = id;
            this.entityType = entityType;
            this.type = type;
            this.icon = icon;
            this._dto = dto;
            this.inactive = dto?.warning === 'inactive' || false;
            this.setTitle(title);
        }
    }

    setDateTime(dateTime: number): void {
        this.dateTime = dateTime || null;
    }

    setPreferredFilters(params: SerializedFiltersParams, allowDefault = true): void {
        const defaultFilters = allowDefault ? defaultPredefinedFilter : null;
        this.preferredFilters = params || defaultFilters;
    }

    isGroup(): boolean {
        return this.type === ENTITY_ITEM_TYPE.GROUP;
    }

    isInstance(): boolean {
        return this.type === ENTITY_ITEM_TYPE.SINGLE;
    }

    setTitle(text: string): void {
        this.title = text;
        this.updateFullTitle();
    }

    setTitlePrefix(text: string, html?: string): void {
        this.titlePrefix = { text, html: html || text };
        this.updateFullTitle();
    }

    setTitleSuffix(text: string, html?: string): void {
        this.titleSuffix = { text, html: html || text };
        this.updateFullTitle();
    }

    setAsBenchmark(): void {
        this.setTitlePrefix('(Avg) ');
    }

    private updateFullTitle(): void {
        this.fullTitle = FormatHelpers.formatFullTitle(this);
        this.fullTitleHtml = FormatHelpers.formatFullTitle(this, true);
    }
}
