import { DefaultUrlSerializer, UrlSerializer, UrlTree } from '@angular/router';

export default class CustomUrlSerializer implements UrlSerializer {
    private _defaultUrlSerializer: DefaultUrlSerializer = new DefaultUrlSerializer();

    parse(url: string): UrlTree {
        // Encode parentheses
        url = url.replace(/\(/g, '%28').replace(/\)/g, '%29');

        // workaround to fix SAS-5566 p.12 due to incorrect winmo url (redundant path before jwt param):
        // https://dev-ui-winmo.adclarity.com//ad-intelligence/brand/2363?period?jwt=eyJ0eXAiOiJKV1QiLC...
        const jwtTokenPos = url.indexOf('?jwt=');
        if (jwtTokenPos !== -1) {
            url = '/' + url.substring(jwtTokenPos);
        }

        // Use the default serializer.
        return this._defaultUrlSerializer.parse(url);
    }

    serialize(tree: UrlTree): string {
        return this._defaultUrlSerializer
            .serialize(tree)
            .replace(/%28/g, '(')
            .replace(/%29/g, ')')
            .replace(/%20/g, ' ')
            .replace(/%23/g, '#')
            .replace(/%3D/g, '=')
            .replace(/%3F/g, '?');
    }
}
