import { Component, Input, Output, EventEmitter, OnChanges, SimpleChanges, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { MatMenuTrigger } from '@angular/material/menu';

import { IntegrationsService } from '@app/integrations/services/integrations.service';
import { INTEGRATION } from '@app/integrations/integrations.types';
import { Channel } from '@main/models';
import { CHANNEL } from '@main/enums';
import { productAdIntelligencePath } from '@app/config';

@Component({
    selector: 'channel-selector',
    templateUrl: './channel-selector.component.html',
    styleUrls: ['./channel-selector.component.scss'],
})
export class ChannelSelectorComponent implements OnChanges {
    @ViewChild('menuTrigger') menuTrigger: MatMenuTrigger;

    @Input() value: Channel;
    @Input() channels: Channel[];
    @Input() showRestrictedTooltip = true;
    @Input() disabled = false;
    @Input() stopClickPropagation = false;

    @Output() select = new EventEmitter<Channel>();

    availableChannels: Channel[];
    restrictedChannels: Channel[];

    promotionMode = false;

    channelsListDataLabel: string;
    lockedChannelsText: string;

    get isDisabled(): boolean {
        return (this.value && this.value.disabled) || this.disabled || !this.value;
    }

    constructor(private integrationsService: IntegrationsService, private router: Router) {}

    ngOnChanges(changes: SimpleChanges): void {
        const channels: Channel[] = changes.channels?.currentValue;

        if (channels) {
            this.updateChannels(channels);
        }
    }

    _select(event, channel): void {
        if (this.isChannelSelectable(channel)) {
            if (this.stopClickPropagation) {
                event.stopPropagation();
                this.menuTrigger.closeMenu();
            }
            this.select.emit(channel);
        } else {
            event.stopPropagation();
        }
    }

    onUnlockClick(): void {
        this.router.navigate([`/${productAdIntelligencePath}/pricing`]);
    }

    private updateChannels(channels: Channel[]): void {
        const hasRestrictedChannels = !!channels.find((v) => v.restricted);

        this.promotionMode = this.integrationsService.isIntegration(INTEGRATION.SEMRUSH) && hasRestrictedChannels;

        const items = this.promotionMode ? channels.filter((v) => v.type !== CHANNEL.ALL) : channels;

        this.availableChannels = items.filter((v) => !v.restricted);
        this.restrictedChannels = items.filter((v) => v.restricted);

        this.channelsListDataLabel = items.map((v) => v.title).join(',');

        if (this.restrictedChannels.find((v) => [CHANNEL.VIDEO, CHANNEL.SOCIAL].includes(v.type))) {
            this.lockedChannelsText = 'Social & Video';
        } else {
            this.lockedChannelsText = this.restrictedChannels.map((v) => v.title).join(' & ');
        }

        this.disabled = !this.promotionMode && this.availableChannels.length < 2;
    }

    private isChannelSelectable(item): boolean {
        return !item.disabled && !item.restricted;
    }
}
