import { inject, Injectable } from '@angular/core';
import userflow from 'userflow.js';

import { AppConfig } from '@app/app.config';
import { SemrushService } from '@app/integrations/semrush/services/semrush.service';
import { FeatureToggleService } from '@main/shared/feature-toggle';
import { AccountService, WINDOW } from '@shared/services';
import { IUser } from '@shared/interfaces';
import { environment } from 'environments/environment';
import { PACKAGE } from '@app/integrations/semrush/semrush.types';
import { Feature } from '@main/shared/feature-toggle/feature-toggle.model';

/**
 * Service for managing Userflow.
 * See: https://docs.userflow.com/docs/dev/userflow-js-installation
 */
@Injectable()
export class UserflowService {
    private readonly companyNameWlMap = {
        adcheck: 'AdCheck',
        adclarity: 'AdClarity',
        ibope: '',
        infoadex: 'InfoAdex',
        km: 'Kantar Media',
        nielsen: 'Nielsen',
        semrush: '',
    };
    private readonly semrushPackageName = {
        [PACKAGE.ALL]: 'All Channels',
        [PACKAGE.DISPLAY]: 'Display',
        [PACKAGE.SOCIAL_VIDEO]: 'Social & Video',
    };

    private readonly accountService = inject(AccountService);
    private readonly config = inject(AppConfig);
    private readonly featureToggleService = inject(FeatureToggleService);
    private readonly semrushService = inject(SemrushService);
    private readonly window = inject(WINDOW);

    init(): void {
        this.accountService.$currentUser.subscribe((user) => {
            if (!this.featureToggleService.isEnabled(Feature.USERFLOW)) {
                return;
            }

            if (!environment.userflowToken) {
                console.error('Userflow token is not set');
                return;
            }

            try {
                userflow.init(environment.userflowToken);
                userflow.identify(user.id, {
                    name: this.getNameParam(user),
                    domain: this.getDomainParam(),
                    company: this.getCompanyParam(),
                    package: this.getPackageParam(),
                    signed_up_at: new Date().toISOString(),
                });
            } catch (err) {
                console.warn(err);
            }
        });
    }

    private getNameParam(user: IUser): string {
        return `${user.firstName} ${user.lastName}`;
    }

    private getDomainParam(): string {
        const urlParsed = new URL(this.window.location.href);

        return urlParsed.hostname;
    }

    private getCompanyParam(): string {
        const wlName = this.config.appInfo.id;

        return this.companyNameWlMap[wlName] || this.companyNameWlMap.adclarity;
    }

    private getPackageParam(): string {
        const wlName = this.config.appInfo.id;

        if (wlName.toLowerCase() !== 'semrush') {
            return 'others';
        }

        const semrushPackages = this.semrushService.getActivePackages();
        const packageText =
            semrushPackages.length === 1
                ? this.semrushPackageName[semrushPackages[0]]
                : this.semrushPackageName[PACKAGE.ALL];

        return `SEMRush ${packageText}`;
    }
}
