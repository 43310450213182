import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { RequestService } from '@shared/services';
import { Currency } from '@shared/models';
import { PaginatedListResponse } from './base.types';

type CurrencyInfo = Readonly<{
    expenses: {
        currency: Currency;
    };
}>;

@Injectable({ providedIn: 'root' })
export class CurrencyApiService {
    constructor(private requestService: RequestService) {}

    fetchCurrencies(): Observable<Currency[]> {
        return this.requestService
            .get<PaginatedListResponse<CurrencyInfo>>('datasets/currencies')
            .pipe(map(({ elements }) => this.normalizeCurrencyInfo(elements)));
    }

    private normalizeCurrencyInfo(elements: CurrencyInfo[]): Currency[] {
        return elements.map(({ expenses: { currency } }) => currency);
    }
}
