import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import html2canvas from 'html2canvas';

import { SharedTopNotificationBarService } from '@app/modules/top-notification/top-notification-bar.service';
import { NOTIFICATION_TYPE } from '@app/modules/top-notification/top-notification-bar.types';
import { ExportHelpers } from '@shared/helpers/export.helpers';
import { LayoutHelpers } from '@shared/helpers/layout.helpers';
import { CommonHelpers } from '../helpers/common.helpers';

@Injectable()
export class ExportImageService {
    constructor(private notificationService: SharedTopNotificationBarService) {}

    export(element: HTMLElement, fileNameTpl: string, lookupWidget = true): Observable<void> {
        return new Observable((observer) => {
            const randomId = 'id' + Math.floor(Math.random() * 1000000);
            const originMainContainer = document.querySelector('app') as HTMLElement;
            const originWidgetContainer = lookupWidget ? this.findWidgetRef(element) : element;

            originWidgetContainer.setAttribute('id', randomId);

            ExportHelpers.setSvgIconsIds(originWidgetContainer);
            // doing it for origin container, because it doesn't work in cloned :(
            ExportHelpers.uncacheImages(originMainContainer);

            const options = {
                onclone: (clonedDocument: HTMLElement) => {
                    const clonedMainContainer = clonedDocument.querySelector('app') as HTMLElement;
                    const clonedWidgetContainer = clonedDocument.querySelector(`#${randomId}`) as HTMLElement;

                    originWidgetContainer.removeAttribute('id');

                    clonedMainContainer.classList.add('mode-export');
                    clonedWidgetContainer.classList.add('mode-export-as-image');

                    originWidgetContainer.classList.add('mode-export-as-image');
                    ExportHelpers.fixSvgIcons(originWidgetContainer, clonedWidgetContainer);
                    originWidgetContainer.classList.remove('mode-export-as-image');

                    ExportHelpers.unsetScrollables(clonedWidgetContainer);
                },
                useCORS: true,
                logging: false,
                scale: 3, // increased scale to avoid pixelation on zooming
            };

            this.notificationService.add(NOTIFICATION_TYPE.COMMON_EXPORT, 'Generating export', {
                theme: 'blue',
                spinner: true,
                shared: true,
            });

            html2canvas(originWidgetContainer, options).then((canvas) => {
                canvas.toBlob(
                    (blob) => {
                        this.initDownload(fileNameTpl, blob);
                    },
                    'image/jpeg',
                    0.95,
                );

                this.notificationService.removeByType(NOTIFICATION_TYPE.COMMON_EXPORT);

                observer.next(null);
                observer.complete();
            });
        });
    }

    private initDownload(fileNameTpl: string, blob: Blob): void {
        const fileName = CommonHelpers.finalizeExportFileNameTpl(fileNameTpl, 'jpg');

        const downloadLink = document.createElement('a');
        downloadLink.setAttribute('download', fileName);

        const url = URL.createObjectURL(blob);
        downloadLink.setAttribute('href', url);
        downloadLink.click();
    }

    private findWidgetRef(element: HTMLElement): HTMLElement {
        let node = element;

        const child = node.querySelector('widget-card');

        if (child) return child as HTMLElement;

        while (node && node.nodeName !== 'WIDGET-CARD') {
            node = node.parentElement;
        }

        return node;
    }

    private calcWidgetHeight(widgetEl: HTMLElement): number {
        widgetEl.classList.add('mode-export-as-image');
        const height = LayoutHelpers.getElementHeight(widgetEl);
        widgetEl.classList.remove('mode-export-as-image');

        return height;
    }
}
