import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { DatasetRequestParams, RequestService } from '@shared/services';
import { CategoriesResponse, CategoryResponse } from 'app/app-main/reports/categories/models';
import { IRequestParams } from '@shared/models/request-params.model';

@Injectable()
export class AllCategoriesApiService {
    constructor(private requestService: RequestService) {}

    fetchCategories(requestParams?: IRequestParams): Observable<CategoryResponse[]> {
        const params: DatasetRequestParams = { datasetName: 'all-categories', requestParams };

        return this.requestService.dataset<CategoriesResponse>(params).pipe(map(({ elements }) => elements));
    }
}
