/* eslint-disable max-lines */
import { isNil } from 'lodash';

import { CommonHelpers } from '@shared/helpers/common.helpers';
import {
    Channel,
    Device,
    EntityAdvertiser,
    EntityCampaign,
    EntityCategory,
    EntityGroup,
    EntityItem,
    EntityPublisher,
} from '../models';
import { Period } from '@shared/models/period';
import { Country } from '@shared/models/country';
import { Currency } from '@shared/models';
import { FiltersValue } from '@shared/services/filters/filters.types';
import { PeriodHelpers } from '@shared/helpers/period.helpers';
import { Context } from '@main/core/context';
import { TaxonomyTopic } from '@main/models';
import { IEntityItemId } from '@main/types';
import { IDatasetResponseItemBase, IEntityInfo } from '@main/interfaces';
import { BUSINESS_ENTITY, CHANNEL, DEVICE, GROUP_TYPE } from '@main/enums';
import { entityInfoConfig } from '@main/helpers/entity-info.config';
import { SerializeEntitiesIdsOptions, SerializedFiltersParams } from './entity.helpers.types';
import { RouteIdsItem } from '../types/types';
import { EntityKeyword } from '../models/EntityKeyword';
import { EntityInstance, EntitySingle } from '@main/models';
import { EntityBrand } from '../models/EntityBrand';
import { ChannelFilter } from '@main/services/entity-common.service.types';
import { BenchmarkInfo } from '../core/report.types';

export class EntityHelpers {
    static maxSelectedEntitiesCount = 10;
    static maxGroupEntitiesCount = 10;
    static maxSelectedCategoriesCount = 4;

    static getEntityInfo(entityType: BUSINESS_ENTITY): IEntityInfo {
        return entityInfoConfig[entityType];
    }

    static mapSingleEntity<E = EntitySingle>(entityType: BUSINESS_ENTITY, data: any): E {
        switch (entityType) {
            case BUSINESS_ENTITY.Advertiser:
                return new EntityAdvertiser(data) as any;

            case BUSINESS_ENTITY.Publisher:
                return new EntityPublisher(data) as any;

            case BUSINESS_ENTITY.Category:
                return new EntityCategory(data) as any;

            case BUSINESS_ENTITY.Campaign:
                return new EntityCampaign(data) as any;

            case BUSINESS_ENTITY.Keyword:
                return new EntityKeyword(data) as any;

            case BUSINESS_ENTITY.Brand:
                return new EntityBrand(data) as any;
        }
    }

    static entitiesHasGroup(items: EntityItem[]): boolean {
        return !!items.find((item) => item.isGroup());
    }

    static isValidLink(link): boolean {
        return link && link.indexOf('(facebook.com)') === -1 && link.indexOf('(FB page)') === -1;
    }

    static isSocialChannel(channel: Channel): boolean {
        return [CHANNEL.SOCIAL].includes(channel.type as CHANNEL);
    }

    static isItemsSingleGroup(items: EntityItem[]): boolean {
        return items.length === 1 && items[0].isGroup();
    }

    static isItemsSingleComparisonGroup(items: EntityItem[]): boolean {
        return items.length === 1 && this.isEntityComparisonGroup(items[0]);
    }

    static isEntityComparisonGroup(item: EntityItem): boolean {
        return item.isGroup() && (item as EntityGroup).isComparison();
    }

    static isItemsVirtualGroup(items: EntityItem[]): boolean {
        return this.isItemsSingleGroup(items) && (items[0] as EntityGroup).isVirtual();
    }

    static areItemsHasVirtualGroup(items: EntityItem[]): boolean {
        return !!items.find((v) => v.isGroup() && (v as EntityGroup).isVirtual());
    }

    static filterEntitiesByEntityType(entities: EntityItem[], entityType: BUSINESS_ENTITY): EntityItem[] {
        return entities.filter((entity) => entity.entityType === entityType);
    }

    static serializeEntitiesIds(entities: EntityItem[], options: Partial<SerializeEntitiesIdsOptions> = {}): string {
        const { extractGroupsIds, plain, duplicateSingleId, globalParentheses } = options;
        const ids: IEntityItemId[] = [];
        const filterUniq = isNil(options?.filterUniq) ? true : options.filterUniq;

        entities.forEach((item) => {
            if (item.isGroup()) {
                if ((item as EntityGroup).isVirtual() || extractGroupsIds) {
                    const nestedIds = this.serializeEntitiesIds((item as EntityGroup).items, {
                        extractGroupsIds,
                        plain,
                    });

                    if (plain) {
                        ids.push(nestedIds);
                    } else {
                        ids.push('(' + nestedIds + ')');
                    }
                } else {
                    ids.push(item.id);
                }
            } else {
                ids.push(item.id);
            }
        });

        const resultIds = filterUniq ? this.uniqIds(ids) : ids;

        if (duplicateSingleId && resultIds.length === 1) {
            resultIds.push(resultIds[0]);
        }

        const joinedResult = resultIds.join(',');

        return joinedResult.indexOf(',') !== -1 && globalParentheses ? `(${joinedResult})` : joinedResult;
    }

    static serializeEntitiesIdsPairs(entities: EntityItem[], options?: Partial<SerializeEntitiesIdsOptions>) {
        const result: Record<string, string> = {};

        Object.values(BUSINESS_ENTITY).forEach((entityType) => {
            const entityInfo = EntityHelpers.getEntityInfo(entityType);
            const filteredEntities = entities.filter((v) => v.entityType === entityType);

            if (filteredEntities.length) {
                result[entityInfo.singleKey] = this.serializeEntitiesIds(filteredEntities, options);
            }
        });

        return result;
    }

    static serializePeriod(period: Period, asString = false): string | number {
        const value = period.customRange
            ? `${PeriodHelpers.serializePeriodDate(period.start)}-${PeriodHelpers.serializePeriodDate(period.end)}`
            : period.id;

        return asString ? value.toString() : value;
    }

    static serializeChannel(channel: Channel, device: Device): ChannelFilter {
        return `${channel.type}-${device.code}`;
    }

    static serializeCountry(country: Country, upperCase = false): string {
        return upperCase ? country?.code.toLocaleUpperCase() : country?.code;
    }

    static serializeCurrency(currency: Currency): string {
        return currency?.code;
    }

    static serializeCommonFilters(
        channel: Channel,
        period: Period,
        country: Country,
        device: Device,
        taxonomyTopic: TaxonomyTopic,
        currency: Currency,
        options = {} as Record<string, unknown>,
    ) {
        const params: Record<string, any> = {
            channel: this.serializeChannel(channel, device),
            period: this.serializePeriod(period, options.export === true),
            country: this.serializeCountry(country, options.export === true),
            currency: this.serializeCurrency(currency),
        };

        if (taxonomyTopic) {
            params.taxonomy = taxonomyTopic.id;
        }

        return params;
    }

    static serializeCommonContextParams(context: Context, options = {} as Record<string, any>): Record<string, any> {
        const {
            filters: { country, channel, device, period },
            settings: { currency },
        } = context;

        const params: Record<string, any> = {
            channel: this.serializeChannel(channel, device),
            period: this.serializePeriod(period, options.export === true),
            country: this.serializeCountry(country, options.export === true),
            currency: this.serializeCurrency(currency),
        };

        return params;
    }

    static serializeFiltersParams(
        filters: FiltersValue,
        currency?: Currency,
        options: { export?: boolean } = {},
    ): SerializedFiltersParams {
        const { country, channel, device, period, taxonomyTopic } = filters;

        const params: SerializedFiltersParams = {
            channel: this.serializeChannel(channel, device),
            country: this.serializeCountry(country, options.export === true),
            period: this.serializePeriod(period, options.export === true),
        };

        if (taxonomyTopic) {
            params.taxonomy = taxonomyTopic.id;
        }

        if (currency) {
            params.currency = this.serializeCurrency(currency);
        }

        return params;
    }

    static unwrapSingleGroup(items: EntityItem[]): EntityItem[] {
        return (items[0] as EntityGroup).items;
    }

    static composeDownloadFileName(
        prefixes: string[],
        rootEntity: EntityGroup,
        extraValues: string[] = [],
        extension?: string,
    ): string {
        const titles = rootEntity.isVirtual() ? rootEntity.items.map((item) => item.title) : [rootEntity.title];

        return CommonHelpers.composeDownloadFileName(
            [...prefixes, ...titles, ...extraValues].filter((v) => !!v),
            extension,
        );
    }

    static uniqIds(ids: IEntityItemId[]): IEntityItemId[] {
        return [...new Set(ids)];
    }

    static flattenItems(items: EntityItem[]): EntityItem[] {
        const result = [];

        items.forEach((item) => {
            if (item.isGroup()) {
                result.push(...this.flattenItems((item as EntityGroup).items));
            } else {
                result.push(item);
            }
        });

        return [...new Set(result)];
    }

    static isInstancesArraysEqual(arr1: IEntityItemId[], arr2: IEntityItemId[]): boolean {
        return Array.isArray(arr1) && Array.isArray(arr2) && arr1.sort().join() === arr2.sort().join();
    }

    static isSingleInstanceId(id: IEntityItemId, strictType = false): boolean {
        return strictType ? Number(id) === id : Number.isInteger(Number(id));
    }

    static isGroupId(id: RouteIdsItem): boolean {
        /* contains at least 1 char and 1 number */
        return !!id?.toString().match(/^[a-z0-9]{8}-[a-z0-9]{4}-[a-z0-9]{4}-[a-z0-9]{4}-[a-z0-9]{12}$/g);
    }

    static isVirtualGroupId(id: RouteIdsItem) {
        return !!id?.toString().match(/^_virtual_group.*/);
    }

    static getIcon(entityType: BUSINESS_ENTITY, groupType: GROUP_TYPE): string {
        return entityType === BUSINESS_ENTITY.Keyword
            ? ':hashtagBlue'
            : groupType === GROUP_TYPE.REGULAR
            ? ':groupGeneric'
            : ':groupComparison';
    }

    static getVirtualGroupId(suffix: string | number): string {
        return `_virtual_group_${suffix}`;
    }

    static createVirtualGroup(
        n: number | string,
        title: string = null,
        entities: EntityItem[] = [],
        groupType = GROUP_TYPE.REGULAR,
        defaultEntityType: BUSINESS_ENTITY = null,
        dynamicDto?: unknown,
        accessLogId?: string,
    ): EntityGroup {
        let virtualGroupName = title;

        if (isNil(virtualGroupName)) virtualGroupName = this.generateVirtualGroupName(n);

        const entityType = defaultEntityType || entities[0]?.entityType || null;

        return new EntityGroup({
            id: this.getVirtualGroupId(n),
            entityType,
            groupType,
            title: virtualGroupName,
            titleGenerated: title !== virtualGroupName,
            icon: this.getIcon(entityType, groupType),
            items: entities,
            dto: dynamicDto,
            resolved: true,
            virtual: true,
            accessLogId,
        });
    }

    static findAvailableVirtualGroupNumber(items: EntityItem[]): number {
        for (let n = 1; n < 100; n++) {
            const id = `_virtual_group_${n}`;

            if (!items.find((v) => v.id === id)) {
                return n;
            }
        }

        return -1;
    }

    static generateVirtualGroupName(index: number | string): string {
        return `Group ${index}`;
    }

    static matchCommonResponseItemAndEntity<T extends IDatasetResponseItemBase>(
        entity: EntityItem,
        responseElement: T,
    ): boolean {
        if (entity.isGroup() && responseElement.groupId) {
            return entity.id === responseElement.groupId;
        }

        if (entity.isGroup() && (entity as EntityGroup).isVirtual()) {
            const groupItemsIds = (entity as EntityGroup).items.map((n) => n.id);

            return (
                EntityHelpers.isInstancesArraysEqual(groupItemsIds, responseElement.ids) ||
                EntityHelpers.isInstancesArraysEqual(groupItemsIds, [responseElement.id])
            );
        }

        return entity.id === responseElement.id;
    }

    static matchCommonResponseItems<T extends IDatasetResponseItemBase>(
        responseElement1: T,
        responseElement2: T,
    ): boolean {
        if (responseElement1.id) {
            return responseElement1.id === responseElement2.id;
        }

        if (responseElement1.ids) {
            return responseElement1.ids === responseElement2.ids;
        }

        if (responseElement1.groupId) {
            return responseElement1.groupId === responseElement2.groupId;
        }
    }

    static sortInstancesByImpressions(entities: EntityInstance[]): EntityItem[] {
        return entities.sort((a, b) => (a.impressionsCount > b.impressionsCount ? -1 : 1));
    }

    static getGroupedBenchmarkItems(
        entities: EntityItem[],
        benchmarkInfo: BenchmarkInfo,
    ): [EntityItem[], EntityCategory] {
        const benchmarkEntity =
            (benchmarkInfo &&
                <EntityCategory>(
                    entities.find(
                        (item) => benchmarkInfo.entityId === item.id && benchmarkInfo.entityType === item.entityType,
                    )
                )) ||
            null;
        const regularItems = entities.filter((entity) => entity !== benchmarkEntity);

        return [regularItems, benchmarkEntity];
    }

    static pickEntityById(entities: EntityItem[], entityId: IEntityItemId, entityType?: BUSINESS_ENTITY): EntityItem {
        return entities
            .filter((entity) => !entityType || entity.entityType === entityType)
            .find((entity) => entity.id === entityId);
    }

    static pickEntityGroupByName(entities: EntityItem[], groupName: string): EntityGroup {
        return <EntityGroup>entities.find((entity) => decodeURI(entity.title) === groupName);
    }

    static getPublishersColumnName(channel: Channel): string {
        return channel.type === CHANNEL.INAPP ? 'Apps' : 'Publishers';
    }

    static getPublishersColumnTooltip(channel: Channel, device: Device): string {
        return channel.type === CHANNEL.ALL ||
            ([CHANNEL.VIDEO, CHANNEL.DISPLAY].includes(channel.type) &&
                [DEVICE.ALL, DEVICE.MOBILE].includes(device.code))
            ? 'Publishers include websites and mobile apps'
            : '';
    }

    static getPresetChannel(entity: EntityGroup, channels: Channel[]): Channel {
        if (!entity || !entity._dto.details.params?.channel) {
            return null;
        }

        const { channel = null } = entity._dto.details.params;
        const [channelType, deviceCode = null] = channel.split('-');

        return channels.find(
            ({ type, devices }) => type === channelType && devices.some(({ code }) => code === deviceCode),
        );
    }

    static getPresetDevice(entity: EntityGroup, selectedChannel: Channel): Device {
        if (!entity || !entity._dto.details.params?.channel) {
            return null;
        }

        const { channel = null } = entity._dto.details.params;
        const [, deviceCode = null] = channel.split('-');

        return selectedChannel.devices.find(({ code }) => code === deviceCode);
    }

    static getPresetPeriod(entity: EntityGroup, periods: Period[]): Period {
        if (!entity || !entity._dto.details.params?.period) {
            return null;
        }

        const { period = null } = entity._dto.details.params;

        return periods.find(({ id }) => id === period) || null;
    }

    static getPresetCountry(entity: EntityGroup, countries: Country[]): Country {
        if (!entity || !entity._dto.details.params?.country) {
            return null;
        }

        const { country = null } = entity._dto.details.params;

        return countries.find(({ code }) => code === country) || null;
    }

    static mapEntityTypeForBenchmark(entityType: BUSINESS_ENTITY): BUSINESS_ENTITY {
        switch (entityType) {
            case BUSINESS_ENTITY.BenchmarkAdvertisers:
                return BUSINESS_ENTITY.Advertiser;
            case BUSINESS_ENTITY.BenchmarkBrands:
                return BUSINESS_ENTITY.Brand;
            default:
                return entityType;
        }
    }

    static mapBenchmarkForEntityType(entityType: BUSINESS_ENTITY): BUSINESS_ENTITY {
        switch (entityType) {
            case BUSINESS_ENTITY.Advertiser:
                return BUSINESS_ENTITY.BenchmarkAdvertisers;
            case BUSINESS_ENTITY.Brand:
                return BUSINESS_ENTITY.BenchmarkBrands;
            default:
                return entityType;
        }
    }

    static getMaxSelectedEntitiesCount(entityType: BUSINESS_ENTITY): number {
        if (entityType === BUSINESS_ENTITY.Category) {
            return this.maxSelectedCategoriesCount;
        } else {
            return this.maxSelectedEntitiesCount;
        }
    }
}
