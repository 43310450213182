<button
    #toggleButton
    #menuTrigger="matMenuTrigger"
    mat-button
    data-unit="publisher-selector-main-button"
    color="primary"
    [matMenuTriggerFor]="publisherSelectorMenu"
    class="u-button h-32 publisher-selector__main-button"
    [class.active]="menuTrigger.menuOpen"
    [class.selected]="publisherIds.length"
    (keydown.esc)="menuTrigger.closeMenu()"
    (menuOpened)="onOpen()"
    (menuClosed)="onClose()"
>
    <span data-unit="publisher-selector-main-button-title" class="title">
        {{ buttonTitle }}
    </span>
    <mat-icon *ngIf="!publisherIds.length" class="s-12 ml-4" svgIcon="expandMore" iconPositionEnd></mat-icon>
    <button
        *ngIf="publisherIds.length"
        mat-icon-button
        data-unit="publisher-selector-clear-button"
        class="clear-button export-hide"
        aria-label="Clear selection"
        (click)="clearSelection($event)"
    >
        <mat-icon svgIcon="close"></mat-icon>
    </button>
</button>

<mat-menu
    #publisherSelectorMenu="matMenu"
    [overlapTrigger]="false"
    [hasBackdrop]="false"
    class="u-dropdown theme-light publisher-selector__menu"
>
    <div (click)="$event.stopPropagation()" (keydown)="$event.stopPropagation()">
        <div class="filters">
            <search-input-control
                class="filter filter--search"
                size="small"
                (searchChange)="onSearchChange($event)"
                [focused]="true"
                [keepFocus]="false"
                [stopClickPropagation]="true"
                [value]="searchValue"
                iconPosition="right"
                data-unit="publisher-selector-menu-search"
            >
            </search-input-control>
            <channel-selector
                class="filter filter--channel"
                [channels]="channels"
                [value]="selectedChannel$ | async"
                [stopClickPropagation]="true"
                (select)="onSelectChannel($event)"
            >
            </channel-selector>
        </div>

        <div *ngIf="currentSelectedItems.length" class="publisher-selector__selected-items">
            <default-entity-card
                *ngFor="let item of currentSelectedItems"
                class="entity-card"
                data-unit="publisher-selector-selected-item"
                [removable]="true"
                [item]="item.entity"
                (remove)="onRemove(item)"
            >
            </default-entity-card>
        </div>

        <div *ngIf="isLoading" class="loader">
            <div *ngFor="let _ of [].constructor(8)" class="ssc loader__item">
                <div class="ssc-square loader__icon"></div>
                <div class="ssc-square loader__title"></div>
                <div class="loader__channels">
                    <div class="ssc-square loader__icon"></div>
                    <div class="ssc-square loader__icon"></div>
                    <div class="ssc-square loader__icon"></div>
                </div>
            </div>
        </div>

        <h5
            *ngIf="!isLoading && noSearchResults && !(filteredItems$ | async)?.length"
            class="empty menu__empty"
            data-unit="publisher-selector-menu-empty"
        >
            No publishers found
        </h5>

        <ul *ngIf="!isLoading && (filteredItems$ | async)?.length" class="menu__list">
            <li
                *ngFor="let item of filteredItems$ | async"
                class="list__item"
                [class.list__item--disabled]="isItemDisabled(item)"
                data-unit="publisher-selector-menu-item"
                (click)="onSelectionChange(item)"
                [popper]="tooltipPopover"
                popperTrigger="click"
                popperPlacement="bottom"
                popperAppendTo="body"
                popperApplyArrowClass="no-arrow"
                [popperDisabled]="!isItemDisabled(item)"
                [popperTimeout]="300"
            >
                <mat-checkbox
                    [ngModel]="item.selected"
                    class="list__item__checkbox"
                    [disabled]="isItemDisabled(item)"
                ></mat-checkbox>
                <fav-icon [src]="item.entity.icon" class="list__item__icon"></fav-icon>
                <span
                    data-unit="publisher-selector-menu-item-name"
                    class="list__item__name"
                    [matTooltip]="item.entity.fullTitle"
                    [matTooltipDisabled]="isItemDisabled(item)"
                >
                    <span [innerHTML]="item.entity.title | searchHighlight : searchValue"></span>
                    <span [innerHTML]="item.entity.titleSuffix.html"></span>
                </span>
                <div
                    class="list__item__channels"
                    data-unit="publisher-selector-menu-item-channels"
                    [matTooltip]="getPublisherChannelsTooltip(item)"
                    [matTooltipDisabled]="isItemDisabled(item)"
                    matTooltipClass="tooltip-multiline"
                >
                    <channel-icon
                        *ngFor="let channel of item.channels"
                        class="channels__icon"
                        data-unit="publisher-selector-menu-item-channel-icon"
                        [channel]="channel"
                        [showTooltip]="false"
                    ></channel-icon>
                </div>
            </li>
        </ul>

        <div class="publisher-selector__actions">
            <button
                mat-stroked-button
                color="primary"
                data-unit="cancel-button"
                class="u-button h-32 actions__cancel-button"
                (click)="onCancel()"
            >
                Cancel
            </button>
            <button
                mat-button
                mat-flat-button
                type="button"
                color="accent"
                class="u-button h-32 actions__apply-button"
                data-unit="apply-button"
                (click)="onApply()"
            >
                Apply
            </button>
        </div>
    </div>
</mat-menu>

<popper-content #tooltipPopover data-unit="tooltipPopover">
    <div class="popper-tooltip">
        <p>Filter allows to select up to 10 publishers</p>
        <button (click)="resetFilter(); tooltipPopover.hide()">Reset filter</button>
    </div>
</popper-content>
