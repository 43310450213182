import { Inject, Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';

import { RequestService } from '@shared/services/request/request.service';
import { DatasetCustomParams, DatasetRequestParams } from '@shared/services/request/request.types';
import { IChannel } from '@main/interfaces';
import { IEntityItemInstanceId } from '@main/types';
import { ReportConfig } from '@shared/interfaces/ReportConfig';
import { REPORT_CONFIG } from '@shared/const/report-config';
import { FiltersValue } from '@shared/services/filters/filters.types';
import { EntityHelpers } from '@main/helpers/entity.helpers';
import { BUSINESS_ENTITY, PUBLISHER_TYPE, WIDGET } from '../enums';
import { EntityItem } from '../models';

export interface GetAvailablePublishersResponse {
    elements: AvailablePublisherItem[];
    impressions: {
        total: number;
    };
    count: {
        total: number;
    };
}

export interface AvailablePublisherItem {
    id: IEntityItemInstanceId;
    name: string;
    homePageUrl: string;
    favIconUrl: string;
    type: {
        code: string;
        title: string;
    };
    impressions: {
        total: number;
    };
    channels: {
        elements: IChannel[];
    };
}

@Injectable()
export class PublishersService {
    private getPublisherTypeData = {
        result: undefined,
        params: undefined,
    };

    constructor(private requestService: RequestService, @Inject(REPORT_CONFIG) protected reportConfig: ReportConfig) {}

    getAvailablePublishers({
        entities,
        filters,
        type,
        searchQuery,
        page,
        size,
    }: {
        entities: EntityItem[];
        filters: FiltersValue;
        type?: PUBLISHER_TYPE;
        searchQuery?: string;
        page?: number;
        size?: number;
    }): Observable<AvailablePublisherItem[]> {
        const entitiesIdsPairs = EntityHelpers.serializeEntitiesIdsPairs(entities, {
            globalParentheses: true,
            extractGroupsIds: true,
            plain: true,
        });

        const customParams: DatasetCustomParams = EntityHelpers.serializeFiltersParams(filters);

        if (type) {
            customParams.type = {
                operation: 'in',
                items: [type],
            };
        }

        if (searchQuery) {
            customParams.name = `contains(${searchQuery})`;
        }

        if (page) {
            customParams.page = page;
        }

        if (size) {
            customParams.size = size;
        }

        const datasetParams: DatasetRequestParams = {
            datasetName: 'available-publishers',
            entitiesIdsPairs,
            customParams,
            useViewToken: true,
            labelEntityType: BUSINESS_ENTITY.Advertiser,
            labelArea: WIDGET.TOP_ADS,
        };

        const cachedParams = JSON.stringify(datasetParams);

        if (this.getPublisherTypeData.result && cachedParams === this.getPublisherTypeData.params) {
            return of(JSON.parse(this.getPublisherTypeData.result));
        }

        return this.requestService.dataset<GetAvailablePublishersResponse>(datasetParams).pipe(
            map((response) => {
                const result = response?.elements || [];

                result[Symbol.for('total')] = response?.count.total || 0;

                return result;
            }),
        );
    }
}
