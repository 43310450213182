export enum BUSINESS_ENTITY {
    Advertiser = 'ADVERTISER',
    Publisher = 'PUBLISHER',
    Campaign = 'CAMPAIGN',
    Keyword = 'KEYWORD',
    Category = 'CATEGORY',
    Brand = 'BRAND',

    // Non-real entities, used to match BE
    BenchmarkAdvertisers = 'BENCHMARK_ADVERTISERS',
    BenchmarkBrands = 'BENCHMARK_BRANDS',
}
