import { SystemColor } from '../../models';
import { getPalette } from '../../utils';

export const singleInfoColor = SystemColor.ColorDataAzure;

export const dataChangeColors: Record<string, SystemColor> = {
    increase: SystemColor.ColorDataTeal,
    noChange: SystemColor.ColorDataYellow,
    decrease: SystemColor.ColorDataPeach,
};

export const singleInfoPalette = getPalette(singleInfoColor);
