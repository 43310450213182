import { Component, EventEmitter, HostBinding, Input, OnChanges, Output, SimpleChanges } from '@angular/core';
import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';

const defaultIcon = ':siteDefault';

@Component({
    selector: 'fav-icon',
    templateUrl: './fav-icon.component.html',
    styleUrls: ['./fav-icon.component.scss'],
})
export class FavIconComponent implements OnChanges {
    @Input() id: string;
    @Input() src: string;
    @Input() defaultIcon: string;
    @Input() useAsSvg = false;
    @Input() removable: boolean;
    @Input() matIconSize = 16;
    @Input() matIconColor: string = null;

    @Output() remove = new EventEmitter<string>();

    @Input()
    @HostBinding('class.boxed')
    boxed = false;

    svgIcon: string;
    imageSrc: string;

    constructor(private matIconRegistry: MatIconRegistry, private sanitizer: DomSanitizer) {}

    ngOnChanges(changes: SimpleChanges): void {
        if (changes.src) {
            this.update();
        }
    }

    onImageLoadError(): void {
        this.src = null;

        this.update();
    }

    private update(): void {
        const src = this.src || this.getDefaultIcon();

        this.svgIcon = null;
        this.imageSrc = null;

        if (this.useAsSvg) {
            this.svgIcon = src.replace(/https?:\/\//, '');
            this.matIconRegistry.addSvgIcon(this.svgIcon, this.sanitizer.bypassSecurityTrustResourceUrl(src));
        } else if (src[0] === ':') {
            this.svgIcon = src.slice(1);
        } else {
            this.imageSrc = src;
        }
    }

    private getDefaultIcon(): string {
        return this.defaultIcon || defaultIcon;
    }
}
