import { Component, HostBinding, Inject, Input, OnInit, ViewChild } from '@angular/core';
import { MatSlideToggleChange } from '@angular/material/slide-toggle';
import { Router } from '@angular/router';

import { productAdIntelligencePath } from '@app/config';
import { AppConfig } from '@app/app.config';
import { AnalyticsService } from '@app/modules/analytics/services/analytics.service';
import { PRODUCT_TYPE } from '@shared/enums';
import { IUser } from '@shared/interfaces';
import { WINDOW } from '@shared/services/window.providers';
import { RoleService } from '@shared/services/role.service';
import { AuthService } from '@shared/services/auth/auth.service';
import { AccountService } from '@shared/services/account.service';
import { EventsService } from '@shared/services/events/events.service';
import { EventsEnum } from '@shared/services/events/events.enum';
import { MarkStateService } from '@main/components/mark-entity-button/services/mark-state.service';
import { MenuType } from '@shared/models';
import { Subject } from 'rxjs';
import { MatMenuTrigger } from '@angular/material/menu';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

@UntilDestroy()
@Component({
    selector: 'account-menu',
    templateUrl: './account-menu.component.html',
    styleUrls: ['./account-menu.component.scss'],
})
export class AccountMenuComponent implements OnInit {
    @Input() menuType: MenuType = 'side';

    @Input() viewType: 'full' | 'short';

    @HostBinding('class.dynamic')
    isDynamic: boolean;

    @Input() menuReady$ = new Subject<boolean>();
    @Input() menuTrigger$: Subject<void>;
    @ViewChild('menuTrigger') menuTrigger: MatMenuTrigger;

    readonly PRODUCT_TYPE = PRODUCT_TYPE;

    userName: string;
    userEmail: string;
    userSubtitle: string;
    userNameAbbr: string;
    userRole: string;
    userAvatarUrl: string;
    markModeEnabled: boolean;

    get showAccountSettings(): boolean {
        return this.roleService.hasAdminRole();
    }

    get showMarkModeToggle(): boolean {
        return this.roleService.hasMarkModeRole();
    }

    get showLogout(): boolean {
        return this.config.appInfo.accountMenu.showLogout;
    }

    get showEmailNotifications(): boolean {
        return this.config.appInfo.accountMenu.showEmailNotifications && !this.isDynamic;
    }

    get showSubscriptionPlans(): boolean {
        return this.config.appInfo.accountMenu.showSemrushSubscriptionPlans;
    }

    constructor(
        @Inject(WINDOW) private window: Window,
        private router: Router,
        private roleService: RoleService,
        private accountService: AccountService,
        private authService: AuthService,
        private eventsService: EventsService,
        private markStateService: MarkStateService,
        private analyticsService: AnalyticsService,
        private config: AppConfig,
    ) {
        this.markModeEnabled = this.markStateService.isEnabled();
    }

    ngOnInit(): void {
        this.setUserNameAndRole();
        this.isDynamic = !!this.menuTrigger$;
        this.menuTrigger$?.pipe(untilDestroyed(this)).subscribe(() => this.menuTrigger.openMenu());

        this.userSubtitle = this.viewType ? this.userEmail : this.userRole;
    }

    openAMU(): void {
        this.trackAnalytics('Account settings');

        const url = this.authService.getAdminPanelUrl();

        this.window.open(url, '_blank');
    }

    openUserSettings(): void {
        this.trackAnalytics('User settings');

        this.router.navigate([productAdIntelligencePath, 'settings', 'general']);
    }

    openEmailNotificationsSettings(): void {
        this.trackAnalytics('Email notifications');

        this.router.navigate([productAdIntelligencePath, 'settings', 'email-notifications']);
    }

    openSubscriptionPlans(): void {
        this.router.navigate([productAdIntelligencePath, 'pricing']);
    }

    onMarkModeStateChanged(change: MatSlideToggleChange): void {
        if (change.checked) {
            this.eventsService.sendMessage(EventsEnum.MARK_MODE_ENABLE);
        } else {
            this.eventsService.sendMessage(EventsEnum.MARK_MODE_DISABLE);
        }
    }

    onSwitchMarkModeStateClicked($event: Event): void {
        $event.stopPropagation();
    }

    onLogoutClick(): void {
        this.trackAnalytics('Logout');

        this.authService.logout();
    }

    private trackAnalytics(targetName: string): void {
        this.analyticsService.trackBusinessAction('User Profile Dropdown Click', {
            'User Profile Dropdown Click Target': targetName,
        });
    }

    private setUserNameAndRole(): void {
        this.accountService.$currentUser.subscribe((user) => {
            this.userName = `${user.firstName} ${user.lastName}`;
            this.userEmail = user.email;
            this.userNameAbbr = this.shortenName(user);
            this.userAvatarUrl = user.avatarUrl && user.avatarUrl + '?' + Math.random();

            const role = this.roleService.getRoleItemById(this.roleService.getHighestRole(user.roles));

            this.userRole = role?.name;
        });
    }

    private shortenName(user: IUser): string {
        let result = '';

        if (user.firstName) {
            result += user.firstName[0];
        }

        if (user.lastName) {
            result += user.lastName[0];
        }

        return result;
    }
}
