<div *ngIf="items" class="toolbar">
    <div fxLayout="row" fxLayoutAlign="space-between center" class="mb-12">
        <div fxFlex fxLayout="row wrap" fxLayoutAlign="start center" class="mr-24">
            <p class="title mr-12">{{ title }}</p>

            <div class="label-after-title" *ngIf="options && options.afterTitleLabel">
                <p class="mr-12">{{ options.afterTitleLabel }}</p>
            </div>

            <p *ngIf="showEntitiesIcons" class="label-for mr-8">for</p>

            <div fxFlex class="items" *ngIf="showEntitiesIcons && items.length === 1" #wrapper>
                <multiline-entities-list
                    [entities]="items"
                    [parent]="wrapper"
                    [noAnimation]="true"
                    [isComparison]="widgetInfo.data.comparison"
                ></multiline-entities-list>
            </div>
        </div>

        <div *ngIf="filters$ | async as filters" fxLayout="row wrap" fxLayoutAlign="start center">
            <ng-content select="[filters]"></ng-content>

            <div class="context-value">
                <channel-icon [channel]="widgetInfo.data.filters.channel" class="mr-8"></channel-icon>
                <p>{{ widgetInfo.data.filters.channel.title }}</p>
            </div>

            <div class="context-value">
                <device-icon [device]="widgetInfo.data.filters.device" class="mr-8"></device-icon>
                <p>{{ widgetInfo.data.filters.device.title }}</p>
            </div>

            <div class="context-value">
                <mat-icon svgIcon="calendar" class="calendar-icon mr-8 s-16"></mat-icon>
                <p class="mr-24">{{ filters.period?.title }}</p>
            </div>

            <div class="context-value" *ngIf="options && options.afterPeriodLabel">
                <p>{{ options.afterPeriodLabel }}</p>
            </div>

            <div class="context-value">
                <img alt="flag" class="mr-8" src="/assets/images/flags_iso/16/{{ filters.country?.code }}.png" />
                <p>{{ filters.country?.code | uppercase }}</p>
            </div>

            <div *ngIf="filters.taxonomyTopic" class="context-value">
                <mat-icon class="s-16 mr-8" svgIcon="filter"></mat-icon>
                <p>{{ filters.taxonomyTopic?.name }}</p>
            </div>
        </div>
    </div>

    <div fxLayout="row wrap" class="items mb-6" *ngIf="showEntitiesIcons && items.length > 1" #wrapper>
        <multiline-entities-list
            [entities]="items"
            [parent]="wrapper"
            [noAnimation]="true"
            [isComparison]="widgetInfo.data.comparison"
        ></multiline-entities-list>
    </div>

    <div class="divider"></div>
</div>
