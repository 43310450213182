import { MatMenuTrigger } from '@angular/material/menu';
import {
    AfterViewInit,
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    ElementRef,
    EventEmitter,
    HostBinding,
    HostListener,
    Input,
    OnDestroy,
    OnInit,
    Output,
    ViewChild,
} from '@angular/core';
import { debounce, delay, filter, switchMap } from 'rxjs/operators';
import { merge, of, Subject, timer } from 'rxjs';
import { EntityGroup, EntityItem } from '@main/models';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

type Separator = 'SEPARATOR';

const SEPARATOR: Separator = 'SEPARATOR';

type GroupedItem = Separator | EntityItem[];
type PlainItem = Separator | EntityItem;

@UntilDestroy()
@Component({
    selector: 'multiline-entities-list',
    templateUrl: './multiline-entities-list.component.html',
    styleUrls: ['./multiline-entities-list.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MultilineEntitiesListComponent implements AfterViewInit, OnInit, OnDestroy {
    @Input() parent: HTMLElement;
    @Input() entities: EntityItem[] = [];
    @Input() tooltipMode = false;
    @Input() isComparison: boolean = false;
    @Input() reloadOnResize = false;
    @Input() closeOthersPopup: Subject<void> = null;

    @HostBinding('class.no-animation')
    @Input()
    noAnimation = false;

    @Output() animationDone = new EventEmitter<boolean>();

    @ViewChild(MatMenuTrigger) othersPopup: MatMenuTrigger;

    private elementContent: HTMLElement;
    private resize$ = new Subject<void>();
    private checkInterval: NodeJS.Timer;

    showedEntities: PlainItem[] = [];
    groupedHiddenItems: GroupedItem[] = [];

    private hiddenItems: PlainItem[] = [];
    private resizeObserver: ResizeObserver;
    private contentMutationObserver: MutationObserver;

    @HostListener('window:resize')
    onResize(): void {
        this.resize$.next();
    }

    @HostBinding('class.visible')
    visible = false;

    constructor(private cdr: ChangeDetectorRef, private elementRef: ElementRef) {}

    get operand(): string {
        return this.isComparison ? 'vs' : null;
    }

    onTriggerLeave$ = new Subject<'leaves'>();
    onMenuEnters$ = new Subject<'enters'>();

    ngOnInit() {
        this.checkInterval = setInterval(() => {
            if (this.elementRef.nativeElement.clientHeight) {
                this.process();
            }
        }, 100);

        this.showedEntities = this.unwrapEntities(this.entities);

        this.resize$.pipe(debounce(() => timer(500))).subscribe(() => {
            this.process();
        });

        const conf = { childList: true };
        this.contentMutationObserver = new MutationObserver((mutationsList) => {
            mutationsList.forEach((mutation) => {
                if (mutation.type === 'childList') {
                    this.resize$.next();
                }
            });
        });

        this.resizeObserver = new ResizeObserver(() => {
            this.showedEntities = this.unwrapEntities(this.entities);
            this.hiddenItems = [];
            this.groupedHiddenItems = [];
            this.process();
        });

        merge(this.onTriggerLeave$, this.onMenuEnters$)
            .pipe(
                untilDestroyed(this),
                switchMap((v) => (v === 'leaves' ? of(v).pipe(delay(500)) : of(v))),
                filter((v) => v === 'leaves'),
            )
            .subscribe(() => this.tooltipMode && this.othersPopup?.closeMenu());

        this.resizeObserver.observe(this.parent);
        this.contentMutationObserver.observe(this.elementRef.nativeElement, conf);
    }

    ngAfterViewInit(): void {
        this.elementContent = this.elementRef.nativeElement;

        setTimeout(() => {
            this.process();
        });
    }

    ngOnDestroy() {
        this.contentMutationObserver.disconnect();
        this.resizeObserver.disconnect();
    }

    process(): void {
        if (this.parent.clientHeight < this.elementContent.clientHeight) {
            if (this.reloadOnResize) {
                this.animationDone.next(false);
                this.visible = false;
            }

            this.hiddenItems = [this.showedEntities.pop(), ...this.hiddenItems];
            this.groupedHiddenItems = this.getHiddenItemsGrouped(this.hiddenItems);
        } else if (this.elementContent.clientHeight) {
            this.visible = true;
            this.animationDone.next(true);
            clearInterval(this.checkInterval);

            if (!this.reloadOnResize) {
                this.contentMutationObserver.disconnect();
                this.resizeObserver.disconnect();
            }
        }

        this.cdr.markForCheck();
    }

    getHiddenItemsGrouped(hiddenItems: PlainItem[]): GroupedItem[] {
        return hiddenItems.reduce((acc, curr) => {
            if (curr === 'SEPARATOR') {
                return [...acc, curr];
            } else {
                if (acc?.at(-1) === SEPARATOR || acc?.at(-1) === undefined) {
                    return [...acc, [curr]];
                } else if (Array.isArray(acc?.at(-1))) {
                    const lastElem = acc.at(-1);
                    const head = acc.slice(0, -1);

                    return [...head, [...lastElem, curr]];
                }
            }

            return [...acc, [curr]];
        }, []);
    }

    private unwrapEntities(entities: EntityItem[]): EntityItem[] {
        return entities.reduce((acc, curr, i) => {
            const itemsGroup = curr instanceof EntityGroup ? [...curr.items] : [curr];
            const next = i + 1 < entities.length && this.isComparison ? [...itemsGroup, SEPARATOR] : itemsGroup;

            return [...acc, ...next];
        }, []);
    }

    protected readonly Array = Array;
}
