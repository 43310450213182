import { Injectable } from '@angular/core';
import { forkJoin, Observable, ReplaySubject } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';

import { AdBlockCheckMethod } from '../methods/adblock-check.class';
import { AdblockCheckMethod1 } from '../methods/adblock-check.method1';
import { AdblockCheckMethod2 } from '../methods/adblock-check.method2';

@Injectable()
export class AdblockCheckService {
    private $detectorsReady = new ReplaySubject<void>(1);

    private detectMethods: AdBlockCheckMethod[] = [new AdblockCheckMethod1(), new AdblockCheckMethod2()];

    constructor() {
        forkJoin(this.detectMethods.map((method) => method.init())).subscribe(() => {
            this.$detectorsReady.next(undefined);
            this.$detectorsReady.complete();
        });
    }

    isAdBlockDetected(): Observable<boolean> {
        return this.$detectorsReady.pipe(
            switchMap(() =>
                forkJoin(this.detectMethods.map((method) => method.check())).pipe(
                    map((results) => results.find((v) => v) || false),
                ),
            ),
        );
    }
}
