export enum SystemColor {
    ColorDataPurple = 'color-data-purple',
    ColorDataTeal = 'color-data-teal',
    ColorDataYellow = 'color-data-yellow',
    ColorDataPink = 'color-data-pink',
    ColorDataGraphite = 'color-data-graphite',
    ColorDataMint = 'color-data-mint',
    ColorDataCoral = 'color-data-coral',
    ColorDataOlive = 'color-data-olive',
    ColorDataAzure = 'color-data-azure',
    ColorDataPeach = 'color-data-peach',

    ColorNeutral10 = 'color-neutral-10',
    ColorNeutral30 = 'color-neutral-30',
    ColorNeutral40 = 'color-neutral-40',
}
