import { Component, inject, Input, OnInit } from '@angular/core';
import { Observable, of, ReplaySubject } from 'rxjs';
import { delay, switchMap, tap } from 'rxjs/operators';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

import { REPORT_CONFIG } from '@shared/const/report-config';
import { GLOBAL_EXPORT_OPTIONS } from '@shared/const/global-export-options';
import { EXPORT_FORMAT } from '@main/services/export.service.types';
import { LimitsService } from '@main/services/limits.service';
import { FeatureToggleService } from '@main/shared/feature-toggle';
import { ReportService } from '@main/core/report.service';
import { GlobalExportExcelService } from '@main/services/global-export-excel.service';
import { GlobalExportPdfService } from './global-export-pdf.service';
import { ExportOption, UpdateStateParams } from '../common-export-button/common-export-button.types';
import { PAGE_TYPE } from '@main/enums';
import { AnalyticsService, BusinessActionPayload } from '@app/modules/analytics';
import { FiltersService } from '@shared/services';
import { DashboardsReportService } from '@main/dashboards/services/dashboards.report.service';
import { Feature } from '@app/app-main/shared/feature-toggle/feature-toggle.model';

@UntilDestroy()
@Component({
    selector: 'app-global-export-button',
    templateUrl: './global-export-button.component.html',
    styleUrls: ['./global-export-button.component.scss'],
})
export class GlobalExportButtonComponent implements OnInit {
    @Input() exportTypes: EXPORT_FORMAT[] = [];
    @Input() disabled = false;
    @Input() disabledTooltip = '';

    exportItems: ExportOption[];
    fileNamTpl: string;

    exportUpdateState$ = new ReplaySubject<UpdateStateParams>(2);

    exportHandler: (formatId: EXPORT_FORMAT) => Observable<void> = this.export.bind(this);

    isDisabled = false;
    tooltip = '';

    private readonly reportConfig = inject(REPORT_CONFIG);
    private readonly globalExportOptions = inject(GLOBAL_EXPORT_OPTIONS);

    constructor(
        private globalExportPdfService: GlobalExportPdfService,
        private globalExportExcelService: GlobalExportExcelService,
        private limitsService: LimitsService,
        private reportService: ReportService,
        private analyticsService: AnalyticsService,
        private filtersService: FiltersService,
        private featureToggleService: FeatureToggleService,
    ) {}

    ngOnInit(): void {
        this.exportItems = this.filterItems(this.globalExportOptions);

        this.limitsService.showLimitPage$.pipe(untilDestroyed(this)).subscribe((isLimit) => {
            this.exportUpdateState$.next({
                id: EXPORT_FORMAT.GLOBAL_PDF,
                notAvailable: isLimit,
            });
        });

        this.reportService.globalExportReady$.pipe(untilDestroyed(this)).subscribe((isReady) => {
            this.exportUpdateState$.next({
                id: EXPORT_FORMAT.GLOBAL_PDF,
                ready: isReady,
            });

            this.exportUpdateState$.next({
                id: EXPORT_FORMAT.GLOBAL_EXCEL,
                ready: isReady,
            });
        });
    }

    export(item: ExportOption): Observable<any> {
        switch (item.id) {
            case EXPORT_FORMAT.GLOBAL_PDF:
                return this.exportPdf();

            case EXPORT_FORMAT.GLOBAL_EXCEL:
                return this.globalExportExcelService.export();
        }
    }

    private filterItems(items: ExportOption[]): ExportOption[] {
        return items.filter((v) => this.reportConfig.globalExportFormats.includes(v.id));
    }

    private exportPdf(): Observable<boolean> {
        return of(null).pipe(
            tap(() => this.limitsService.safeLimitsNotificationRemove()),
            delay(1000),
            switchMap(() => this.globalExportPdfService.export()),
            tap(() => {
                if (this.reportConfig.pageType === PAGE_TYPE.DASHBOARDS) {
                    this.sendAnalytics();
                }
            }),
        );
    }

    sendAnalytics(): void {
        const { dashboard } = this.reportService as unknown as DashboardsReportService;

        const payload: BusinessActionPayload = {
            'Dashboard Id': dashboard?.id,
            Channel: this.filtersService.channel$.getValue().title,
            Country: this.filtersService.country$.getValue().title,
            Period: this.filtersService.period$.getValue()?.title,
            Format: EXPORT_FORMAT.GLOBAL_PDF,
        };

        if (this.featureToggleService.isEnabled(Feature.TAXONOMY)) {
            payload.Taxonomy = this.filtersService.taxonomyTopic$.getValue()?.name;
        }

        this.analyticsService.trackBusinessAction('[Dashboard] Dashboard Export', payload);
    }
}
