import { SystemColor } from '../../models';
import { getColors, getColorsPalette } from '../../utils';

export const chartColorNames = [
    SystemColor.ColorDataPurple,
    SystemColor.ColorDataTeal,
    SystemColor.ColorDataYellow,
    SystemColor.ColorDataPink,
    SystemColor.ColorDataGraphite,
    SystemColor.ColorDataMint,
    SystemColor.ColorDataCoral,
    SystemColor.ColorDataOlive,
    SystemColor.ColorDataAzure,
    SystemColor.ColorDataPeach,
];

export const chartColors = getColors(chartColorNames);
export const chartPalette = getColorsPalette(chartColorNames);
