import { Inject, Injectable } from '@angular/core';
import { StorageService } from '@app/core';

import { AppConfig } from '@app/app.config';
import { Feature } from '../feature-toggle.model';
import { ENABLED_BY_DEFAULT_FEATURES } from '../feature-toggle.providers';

@Injectable()
export class FeatureToggleService {
    private get features(): Record<string, boolean> {
        return this.config?.settings?.features;
    }

    constructor(
        @Inject(ENABLED_BY_DEFAULT_FEATURES) private enabledByDefaultFeatures: Feature[],
        private storageService: StorageService,
        private config: AppConfig,
    ) {}

    isEnabled(feature: Feature): boolean {
        if (!this.config.appInfo.featuresEnabled) return false;

        if (this.isEnabledByDefault(feature)) return true;

        const isEnabled = this.storageService.get<boolean>(feature);

        return Boolean(isEnabled ?? this.features?.[feature]);
    }

    private isEnabledByDefault(feature: Feature): boolean {
        return this.enabledByDefaultFeatures.includes(feature);
    }
}
