<button
    mat-button
    mat-flat-button
    type="button"
    color="accent"
    class="u-button btn-back h-32"
    data-unit="back-button"
    (click)="goBack()"
>
    <mat-icon class="s-16" svgIcon="arrowLeft"></mat-icon>
    Back
</button>
