import { Injectable } from '@angular/core';
import { Maintenance } from '@main/services/maintenance.service.types';
import { AppConfig } from '@app/app.config';

@Injectable({ providedIn: 'root' })
export class MaintenanceService {
    private get data(): Record<Maintenance, unknown> {
        return this.config?.settings?.maintenance;
    }

    constructor(private config: AppConfig) {}

    get<T>(key: Maintenance): T {
        return (this.data?.[key] as T) || null;
    }
}
