import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    EventEmitter,
    inject,
    Input,
    OnInit,
    Output,
    Renderer2,
    ViewChild,
} from '@angular/core';
import { MatButton } from '@angular/material/button';
import { MatMenuTrigger } from '@angular/material/menu';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { cloneDeep, groupBy } from 'lodash';
import {
    BehaviorSubject,
    combineLatest,
    debounceTime,
    distinctUntilChanged,
    map,
    Observable,
    shareReplay,
    startWith,
    switchMap,
    tap,
} from 'rxjs';
import { IEntityItemId } from '@main/types';
import { ReportHelpers } from '@main/core/report.helpers';
import { Channel, EntityItem, EntityPublisher } from '@main/models';
import { PublishersService } from '@main/services/publishers.service';
import { FiltersValue } from '@shared/services/filters/filters.types';
import { PublisherSelectorItem } from './publisher-selector.types';

@UntilDestroy()
@Component({
    selector: 'publisher-selector',
    templateUrl: './publisher-selector.component.html',
    styleUrls: ['./publisher-selector.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PublisherSelectorComponent implements OnInit {
    @ViewChild('menuTrigger') menuTrigger: MatMenuTrigger;
    @ViewChild('toggleButton') toggleButton: MatButton;

    @Input({ required: true }) entities: EntityItem[];
    @Input({ required: true }) filters: FiltersValue;
    @Input() publisherIds: IEntityItemId[] = [];

    @Input()
    set channels(value: Channel[]) {
        this._channels = value ?? [];

        if (this._channels.every((channel) => channel.type !== this.selectedChannel$.value?.type)) {
            this._channel = this._channels[0];
            this.selectedChannel$.next(this._channel);
        }
    }
    get channels(): Channel[] {
        return this._channels ?? [];
    }

    @Input()
    set channel(value: Channel) {
        this._channel = value;
        this.selectedChannel$.next(value);
    }

    @Output() publisherIdsChange = new EventEmitter<IEntityItemId[]>();

    selectedChannel$ = new BehaviorSubject<Channel | null>(null);
    searchValue = '';
    noSearchResults = false;
    isLoading = false;

    filteredItems$: Observable<PublisherSelectorItem[]>;
    currentSelectedItems: PublisherSelectorItem[] = [];

    private _channels: Channel[];
    private _channel: Channel;
    private maxSelectedItems = 10;
    private selectedPublishers: PublisherSelectorItem[] = [];
    private updateFilteredItems$ = new BehaviorSubject<void>(null);
    private searchQuerySubject$ = new BehaviorSubject<string>('');
    private searchQuery$ = this.searchQuerySubject$.asObservable().pipe(
        startWith(''),
        debounceTime(200),
        map((term) => term.trim()),
        distinctUntilChanged(),
        untilDestroyed(this),
    );

    private readonly publishersService = inject(PublishersService);
    private readonly cdr = inject(ChangeDetectorRef);
    private readonly renderer = inject(Renderer2);

    constructor() {
        this.handleClickOutside(() => this.menuTrigger.closeMenu());
    }

    ngOnInit(): void {
        this.updateSelectedPublisherItems(this.publisherIds);
    }

    get buttonTitle(): string {
        const { length } = this.publisherIds;
        return length ? `${length} publisher${length > 1 ? 's' : ''} selected` : 'Filter by publisher';
    }

    onSelectionChange(item: PublisherSelectorItem): void {
        if (this.isItemDisabled(item)) {
            return;
        }

        item.selected = !item.selected;
        if (item.selected) {
            this.currentSelectedItems.push(item);
        } else {
            this.currentSelectedItems = this.currentSelectedItems.filter((i) => i.id !== item.id);
        }
        this.updateFilteredItems$.next();
    }

    onRemove(item: PublisherSelectorItem): void {
        item.selected = false;
        this.currentSelectedItems = this.currentSelectedItems.filter((i) => i.id !== item.id);
        this.updateFilteredItems$.next();
    }

    clearSelection(event?: Event): void {
        event?.stopPropagation();
        this.selectedPublishers = [];
        this.publisherIds = [];
        this.publisherIdsChange.emit(this.publisherIds);
        this.resetFilter();
    }

    onOpen(): void {
        this.filteredItems$ ??= combineLatest([this.getFilteredItems$(), this.updateFilteredItems$]).pipe(
            map(([items]) => {
                this.updateSelectedItems(items);
                return items;
            }),
        );

        this.currentSelectedItems = cloneDeep(this.selectedPublishers);
        this.updateFilteredItems$.next();

        // Focus on the search input.
        this.searchValue = ' ';
        setTimeout(() => {
            this.searchValue = '';
        });
    }

    onClose(): void {
        this.searchQuerySubject$.next('');
        this.selectedChannel$.next(this._channel);
    }

    onCancel(): void {
        this.menuTrigger.closeMenu();
    }

    onApply(): void {
        this.selectedPublishers = this.currentSelectedItems;
        this.publisherIds = this.currentSelectedItems.map((item) => item.id);
        this.publisherIdsChange.emit(this.publisherIds);
        this.menuTrigger.closeMenu();
    }

    onSearchChange(value: string): void {
        this.searchValue = value;
        this.searchQuerySubject$.next(value);
    }

    onSelectChannel(channel: Channel): void {
        this.selectedChannel$.next(channel);
    }

    getPublisherChannelsTooltip(item: PublisherSelectorItem): string {
        const channelNames = item.channels ? item.channels.map(({ title }) => title).join(', ') : [];
        return `Channels:\n${channelNames}`;
    }

    isItemDisabled(item: PublisherSelectorItem): boolean {
        return !item.selected && this.currentSelectedItems.length >= this.maxSelectedItems;
    }

    resetFilter(): void {
        this.currentSelectedItems = [];
        this.updateFilteredItems$.next();
    }

    private getFilteredItems$(): Observable<PublisherSelectorItem[]> {
        return combineLatest([
            this.searchQuery$,
            this.selectedChannel$.pipe(distinctUntilChanged(), untilDestroyed(this)),
        ]).pipe(
            tap(() => {
                this.isLoading = true;
                this.noSearchResults = false;
                this.cdr.markForCheck();
            }),
            switchMap(([searchQuery, channel]) => {
                const filters = { ...this.filters };

                if (channel) {
                    filters.channel = channel;
                }

                return this.publishersService.getAvailablePublishers({
                    entities: this.entities,
                    filters,
                    searchQuery,
                    size: 100,
                });
            }),
            map((publishers) =>
                publishers.map((publisher) => {
                    const channels = ReportHelpers.buildChannelsList({
                        currentItems: publisher.channels.elements,
                        noPermissionAction: null,
                        noDataAction: null,
                    });
                    return {
                        id: publisher.id,
                        entity: new EntityPublisher({
                            id: publisher.id,
                            name: publisher.name,
                            favIconUrl: publisher.favIconUrl,
                            type: publisher.type,
                        }),
                        channels,
                    };
                }),
            ),
            tap((publishers) => {
                this.noSearchResults = !publishers.length;
                this.isLoading = false;
                this.cdr.markForCheck();
            }),
            untilDestroyed(this),
            shareReplay({ bufferSize: 1, refCount: true }),
        );
    }

    private updateSelectedItems(items: PublisherSelectorItem[]): void {
        const selectedItemsMap = groupBy(this.currentSelectedItems, 'id');
        items.forEach((i) => (i.selected = !!selectedItemsMap[i.id]));
    }

    private updateSelectedPublisherItems(publisherIds: IEntityItemId[]): void {
        if (!publisherIds.length) {
            this.selectedPublishers = [];
            return;
        }

        this.publishersService
            .getAvailablePublishers({
                entities: this.entities,
                filters: this.filters,
            })
            .pipe(untilDestroyed(this))
            .subscribe((publishers) => {
                const selectedPublishers: PublisherSelectorItem[] = [];

                publishers.forEach(({ id, name, favIconUrl, type }) => {
                    if (publisherIds.includes(id)) {
                        const entity = new EntityPublisher({ id, name, favIconUrl, type });
                        selectedPublishers.push({ id, entity });
                    }
                });

                this.selectedPublishers = selectedPublishers;
            });
    }

    private handleClickOutside(callback: () => void): void {
        this.renderer.listen('document', 'click', (event) => {
            const panelId = this.menuTrigger.menu.panelId;
            const panel = document.getElementById(panelId);

            if (
                callback &&
                this.menuTrigger?.menuOpen &&
                !panel?.contains(event.target) &&
                !this.toggleButton?._elementRef.nativeElement.contains(event.target)
            ) {
                callback();
            }
        });
    }
}
