import { Class } from '@shared/core/class';
import { CHANNEL } from '@main/enums';
import { Device } from './device';
import { IDatasetResponseItemBase } from '@main/interfaces';
import { EntityItem } from '@main/models/EntityItem';

export class Channel extends Class {
    id: number;
    type: CHANNEL;
    title: string;
    devices: Device[];
    restricted: boolean;
    disabled: boolean;

    constructor(data: Partial<Channel>) {
        super();

        this.id = data.id;
        this.type = data.type;
        this.title = data.title;
        this.devices = data.devices;
        this.restricted = data.restricted;
        this.disabled = data.disabled;
    }
}

export interface RestResponseByEntities {
    elements: ChannelsResponse[];
}

export interface ChannelsResponse extends IDatasetResponseItemBase {
    channels: {
        elements: ChannelsElement[];
    };
}

export interface ChannelsAggregatedResponse {
    baseResponse: RestResponseByEntities;
    creativesResponse: RestResponseByEntities;
    benchmarkBaseResponse: ChannelsResponse;
    benchmarkCreativesResponse: ChannelsResponse;
}

export interface ChannelsElement<DataValue extends BaseDataValue = IDataValue> {
    id: number;
    title?: string;
    type?: string;
    expenses?: DataValue;
    impressions?: DataValue;
    advertisers?: {
        count: IDataValue;
    };
    publishers?: {
        count: IDataValue;
    };
    creatives?: {
        count: IDataValue;
    };
}

export type BenchmarkChannelsElement<DataValue extends BaseDataValue = IDataValue> = Omit<
    ChannelsElement<DataValue>,
    'title' | 'type' | 'advertisers'
>;

export type CategoryBenchmarkChannelsElement = BenchmarkChannelsElement<BenchmarkDataValue>;

export type BenchmarkResponseByEntities = Readonly<{
    advertisers?: { elements: BenchmarkChannelsElement[] };
    brands?: { elements: BenchmarkChannelsElement[] };
    category: CategoryBenchmarkChannelsElement;
}>;

export type ResponseByEntities = RestResponseByEntities | BenchmarkResponseByEntities;

type BaseDataValue = Readonly<{ share: number }>;

export interface IDataValue extends BaseDataValue {
    total: number;
    average?: number;
}

export type BenchmarkDataValue = BaseDataValue & Required<Pick<IDataValue, 'average'>>;

export interface ChannelDataByEntity {
    entity: EntityItem;
    impressions: IDataValue;
    expenses: IDataValue;
}
