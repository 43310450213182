export enum Feature {
    CATEGORY_REPORT = 'category_report',
    CATEGORY_REPORT_BENCHMARK = 'category_report_benchmark',
    BRAND_REPORT = 'brand_report',
    SUB_BRANDS = 'sub_brands',
    TAXONOMY = 'taxonomy',
    DASHBOARDS = 'dashboards',
    ADVERTISER_REPORT_BENCHMARK = 'advertiser_report_benchmark',
    BRAND_REPORT_BENCHMARK = 'brand_report_benchmark',
    BRANDS_MORE_COUNTRIES = 'brands_more_countries',
    USERFLOW = 'userflow',
}

export type FeatureToggleModuleOptions = Readonly<{
    enabledByDefaultFeatures?: Feature[];
}>;
