import { QueryList } from '@angular/core';
import { AbstractControl } from '@angular/forms';
import { PageEvent } from '@angular/material/paginator';
import { Sort } from '@angular/material/sort';
import { Observable, Subscription } from 'rxjs';
import { isNil } from 'lodash';
import * as moment from 'moment';

import { maxExportFileNameLength } from 'app/config';
import { Constants } from '@app/util/constants';
import { Item as FilterItem } from '@shared/components/grid-filter/grid-filter.types';
import { IEntityItemId } from '@main/types';
import { PerfectScrollbarDirective } from '../directives/perfect-scrollbar.directive';
import { CommonPeriodCode, Period } from '../models';
import { PERIOD_TYPE } from '../enums';
import { RequestParams } from '../models/request-params.model';

export class CommonHelpers {
    static composeDownloadFileName(values: string[] = [], extension?: string): string {
        const dateStr = this.trimFileName(moment().format(Constants.DATE_FMT_ALT));
        const g = '_';
        let valuesPart = '';

        if (values?.length) {
            valuesPart += values.filter((v) => Boolean(v)).join(g);
        }

        valuesPart = this.trimFileName(valuesPart);

        const suffixPart = g + dateStr + (extension ? `.${extension}` : '');
        const finalMaxLength = maxExportFileNameLength - suffixPart.length;

        if (valuesPart.length > finalMaxLength) {
            valuesPart = valuesPart.substr(0, finalMaxLength - 3) + '...';
        }

        return valuesPart + suffixPart;
    }

    static templateFileName(tpl: string, values: Record<string, string>): string {
        let result = tpl;

        Object.entries(values).forEach(([key, value]) => {
            result = result.replace(`{${key}}`, value);
        });

        return result;
    }

    static finalizeExportFileNameTpl(tpl: string, extension = '', appendDate = true): string {
        const dateStr = this.trimFileName(moment().format(Constants.DATE_FMT_ALT));

        let basePart = this.trimFileName(tpl);

        const appendPart = (appendDate ? '_' + dateStr : '') + (extension ? `.${extension}` : '');
        const finalMaxLength = maxExportFileNameLength - appendPart.length;

        if (basePart.length > finalMaxLength) {
            basePart = basePart.substr(0, finalMaxLength - 3) + '...';
        }

        return [basePart, appendPart].join('').replace(/_?\.\.\._?/, '...');
    }

    static formatShortNumber(value: number | string, precision = 0, roundSmall = false, naStr = 'N/A'): string {
        const units = ['K', 'M', 'B', 'T'];

        if (isNil(value)) return naStr;

        if (typeof value === 'string') {
            if (isNaN(parseFloat(value))) return value;
            value = parseFloat(value);
        }

        if (!isFinite(value)) return value.toString();

        if (roundSmall && value < 1000) {
            return Math.round(value).toString();
        }

        const trim = (val: any, i: number = 0): string => {
            const d = val / 1000;

            if (Math.floor(d) > 0 && units[i]) {
                const n = Number(d.toFixed(d % 1 === 0 ? 0 : precision));
                return trim(n, ++i);
            } else {
                if (i === 0) {
                    return val.toFixed(val % 1 === 0 ? 0 : precision);
                }
                return val + units[i - 1];
            }
        };

        return trim(value);
    }

    static focusFormControl(control: AbstractControl, delay = 100): void {
        const controlNative = (control as any).nativeElement;

        setTimeout(() => {
            controlNative.focus();
        }, delay);
    }

    static resetScrollBars(scrollBars: QueryList<PerfectScrollbarDirective>, resetScroll = false): void {
        if (!scrollBars) {
            return;
        }

        setTimeout(() => {
            scrollBars.forEach((item) => {
                this.resetScrollBar(item, resetScroll);
            });
        });
    }

    static resetScrollBar(scrollBar: PerfectScrollbarDirective, resetScroll = false): void {
        if (!scrollBar) {
            return;
        }

        setTimeout(() => {
            if (resetScroll) {
                scrollBar.scrollToLeft();
                scrollBar.scrollToTop();
            }

            scrollBar.update();
        });
    }

    static getValueByPath<T>(obj: any, path: string, defaultValue?: T): T {
        const value = path && path.split('.').reduce((a, v) => a && a[v], obj);

        return typeof value === 'undefined' ? defaultValue : (value as T);
    }

    static capitalizeFirstLetter(string) {
        return string.charAt(0).toUpperCase() + string.toLowerCase().slice(1);
    }

    static equilObjects(a, b): boolean {
        return JSON.stringify(a) === JSON.stringify(b);
    }

    static resetSubscription(subscription: Subscription): void {
        if (subscription) {
            subscription.unsubscribe();
            subscription = null;
        }
    }

    static alignPercentValuesTo100(values: number[]): number[] {
        const items = values.map((v, i) => ({
            index: i,
            value: Math.floor(v),
            decimal: v % 1,
        }));

        const summ = (ar) => ar.reduce((a, v) => a + v.value, 0);

        const precisionError = 100 - summ(items);

        if (precisionError > 0) {
            /*  sorting by decimals descenging */
            items.sort((v1, v2) => v2.decimal - v1.decimal);

            for (const item of items) {
                item.value++;

                if (summ(items) === 100) {
                    break;
                }
            }

            /* sorting to origin order */
            items.sort((v1, v2) => v1.index - v2.index);
        }

        return items.map((v) => v.value);
    }

    static deepClone<T>(obj: T): T {
        return JSON.parse(JSON.stringify(obj));
    }

    static getCurrentUrlHash(): string {
        return new URL(window.location.href).hash;
    }

    static uniqValues(ids: number[]): number[] {
        return [...new Set(ids)];
    }

    static getPeriodMonthsCount(period: Period): number {
        /* handle period between 2 months */
        if (moment(new Date(period.end)).diff(new Date(period.start), 'days', true) <= 31) {
            return 1;
        }

        const count = Math.ceil(moment(new Date(period.end)).diff(new Date(period.start), 'months', true));

        return count;
    }

    static findMinimalDefaultPeriod(periods: Period[]): Period {
        return (
            periods.find((v) => v.code === CommonPeriodCode.LAST_3_MONTHS) ||
            periods.find((v) => v.code === CommonPeriodCode.LAST_30_DAYS) ||
            periods.find((v) => v.code === CommonPeriodCode.LAST_7_DAYS)
        );
    }

    static findPeriodToFitMonthsCount(periods: Period[], count: number): Period {
        const sortedPeriods = periods.sort((p1, p2) => {
            const p1Months = this.getPeriodMonthsCount(p1);
            const p2Months = this.getPeriodMonthsCount(p2);

            return p2Months - p1Months;
        });

        return sortedPeriods.find(
            (p) =>
                this.getPeriodMonthsCount(p) <= count &&
                (p.type === PERIOD_TYPE.PREDEFINED || p.type === PERIOD_TYPE.MONTHLY),
        );
    }

    static processDataSplitting<T extends { isSplitter?: boolean; entity: { id: IEntityItemId } }>(
        items: T[],
        benchmarkEntityId: IEntityItemId,
    ): T[] {
        const processedData = items.sort((item) => (item?.entity.id === benchmarkEntityId ? -1 : 0));

        if (benchmarkEntityId) {
            processedData.splice(1, 0, { isSplitter: true } as T);
        }

        return processedData;
    }

    static copyProps<T = object>(source: T, target: T): T {
        Object.getOwnPropertyNames(source).forEach((key) => {
            target[key] = source[key];
        });

        return target;
    }

    static createRequestParams(paging: PageEvent, sorting: Sort, filters: FilterItem[], search: string) {
        const requestParams = new RequestParams();

        if (paging) {
            requestParams.setPagingFromPager(paging);
        }

        if (sorting) {
            requestParams.setSort(sorting.direction ? { field: sorting.active, direction: sorting.direction } : null);
        }

        if (filters) {
            requestParams.setFilters(
                filters.map((v) => ({
                    id: v.id,
                    type: v.type,
                    value: v.value,
                })),
            );
        }

        requestParams.setSearch(
            'name',
            search
                ? {
                      operation: 'contains',
                      item: search,
                  }
                : undefined,
        );

        return requestParams;
    }

    static injectScript(path: string): void {
        const script = document.createElement('script');
        script.src = path;
        document.head.appendChild(script);
    }

    static decorateHistoryChangeHandlers(fn: (method: string, url: string) => void): void {
        const wrap = (method, origin) =>
            function (_data, _title, url) {
                fn(method, url);
                return origin.apply(this, arguments);
            };

        history.pushState = wrap('replaceUrl', history.pushState);
        history.replaceState = wrap('replaceUrl', history.replaceState);
    }

    static observeOnMutation(target: HTMLElement, config: MutationObserverInit): Observable<MutationRecord[]> {
        return new Observable((observer) => {
            const mutationObserver = new MutationObserver((mutations) => {
                observer.next(mutations);
            });

            mutationObserver.observe(target, config);

            return () => mutationObserver.disconnect();
        });
    }

    private static trimFileName(fileName: string): string {
        return fileName
            .replace(/(https?:|)\/\//g, '')
            .replace(/[^\p{L}0-9.-_()]/gu, '_')
            .replace(/[:\/\?\[\]\^]/g, '_')
            .replace(/_+/g, '_')
            .replace(/^_+/, '')
            .replace(/_+$/, '');
    }
}
