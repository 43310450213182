<div class="dialog-content-wrapper">
    <div mat-dialog-content>
        <button
            class="btn-close"
            mat-icon-button
            (click)="matDialogRef.close()"
            aria-label="Close dialog"
            tabindex="-1"
        >
            <mat-icon class="s-12">close</mat-icon>
        </button>

        <div class="row">
            <div class="column-left">
                <img src="/assets/icons/adblock.png" />
            </div>

            <div class="column-right" fxFlex>
                <p class="title">Looks like you are having trouble opening the platform</p>

                <p class="text">
                    AdClarity may not work while using ad-blockers. To continue please disable any ad-blocker plugins
                    through Extension settings on your browser.
                </p>
            </div>
        </div>
    </div>

    <div mat-dialog-actions>
        <button class="button-close" (click)="matDialogRef.close()">Close</button>
    </div>
</div>
