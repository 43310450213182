import { inject, Pipe, PipeTransform } from '@angular/core';
import { AdcCurrencyPipe } from '../currency.pipe';
import { Currency } from '../../models';

/**
 * Custom pipe that calculates the cost per thousand impressions (CPM)
 * based on the given cost and impressions.
 *
 * @param currency - The currency to be used for formatting the result.
 * @param cost - The cost value.
 * @param impressions - The number of impressions.
 * @param shortFormat - Whether to use the short format for the currency.
 * @returns The formatted cost per thousand impressions (CPM) value.
 */
@Pipe({ name: 'cpm' })
export class CpmPipe implements PipeTransform {
    private readonly adcCurrency = inject(AdcCurrencyPipe);

    transform(currency: Currency, cost: number, impressions: number, shortFormat = false): string {
        const cpm = !cost || !impressions ? 0 : (cost / impressions) * 1000;
        return this.adcCurrency.transform(cpm, currency, 2, shortFormat);
    }
}
