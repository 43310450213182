import { CategoryDictionary } from '../models';

export const categoryDictionary: CategoryDictionary[] = [
    {
        range: ['a', 'e'],
        label: 'A - E',
        categories: null,
    },
    {
        range: ['f', 'i'],
        label: 'F - I',
        categories: null,
    },
    {
        range: ['j', 'n'],
        label: 'J - N',
        categories: null,
    },
    {
        range: ['m', 'r'],
        label: 'M - R',
        categories: null,
    },
    {
        range: ['s', 'z'],
        label: 'S - Z',
        categories: null,
    },
];
