import { ActivatedRoute, Router } from '@angular/router';
import { Inject, Injectable } from '@angular/core';
import { BehaviorSubject, combineLatest, Observable, of, switchMap } from 'rxjs';
import {
    catchError,
    debounceTime,
    delay,
    distinctUntilChanged,
    filter,
    map,
    shareReplay,
    tap,
    withLatestFrom,
} from 'rxjs/operators';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { cloneDeep } from 'lodash';

import { IntegrationsService } from '@app/integrations/services/integrations.service';
import { WinmoService } from '@app/integrations/winmo/services/winmo.service';
import { INTEGRATION } from '@app/integrations/integrations.types';
import { REPORT_CONFIG } from '@shared/const/report-config';
import { ReportConfig } from '@shared/interfaces/ReportConfig';
import { FiltersService } from '@shared/services/filters/filters.service';
import { AnalyticsService, BusinessActionPayload, CurrencyService } from '@shared/services';
import { ReportSettingsService } from '@shared/services/report-settings.service';
import { IReportComparisonSettings } from '@shared/interfaces/IReportSettings';
import { WidgetData, WidgetInfo } from '@shared/widgets/core/widgets.types';
import { CommonHelpers } from '@shared/helpers/common.helpers';
import { AnalyticsData, ExportItem } from '@shared/components/common-export-button/common-export-button.types';
import { EntityHelpers } from '@main/helpers/entity.helpers';
import { NavigationService } from '@main/services/navigation.service';
import { ViewTokenParams } from '@main/services/view-report.service.types';
import { SerializedFiltersParams } from '@main/helpers/entity.helpers.types';
import { GlobalConnectionService } from '@main/services/global-connection.service';
import { EntityGroup, EntityItem, ReportType } from '../models';
import { BUSINESS_ENTITY, WIDGET } from '../enums';
import { IEntityItemGroupId, IEntityItemId, IEntityItemInstanceId } from '../types';
import { ReportUpdateTriggerResult, WidgetStatus, WidgetTriggerUpdateOptions } from '../services/report.service.types';
import { ViewReportService } from '../services/view-report.service';
import { FeatureToggleService } from '@main/shared/feature-toggle';
import { ReportHelpers } from './report.helpers';
import { ReportServiceBase } from './report.service-base';
import { ReportDataAggregationService } from '../services/report-data-aggregation.service';
import { BenchmarkInfo, ReportDeviationData } from './report.types';
import { GroupsService } from '../services/groups.service';
import { IGroupType } from '../interfaces';
import { IAccessLogUnsavedGroup } from '@main/services/recents.service.types';
import { Feature } from '@main/shared/feature-toggle/feature-toggle.model';

@UntilDestroy()
@Injectable()
export class ReportService extends ReportServiceBase {
    rootEntity$ = new BehaviorSubject<EntityGroup>(null);
    entities$ = new BehaviorSubject<EntityItem[]>([]);

    isBenchmark: boolean;
    benchmarkInfo: BenchmarkInfo;

    reportReady$ = combineLatest([this.rootEntity$, this.filtersService.ready$]).pipe(
        map(([rootEntity, filtersReady]) => Boolean(rootEntity) && filtersReady),
        distinctUntilChanged(),
        shareReplay(1),
    );

    widgets: WidgetInfo[];
    widgetsStatus$ = new BehaviorSubject<WidgetStatus[]>([]);
    deviation$: Observable<ReportDeviationData>;

    get isViewTokenValid(): boolean {
        return Boolean(this.viewReportService.viewToken$.value);
    }

    get rootEntity(): EntityGroup {
        return this.rootEntity$.value;
    }

    get entities(): EntityItem[] {
        return this.entities$.value;
    }

    get fullScreenViewEntityId(): IEntityItemId {
        const entityId = this.navigationService.getQueryParam('entityId');

        return Number(entityId) || entityId;
    }

    get isFullscreenEntityMatchBenchmarkEntity(): boolean {
        if (!this.fullScreenViewEntityId || !this.isBenchmark) return false;

        return this.fullScreenViewEntityId === Number(this.benchmarkInfo?.entityId);
    }

    get fullscreenEntity() {
        const entityType = this.isFullscreenEntityMatchBenchmarkEntity ? this.benchmarkInfo.entityType : null;
        const entityId = this.fullScreenViewEntityId;

        return EntityHelpers.pickEntityById(this.entities, entityId, entityType);
    }

    get analyticsReportType(): ReportType {
        return this.reportType;
    }

    get reportType(): ReportType {
        if (this.rootEntity?.items.length === 1) return ReportType.SINGLE;
        return this.rootEntity?.isComparison() ? ReportType.COMPARISON : ReportType.GROUP;
    }

    get filterParams(): SerializedFiltersParams {
        const filters = this.filtersService.value();

        return filters ? EntityHelpers.serializeFiltersParams(this.filtersService.value()) : null;
    }

    reportUpdateTrigger$: Observable<ReportUpdateTriggerResult>;
    reportTokenUpdateTrigger$: Observable<string>;

    constructor(
        private integrationsService: IntegrationsService,
        private analyticsService: AnalyticsService,
        private filtersService: FiltersService,
        private currencyService: CurrencyService,
        private reportSettingsService: ReportSettingsService,
        private reportDataAggregationService: ReportDataAggregationService,
        private navigationService: NavigationService,
        private viewReportService: ViewReportService,
        private featureService: FeatureToggleService,
        private router: Router,
        private groupsService: GroupsService,
        private globalConnectionService: GlobalConnectionService,
        @Inject(REPORT_CONFIG) private reportConfig: ReportConfig,
    ) {
        super();

        this.reportUpdateTrigger$ = combineLatest([this.entities$, this.filtersService.value$]).pipe(
            filter(() => !!this.entities.length),
            debounceTime(0),
            map(([, filters]) => ({ filters })),
            shareReplay(1),
        );

        this.reportTokenUpdateTrigger$ = this.reportUpdateTrigger$.pipe(
            switchMap(({ filters }) =>
                this.viewReportService
                    .updateToken({
                        entityType: this.reportConfig.entityType,
                        compare: this.reportConfig.comparison,
                        entities: [this.rootEntity],
                        filters,
                        benchmarkInfo: this.benchmarkInfo,
                    })
                    .pipe(
                        catchError((err) => {
                            if (err.status === 400) {
                                this.router.navigate(['bad-request']);
                            }

                            return of(null);
                        }),
                    ),
            ),
            shareReplay(1),
        );

        this.reportTokenUpdateTrigger$
            .pipe(
                untilDestroyed(this),
                filter((token) => Boolean(token)),
                filter(() => !!this.rootEntity),
                withLatestFrom(this.reportUpdateTrigger$),
                map(([, triggers]) => triggers),
                distinctUntilChanged(
                    ({ filters: filtersPrev }, { filters: filtersCurr }) =>
                        filtersPrev.country.id === filtersCurr.country.id,
                ),
                filter(() => !this.isWinmoSneakView()),
            )
            .subscribe(({ filters }) => {
                this.trackReportViewChange({
                    entityType: this.reportConfig.entityType,
                    compare: this.reportConfig.comparison,
                    entities: this.entities,
                    filters,
                    benchmarkInfo: this.benchmarkInfo,
                });
            });

        this.deviation$ = this.reportTokenUpdateTrigger$.pipe(
            filter(Boolean),
            withLatestFrom(this.reportUpdateTrigger$, this.currencyService.currency$),
            switchMap(([, { filters }, currency]) =>
                this.reportDataAggregationService.fetchDataDeviation(
                    this.entities,
                    filters,
                    currency,
                    this.rootEntity.entityType,
                    'widget_month_over_month' as WIDGET,
                ),
            ),
            map((data) => ({
                data,
                impressions: Boolean(data?.deviation?.impressions),
                expenses: Boolean(data?.deviation?.expenses),
            })),
            shareReplay(1),
        );
    }

    getWidgetUpdateTrigger(
        widgetId: string,
        options: WidgetTriggerUpdateOptions = {} as WidgetTriggerUpdateOptions,
    ): Observable<WidgetData> {
        return combineLatest([
            this.reportTokenUpdateTrigger$,
            options.comparisonSettings ? this.reportSettingsService.comparison$ : of<IReportComparisonSettings>(null),
        ]).pipe(
            filter(() => Boolean(this.viewReportService.viewToken)),
            delay(10),
            withLatestFrom(this.reportUpdateTrigger$, this.currencyService.currency$),
            map(([, { filters }, currency]) => {
                const { config, data } = this.widgets.find((v) => v.id === widgetId);

                data.filters = filters;
                data.currency = currency;
                data.deviation$ = config?.showDeviation
                    ? this.deviation$
                    : of({ data: null, impressions: false, expenses: false });

                return cloneDeep(data);
            }),
            shareReplay(1),
        );
    }

    checkForBenchmarkRoute(route: ActivatedRoute): void {
        const {
            queryParams: { category },
        } = route.snapshot;

        if (this.reportConfig.comparison && category) {
            if (
                this.featureService.isEnabled(Feature.ADVERTISER_REPORT_BENCHMARK) ||
                this.featureService.isEnabled(Feature.BRAND_REPORT_BENCHMARK)
            ) {
                this.setBenchmark(BUSINESS_ENTITY.Category, category);
            } else {
                this.router.navigate(['not-found']);
            }
        }
    }

    setRootEntity(entity: EntityGroup): void {
        this.rootEntity$.next(entity);
        let entities = entity.items;

        if (entity.isBenchmark()) {
            const benchmarkEntity = entity.items.find((v) => v.entityType === entity.benchmarkEntityType);
            this.setBenchmark(entity.benchmarkEntityType, benchmarkEntity?.id);

            entities = this.sortEntitiesByBenchmark(entity.items, entity.benchmarkEntityType);
        }

        this.entities$.next(entities);
    }

    sortEntitiesByBenchmark(entities: EntityItem[], benchmarkEntityType: BUSINESS_ENTITY): EntityItem[] {
        return entities.sort((a, b) => {
            if (a.entityType === benchmarkEntityType && b.entityType !== benchmarkEntityType) {
                return -1;
            }
            if (a.entityType !== benchmarkEntityType && b.entityType === benchmarkEntityType) {
                return 1;
            }
            return 0;
        });
    }

    reset(): void {
        this.viewReportService.reset();

        this.rootEntity$.next(null);
        this.entities$.next([]);
        this.isBenchmark = false;
        this.benchmarkInfo = null;
    }

    getGlobalExportFileNameTpl(): string {
        let entities = this.entities;

        if (this.isBenchmark)
            entities = entities.filter(
                ({ id, entityType }) =>
                    id !== this.benchmarkInfo.entityId && entityType !== this.benchmarkInfo.entityType,
            );

        let groupTypePrefix: string;

        if (this.isBenchmark) {
            const entityInfo = EntityHelpers.getEntityInfo(this.rootEntity.entityType);
            groupTypePrefix = `benchmark_${entityInfo.text.few}`;
        } else {
            groupTypePrefix = (entities.length > 1 && (this.reportConfig.comparison ? 'comparison' : 'group')) || '';
        }

        const entitiesTitles = ReportHelpers.serializeExportEntitiesFileNamePart(
            this.rootEntity,
            entities,
            this.benchmarkInfo,
        );

        return CommonHelpers.templateFileName(this.reportConfig.globalExportFileName, {
            groupType: groupTypePrefix,
            entities: entitiesTitles,
        });
    }

    getWidgetExportFileNameTpl(widgetName: string, entities: EntityItem[]): string {
        let fileName = CommonHelpers.templateFileName(this.reportConfig.widgetExportFileName, {
            widgetName,
            entities: this.getEntitiesFileNameTpl(entities),
        });

        if (this.isBenchmark) {
            const entityInfo = EntityHelpers.getEntityInfo(this.rootEntity.entityType);
            fileName = `benchmark_${entityInfo.text.few}_${fileName}`;
        }

        return fileName;
    }

    getEntitiesFileNameTpl(entities: EntityItem[]): string {
        const { rootEntity, isBenchmark, benchmarkInfo } = this;

        if (isBenchmark) {
            entities = entities.filter(
                ({ id, entityType }) => id !== benchmarkInfo.entityId && entityType !== benchmarkInfo.entityType,
            );
        }

        return ReportHelpers.serializeExportEntitiesFileNamePart(rootEntity, entities, benchmarkInfo);
    }

    trackExportAction(item: ExportItem, analyticsData: AnalyticsData): void {
        const { rootEntity, entities, isBenchmark } = this;
        const { country$, period$, channel$, device$, taxonomyTopic$ } = this.filtersService;

        const payload: BusinessActionPayload = {
            'Entity Type': this.analyticsService.getAnalyticsEntityType(rootEntity, isBenchmark),
            'Report Type':
                entities.length === 1
                    ? ReportType.SINGLE
                    : rootEntity?.isComparison()
                    ? ReportType.COMPARISON
                    : ReportType.GROUP,
            Country: country$.getValue()?.title,
            Period: period$.getValue()?.title,
            Channel: channel$.getValue()?.title,
            Device: device$.getValue()?.title,
            Format: item.id,
            ...analyticsData,
        };

        if (this.featureService.isEnabled(Feature.TAXONOMY)) {
            payload.Taxonomy = taxonomyTopic$.getValue()?.name;
        }

        this.analyticsService.trackBusinessAction('Export', payload);
    }

    saveReport(groupName: string, entity: EntityGroup): Observable<IEntityItemGroupId> {
        return this.proceedWithCreateNewGroup(groupName, entity).pipe(
            tap(() => this.globalConnectionService.favoritesUpdated$.next()),
        );
    }

    updateReport(groupName: string, entity: EntityGroup): Observable<IEntityItemGroupId> {
        return this.proceedWithUpdate(groupName, entity).pipe(
            tap(() => this.globalConnectionService.favoritesUpdated$.next()),
        );
    }

    deleteReport(groupId: IEntityItemId): Observable<void> {
        return this.groupsService
            .deleteGroup(groupId)
            .pipe(tap(() => this.globalConnectionService.favoritesUpdated$.next()));
    }

    private trackReportViewChange(params: ViewTokenParams) {
        const { filters } = params;
        const { channel, device, country, period, taxonomyTopic } = filters;
        const entities = params.entities.map(({ title }) => title);

        const payload: BusinessActionPayload = {
            'Entity Type': this.analyticsService.getAnalyticsEntityType(this.rootEntity, this.isBenchmark),
            'Report Type': this.analyticsReportType,
            Entities: entities,
            'Entities Count': entities.length,
            Country: country?.title,
            Period: period?.title,
            Channel: channel?.title,
            Device: device?.title,
        };

        if (this.featureService.isEnabled(Feature.TAXONOMY)) {
            payload.Taxonomy = taxonomyTopic?.name;
        }

        this.analyticsService.trackBusinessAction('[Security] Report View', payload);
    }

    private setBenchmark(entityType: BUSINESS_ENTITY, entityId: IEntityItemId): void {
        this.benchmarkInfo = {
            entityType,
            entityId: entityId && Number(entityId),
        };

        this.isBenchmark = Boolean(this.benchmarkInfo.entityId);
    }

    private isWinmoSneakView(): boolean {
        return (
            this.integrationsService.isIntegration(INTEGRATION.WINMO) &&
            this.integrationsService.getService<WinmoService>().isSneakPeekView()
        );
    }

    private searchGroup(
        entityType: BUSINESS_ENTITY,
        groupName: string,
        isComparison: boolean,
    ): Observable<EntityGroup> {
        const type = isComparison ? IGroupType.COMPARISON : IGroupType.GROUP;

        return this.groupsService
            .getGroupsList(entityType, { search: groupName, strict: true, type })
            .pipe(map((groups) => groups && groups[0]));
    }

    private proceedWithCreateNewGroup(groupName: string, entity: EntityGroup): Observable<IEntityItemGroupId> {
        let entities: EntityItem[];
        let unsavedGroups: IAccessLogUnsavedGroup[] = null;
        const compare = entity.isComparison();
        const virtualGroups = entity.items.filter((item) => item.isGroup() && (<EntityGroup>item).isVirtual());

        if (compare) {
            /* unwrapping singe group, ex. virtual */
            entities = entity.items;
        } else {
            /* saving generic groups from groups & instances */
            entities = EntityHelpers.flattenItems(entity.items);
        }

        if (this.isBenchmark) {
            entities = entities.filter(
                (v) => v.id !== this.benchmarkInfo.entityId && v.entityType !== this.benchmarkInfo.entityType,
            );
        }

        if (virtualGroups.length) {
            unsavedGroups = virtualGroups.map(
                (item): IAccessLogUnsavedGroup => ({
                    name: item.title,
                    instances: (<EntityGroup>item).items.map(({ id }) => <IEntityItemInstanceId>id),
                }),
            );
        }

        const ids: IEntityItemId[] =
            entities
                .filter((item) => !item.isGroup() || (item.isGroup() && !(<EntityGroup>item).isVirtual()))
                .map(({ id }) => id) || [];

        return this.proceedWithCreateOne(
            groupName,
            ids,
            this.benchmarkInfo,
            compare,
            this.filterParams || entity.preferredFilters,
            <EntityGroup>entity,
            unsavedGroups,
        );
    }

    private proceedWithCreateOne(
        groupName: string,
        groupItemsIds: IEntityItemId[],
        benchmarkInfo: BenchmarkInfo = null,
        isCompare = false,
        params: SerializedFiltersParams,
        entityGroup: EntityGroup,
        unsavedGroups: IAccessLogUnsavedGroup[] = null,
    ): Observable<IEntityItemGroupId> {
        const entityType = entityGroup.entityType;
        return this.searchGroup(entityType, groupName, isCompare).pipe(
            switchMap((existingGroup) => {
                if (existingGroup) {
                    return this.groupsService.updateGroup(
                        entityType,
                        existingGroup.id,
                        groupName,
                        groupItemsIds,
                        unsavedGroups,
                        isCompare,
                        benchmarkInfo,
                        params,
                    );
                } else {
                    return this.groupsService.createGroup(
                        entityType,
                        groupName,
                        groupItemsIds,
                        unsavedGroups,
                        this.benchmarkInfo,
                        isCompare,
                        params,
                    );
                }
            }),
            map((groupItem) => groupItem.id),
        );
    }

    private proceedWithUpdate(groupName: string, entity: EntityGroup): Observable<IEntityItemGroupId> {
        let unsavedGroups: IAccessLogUnsavedGroup[] = null;
        const { id: groupId, entityType, items } = entity;
        const [groupItems] = EntityHelpers.getGroupedBenchmarkItems(items, this.benchmarkInfo);
        const groupItemsIds = groupItems.map((v) => v.id);
        const compare = entity.isComparison();
        const virtualGroups = entity.items.filter((item) => item.isGroup() && (<EntityGroup>item).isVirtual());

        if (virtualGroups.length) {
            unsavedGroups = virtualGroups.map(
                (item): IAccessLogUnsavedGroup => ({
                    name: item.title,
                    instances: (<EntityGroup>item).items.map(({ id }) => <IEntityItemInstanceId>id),
                }),
            );
        }

        return this.groupsService
            .updateGroup(
                entityType,
                groupId,
                groupName,
                groupItemsIds,
                unsavedGroups,
                compare,
                this.benchmarkInfo,
                this.filterParams,
            )
            .pipe(map((groupItem) => groupItem.id));
    }
}
