import { CommonHelpers } from '@shared/helpers/common.helpers';
import { BUSINESS_ENTITY, ENTITY_ITEM_TYPE } from '../enums';
import { ICategoryDto } from '@main/interfaces';
import { EntityBase } from './EntityBase';

export class EntityCategory extends EntityBase<ICategoryDto> {
    parent: EntityCategory;
    impressionsCount: number;

    constructor(dto?: ICategoryDto) {
        super({
            type: ENTITY_ITEM_TYPE.SINGLE,
            entityType: BUSINESS_ENTITY.Category,
            dto,
        });

        if (dto) {
            this.id = dto.id;
            this.parent = dto.parent && new EntityCategory(dto.parent);
            this.icon = dto.parent?.favIconUrl || dto.favIconUrl;
            this.impressionsCount = dto.impressions?.total;

            this.setTitle(dto.name);
        }
    }

    copy(): EntityCategory {
        return CommonHelpers.copyProps(this, new EntityCategory());
    }
}
