import { Inject, Injectable } from '@angular/core';
import { isNil, omitBy } from 'lodash';

import { EntityGroup, EntityItem, ReportType } from '@main/models';
import { BUSINESS_ENTITY } from '@main/enums';
import {
    AnalyticsEntityType,
    AnalyticsToolService,
    BusinessActionPayload,
    UXActionPayload,
    UXElementType,
} from '../models';
import { ANALYTICS_TOOL } from '../data';

@Injectable()
export class AnalyticsService {
    private readonly UI_ACTION_PREFIX = 'UX_ACTION';

    constructor(@Inject(ANALYTICS_TOOL) private analyticsTool: AnalyticsToolService) {}

    trackUIAction(payload: UXActionPayload): void {
        const actionName = this.generateUXActionName(payload.elementType);

        this.track(actionName, payload);
    }

    trackBusinessAction(actionName: string, payload: BusinessActionPayload): void {
        this.track(actionName, payload);
    }

    getAnalyticsReportType(rootEntity: EntityGroup, entities: EntityItem[]): ReportType {
        if (!entities || entities.length === 0) return null;

        if (entities.length === 1) return ReportType.SINGLE;

        return rootEntity?.isComparison() ? ReportType.COMPARISON : ReportType.GROUP;
    }

    getAnalyticsEntityType(rootEntity: EntityGroup, isBenchmark: boolean): AnalyticsEntityType {
        if (isBenchmark) {
            switch (rootEntity?.entityType) {
                case BUSINESS_ENTITY.Advertiser:
                    return 'ADVERTISER_BENCHMARK';
                case BUSINESS_ENTITY.Brand:
                    return 'BRAND_BENCHMARK';
                default:
                    throw new Error('Unknown benchmark entity type');
            }
        }

        return rootEntity?.entityType;
    }

    private track(action: string, payload: object): void {
        if (this.analyticsTool.disabled) return;

        const actualPayload = omitBy(payload, isNil);

        this.analyticsTool.track(action, actualPayload);
    }

    private generateUXActionName(elementType: UXElementType): string {
        const actionSuffix = elementType.toUpperCase();

        return `${this.UI_ACTION_PREFIX}_${actionSuffix}`;
    }
}
