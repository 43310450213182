import { inject, Injectable } from '@angular/core';
import { from, map, mergeMap, Observable, of, ReplaySubject, throwError } from 'rxjs';
import { isNil } from 'lodash';

import { environment } from 'environments/environment';
import { AppConfig } from '@app/app.config';
import { IntegrationServiceBase } from '@app/integrations/services/integration.service.base';
import { AuthHelpers } from '@shared/services';
import { WINDOW } from '@shared/services/window.providers';
import { CommonHelpers } from '@shared/helpers/common.helpers';
import { ReportConfig } from '@shared/interfaces/ReportConfig';
import { NavigationService } from '@main/services/navigation.service';
import { BUSINESS_ENTITY } from '@main/enums';
import { EntityItem } from '@main/models';
import { EntityHelpers } from '@main/helpers/entity.helpers';
import { WinmoAuthTokenData, WinmoParams, WinmoViewMode, WMSDK } from './winmo.service.types';
import { WinmoApiService } from './winmo.api-service';
import { ViewportService } from './viewport.service';

@Injectable({ providedIn: 'root' })
export class WinmoService implements IntegrationServiceBase {
    private readonly window = inject(WINDOW);
    private readonly navigationService = inject(NavigationService);
    private readonly viewportService = inject(ViewportService);
    private readonly apiService = inject(WinmoApiService);
    private appConfig = inject(AppConfig);

    private readonly SDK$ = new ReplaySubject<WMSDK>(1);

    private sdkInjected = false;

    private tokenData: WinmoAuthTokenData;

    getToken(): Observable<string> {
        if (!this.sdkInjected) {
            return throwError(() => new Error('SDK not loaded'));
        }

        return this.SDK$.pipe(mergeMap((SDK) => from(SDK.client('getAccessToken'))));
    }

    setToken(token: string): void {
        this.tokenData = AuthHelpers.decodeToken<WinmoAuthTokenData>(token);

        this.appConfig.appInfo.controlsToolbar.defaultFilters.country = this.getCountry();
        this.appConfig.settings.shareLinkUrl = this.tokenData.baseUrl + '?path=';
    }

    isSneakPeekView(): boolean {
        return this.tokenData?.viewMode === WinmoViewMode.SEARCH;
    }

    isSubscribed(): boolean {
        return Boolean(this.tokenData?.subscribed);
    }

    getAllowedCountries(reportConfig: ReportConfig): string[] {
        return reportConfig.entityType === BUSINESS_ENTITY.Brand ? [this.getCountry()] : null;
    }

    getCountry(): string {
        return this.tokenData?.country;
    }

    getIntegrationRedirectPage(): Observable<string> {
        const { names, entityType } = this.getParams();
        const country = this.getCountry();

        if (!this.isSneakPeekView()) {
            const { path } = this.tokenData;
            const hasQueryParams = path?.includes('&'); // our '?' was serialized to '&' to join with winmo params
            const redirectPath = (hasQueryParams && path.replace('&', '?')) || path || '';

            return this.isSubscribed() ? of(redirectPath) : of('winmo-unsubscribed');
        }

        if (!names?.length) {
            return of('winmo-not-found');
        }

        return this.resolveEntitiesByNames(names, entityType, country).pipe(
            map((entities) => {
                if (!entities.length) {
                    return this.isSubscribed() ? 'winmo-not-found' : 'winmo-unsubscribed';
                }

                const entityInfo = EntityHelpers.getEntityInfo(entities[0].entityType);
                const ids = this.navigationService.serializeEntitiesRoutingIds(entities);

                return ['winmo-preview', entityInfo.url.common, ids].join('/');
            }),
        );
    }

    openApp(path: string): void {
        console.info('Sending SDK event: openApp', { path });

        this.SDK$.subscribe((SDK) => {
            SDK.client('openApp', path);
        });
    }

    loadSdk(): void {
        if (this.sdkInjected || window.self === window.top) {
            return;
        }

        this.sdkInjected = true;

        this.SDK$.subscribe((SDK) => {
            if (!this.isSneakPeekView()) {
                //              this.initHistoryChangeHandlers(SDK);
            }

            this.viewportService.setSDK(SDK);
            this.viewportService.init();
        });

        (this.window as any).onWinmoSdkLoad = () => {
            const SDK = (this.window as any).WMSDK as WMSDK;

            if (isNil(SDK)) {
                return;
            }

            SDK.init().then(() => {
                this.SDK$.next(SDK);
            });
        };

        CommonHelpers.injectScript(environment.winmoSdkPath);
    }

    private initHistoryChangeHandlers(SDK: WMSDK): void {
        const currentUrl = new URL(window.location.href);

        SDK.client('replaceUrl', { url: currentUrl.pathname + currentUrl.search });

        CommonHelpers.decorateHistoryChangeHandlers((method, url) => SDK.client(method, { url }));
    }

    private resolveEntitiesByNames(
        names: string[],
        entityType: BUSINESS_ENTITY,
        country: string,
    ): Observable<EntityItem[]> {
        return this.apiService.searchEntities(names, country).pipe(
            map((response) => {
                const entities = response.filter((entity) => entity.entityType === entityType);

                if (entities.length) {
                    return entities;
                }

                return response.filter((entity) => entity.entityType === BUSINESS_ENTITY.Advertiser);
            }),
        );
    }

    private getParams(): WinmoParams {
        const entities = this.tokenData?.entities;
        const entityType = BUSINESS_ENTITY.Brand;

        const names = !isNil(entities) && entities.split(',').filter(Boolean);

        return { names, entityType };
    }
}
