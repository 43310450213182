import { Inject, Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map, shareReplay } from 'rxjs/operators';

import { FiltersValue } from '@shared/services/filters/filters.types';
import { ReportConfig } from '@shared/interfaces/ReportConfig';
import { REPORT_CONFIG } from '@shared/const/report-config';
import { DatasetRequestParams, RequestService } from '@shared/services';
import { ICurrency } from '@shared/interfaces';
import { EntityItem } from '@main/models';
import { AdTypeItem, GetAdTypesResponse } from '@main/services/ad-types.service.types';
import { EntityHelpers } from '@main/helpers/entity.helpers';
import { ReportService } from '@main/core/report.service';
import { BUSINESS_ENTITY, WIDGET } from '@main/enums';

@Injectable()
export abstract class WidgetsApiService {
    constructor(
        protected reportService: ReportService,
        protected requestService: RequestService,
        @Inject(REPORT_CONFIG) protected reportConfig: ReportConfig,
    ) {}

    getAdTypes(
        entityType: BUSINESS_ENTITY,
        entities: EntityItem[],
        filters: FiltersValue,
        currency: ICurrency,
        isBenchmark: boolean,
        labelArea: WIDGET,
    ): Observable<AdTypeItem[]> {
        const { comparison } = this.reportConfig;

        const entitiesIdsPairs = EntityHelpers.serializeEntitiesIdsPairs(entities, {
            globalParentheses: !comparison,
            extractGroupsIds: !comparison,
            plain: !comparison,
        });

        const datasetParams: DatasetRequestParams = {
            datasetName: 'creativetypes',
            entitiesIdsPairs,
            useViewToken: true,
            customParams: EntityHelpers.serializeFiltersParams(filters, currency),
            labelEntityType: entityType,
            labelArea,
        };

        if (isBenchmark && entities.length > 1) {
            datasetParams.datasetName = this.getBenchmarkAdTypesDatasetName(entityType);
        }

        return this.requestService.dataset<GetAdTypesResponse>(datasetParams).pipe(
            map((result) => {
                let elements: AdTypeItem[] = [];

                if (result?.elements) {
                    elements = result.elements
                        .reduce((acc, element) => acc.concat(element.creativeTypes?.elements || []), [])
                        .filter(
                            (element, index, arr) =>
                                arr.findIndex((v) => v.entity.code === element.entity.code) === index,
                        );
                } else {
                    elements = result?.creativeTypes?.elements || [];
                }

                return elements;
            }),
            shareReplay(1),
        );
    }

    private getBenchmarkAdTypesDatasetName(entityType: BUSINESS_ENTITY): string {
        switch (entityType) {
            case BUSINESS_ENTITY.Advertiser:
                return 'benchmark-advertisers-creativetypes';
            case BUSINESS_ENTITY.Brand:
                return 'benchmark-brands-creativetypes';
            default:
                throw new Error(`No dataset for benchmark for entity type ${entityType}`);
        }
    }
}
