<ng-container *ngIf="hasNotifications">
    <ng-container *ngFor="let item of items">
        <div
            fxLayout="row"
            fxLayoutAlign="start center"
            data-userflow="top-notification-bar"
            [ngClass]="['item', 'theme-' + item.options.theme, item.state.hidden ? 'hidden' : '']"
        >
            <div fxFlex fxLayout="row" fxLayoutAlign="center center" class="block-message">
                <div *ngIf="item.options.spinner" class="loader-v2 mr-16"></div>

                <p [innerHTML]="item.message | safeHtml" class="message"></p>
            </div>

            <mat-icon
                *ngIf="item.options.closable"
                class="s-12 btn-close"
                svgIcon="close"
                (click)="onClose(item)"
            ></mat-icon>
        </div>
    </ng-container>
</ng-container>
