import { IGroupDto, IGroupType } from '@main/interfaces';
import { EntityGroup, EntityItem, EntitySingle } from '@main/models';
import { BUSINESS_ENTITY, GROUP_TYPE } from '@main/enums';
import { IEntityItemGroupId, IEntityItemId, IEntityItemInstanceId } from '@main/types';
import { EntityHelpers } from '@main/helpers/entity.helpers';
import { IAccessLogUnsavedGroup } from '@main/services/recents.service.types';
import { SerializedFiltersParams } from '@main/helpers/entity.helpers.types';
import { map } from 'rxjs/operators';
import { Observable, of } from 'rxjs';
import { ResponseGetGroup } from '@main/services/groups.service.types';
import { BenchmarkInfo } from '../core/report.types';

export class GroupsHelper {
    static deserializeGroup(data: IGroupDto): EntityGroup {
        const {
            details: { type, entityType: groupEntityType, categoryId, resolvedUnsavedGroups = [] },
            relations = [],
        } = data;

        const entityType = EntityHelpers.mapEntityTypeForBenchmark(groupEntityType);

        const subGroups = relations ? relations.map((v) => v.id && this.deserializeGroup(v)) : [];
        const icon = type === IGroupType.GROUP ? ':groupGeneric' : ':groupComparison';

        const groupEntity = new EntityGroup({
            groupType: type === IGroupType.GROUP ? GROUP_TYPE.REGULAR : GROUP_TYPE.COMPARISON,
            entityType,
            id: data.id,
            title: data.name,
            icon,
            items: [...subGroups, ...resolvedUnsavedGroups],
            dto: data,
            resolved: false,
            virtual: false,
        });

        const isCategoryBenchmark = !!categoryId;

        if (isCategoryBenchmark) {
            groupEntity.setBenchmark(BUSINESS_ENTITY.Category);
        }

        groupEntity.setPreferredFilters(data?.details?.params);

        return groupEntity;
    }

    static serializeGroupUpdateRequest(
        entityType: BUSINESS_ENTITY,
        id: IEntityItemGroupId,
        name: string,
        ids: IEntityItemId[],
        unsavedGroups: IAccessLogUnsavedGroup[],
        benchmarkInfo: BenchmarkInfo,
        comparison: boolean,
        params: SerializedFiltersParams,
    ): IGroupDto {
        let singleInstancesIds = [];
        let groupsIds = [];

        switch (entityType) {
            case BUSINESS_ENTITY.Keyword:
                singleInstancesIds = ids;
                break;

            default:
                singleInstancesIds = ids.filter((v) => EntityHelpers.isSingleInstanceId(v)) as IEntityItemInstanceId[];

                groupsIds = ids.filter((v) => EntityHelpers.isGroupId(v)) as IEntityItemGroupId[];
        }

        const result: IGroupDto = {
            id,
            name,
            details: {
                entityType,
                type: comparison ? IGroupType.COMPARISON : IGroupType.GROUP,
                instances: singleInstancesIds,
                favoriteIds: groupsIds,
                params,
            },
        };

        if (benchmarkInfo) {
            if (benchmarkInfo.entityType === BUSINESS_ENTITY.Category) {
                result.details.categoryId = benchmarkInfo.entityId;
            }

            result.details.entityType = EntityHelpers.mapBenchmarkForEntityType(entityType);
        }

        if (unsavedGroups) {
            result.details.unsavedGroups = unsavedGroups;
        }

        return result;
    }

    static resolveUnsavedGroups(
        response: ResponseGetGroup,
        entityType: BUSINESS_ENTITY,
        resolverFn: (a: BUSINESS_ENTITY, b: IEntityItemId[]) => Observable<EntitySingle[]>,
    ): Observable<ResponseGetGroup> {
        const unsavedIds: IEntityItemInstanceId[] = [];

        response.elements?.forEach((item) =>
            item.details.unsavedGroups?.forEach(({ instances }) => unsavedIds.push(...instances)),
        );
        const uniqUnsavedIds = new Set(unsavedIds);
        return entityType && uniqUnsavedIds.size
            ? resolverFn(entityType, [...uniqUnsavedIds.values()]).pipe(
                  map((resolvedEntities) => {
                      const entitiesById = new Map<IEntityItemInstanceId, EntityItem>(
                          resolvedEntities.map((item) => [<IEntityItemInstanceId>item.id, <EntityItem>item]),
                      );

                      response.elements.forEach((item) => {
                          item.details.resolvedUnsavedGroups = [];
                          item.details.unsavedGroups?.forEach(({ name, instances }) => {
                              const resolvedGroupEntities = [];

                              instances.forEach(
                                  (id) => entitiesById.has(id) && resolvedGroupEntities.push(entitiesById.get(id)),
                              );

                              const resolvedGroup = EntityHelpers.createVirtualGroup(
                                  EntityHelpers.getVirtualGroupId(name),
                                  name,
                                  resolvedGroupEntities,
                                  GROUP_TYPE.REGULAR,
                                  entityType,
                                  <IAccessLogUnsavedGroup>{ name, instances },
                              );

                              item.details.resolvedUnsavedGroups.push(resolvedGroup);
                          });
                      });

                      return response;
                  }),
              )
            : of(response);
    }

    static resolveSingleUnsavedGroup(
        group: IGroupDto,
        resolverFn: (a: BUSINESS_ENTITY, b: IEntityItemId[]) => Observable<EntitySingle[]>,
    ): Observable<IGroupDto> {
        const unsavedIds: IEntityItemInstanceId[] = [];
        const entityType = EntityHelpers.mapEntityTypeForBenchmark(group.details.entityType);

        group.details.unsavedGroups?.forEach(({ instances }) => unsavedIds.push(...instances));

        const uniqUnsavedIds = new Set(unsavedIds);
        return entityType && uniqUnsavedIds.size
            ? resolverFn(entityType, [...uniqUnsavedIds.values()]).pipe(
                  map((resolvedEntities) => {
                      const entitiesById = new Map<IEntityItemInstanceId, EntityItem>(
                          resolvedEntities.map((item) => [<IEntityItemInstanceId>item.id, <EntityItem>item]),
                      );

                      group.details.resolvedUnsavedGroups = [];
                      group.details.unsavedGroups?.forEach(({ name, instances }) => {
                          const resolvedGroupEntities = [];

                          instances.forEach(
                              (entityId) =>
                                  entitiesById.has(entityId) && resolvedGroupEntities.push(entitiesById.get(entityId)),
                          );

                          const resolvedGroup = EntityHelpers.createVirtualGroup(
                              EntityHelpers.getVirtualGroupId(name),
                              name,
                              resolvedGroupEntities,
                              GROUP_TYPE.REGULAR,
                              entityType,
                              <IAccessLogUnsavedGroup>{ name, instances },
                          );

                          group.details.resolvedUnsavedGroups.push(resolvedGroup);
                      });

                      return group;
                  }),
              )
            : of(group);
    }

    static hasUnsavedGroups(group: IGroupDto): boolean {
        return !!group?.details?.unsavedGroups?.length;
    }
}
