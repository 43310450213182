import { chartColors } from '@shared/colors/data/palette/charts-colors.data';

export const options = {
    generic: {
        chart: {
            type: 'pie',
            backgroundColor: 'rgba(255, 255, 255, 0.0)',
        },
        credits: { enabled: false },
        legend: {
            itemStyle: {
                fontSize: '6px',
                fontWeight: 'normal',
            },
            /* 'hiding' origin symbols */
            symbolHeight: 1,
            symbolWidth: 1,
            useHTML: true,
        },
        plotOptions: {
            pie: {
                colors: chartColors,
                borderWidth: 0,
                dataLabels: {
                    enabled: false,
                },
                showInLegend: true,
                allowPointSelect: false,
                point: {
                    events: {
                        legendItemClick: (e) => {
                            e.preventDefault();
                        },
                    },
                },
                states: {
                    hover: {
                        brightness: 0,
                    },
                },
            },
            series: {
                states: {
                    hover: {
                        halo: {
                            opacity: 1,
                        },
                    },
                },
            },
        },
        series: [
            // outer shadow serie
            {
                opacity: 0.3,
                enableMouseTracking: false,
                showInLegend: false,
            },
            // data serie
            {
                name: 'Value',
                states: {
                    inactive: {
                        opacity: 0,
                    },
                },
            },
        ],
        title: {
            text: '',
            align: 'left',
            floating: true,
            useHTML: true,
        },
        tooltip: {
            enabled: false,
        },
    },

    small: {
        chart: {
            height: 120,
            spacingRight: 0,
        },
        legend: {
            width: '60%',
            align: 'right',
            itemMarginTop: 8,
            padding: 0,
            layout: 'vertical',
            verticalAlign: 'middle',
            labelFormatter(): string {
                const value = this.percentage.toFixed(2);

                return `
                    <div class="piechart-legend-item small">
                        <div class="colored-circle-box s-10">
                            <span style="background-color: ${this.color}"></span>
                        </div>
                        <span class="item-title">
                            ${this.name}
                        </span>
                        <span class="item-value">
                            ${value}%
                        </span>
                    </div>
                `;
            },
            y: -5,
        },
        plotOptions: {
            pie: {
                center: [29, 29],
            },
        },
        series: [
            // outer shadow serie
            {
                size: 115,
                innerSize: 75,
            },
            // data serie
            {
                size: 95,
                innerSize: 75,
            },
        ],
        title: {
            x: 16,
            y: 56,
        },
    },

    big: {
        chart: {
            width: 390,
            height: 240,
            margin: 0,
        },
        legend: false,
        plotOptions: {
            pie: {
                center: ['50%', '47%'],
            },
        },
        series: [
            // outer shadow serie
            {
                size: 203,
                innerSize: 143,
            },
            // data serie
            {
                size: 177,
                innerSize: 143,
                states: {
                    hover: {
                        halo: {
                            size: 13,
                        },
                    },
                },
            },
        ],
        title: {
            x: 119,
            y: 95,
        },
    },
    middle: {
        chart: {
            width: 240,
            height: 240,
            margin: 0,
        },
        legend: false,
        plotOptions: {
            pie: {
                center: ['50%', '47%'],
            },
        },
        series: [
            // outer shadow serie
            {
                size: 203,
                innerSize: 143,
            },
            // data serie
            {
                size: 177,
                innerSize: 143,
                states: {
                    hover: {
                        halo: {
                            size: 13,
                        },
                    },
                },
            },
        ],
        title: {
            x: 48,
            y: 95,
        },
    },
};
