/* eslint-disable max-lines */
import {
    ChangeDetectorRef,
    Component,
    ElementRef,
    EventEmitter,
    Inject,
    Input,
    OnChanges,
    OnInit,
    Output,
    SimpleChanges,
} from '@angular/core';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { BehaviorSubject, combineLatest, Observable, Subject } from 'rxjs';
import { first, map, share } from 'rxjs/operators';
import { isNil } from 'lodash';
import Swal from 'sweetalert2';

import { AppConfig } from '@app/app.config';
import { BusinessActionPayload } from '@app/modules/analytics';
import { FeatureToggleService } from '@main/shared/feature-toggle';
import { FEATURE_NAME_ENUM, RESTRICTION_ACTION } from '@shared/enums';
import { ExportImageService } from '@shared/services/export-image.service';
import { AccountService, AnalyticsService, AuthService, DOWNLOAD_TYPE } from '@shared/services';
import { LayoutHelpers } from '@shared/helpers/layout.helpers';
import { FiltersService } from '@shared/services/filters/filters.service';
import { MainExportService } from '@main/services/export.service';
import { EXPORT_FORMAT, ExportStatus } from '@main/services/export.service.types';
import { FullscreenViewService } from '@main/shared/widget-full-screen-view';
import { ReportService } from '@main/core/report.service';
import { ExportHelpers } from './common-export-button.helpers';
import {
    AnalyticsData,
    ExportItem,
    ExportOption,
    ROOT_BUTTON_TYPE,
    UpdateStateParams,
} from './common-export-button.types';
import { defaultExportOptions } from './common-export-button.options';
import { ReportConfig } from '@shared/interfaces/ReportConfig';
import { REPORT_CONFIG } from '@shared/const/report-config';
import { PAGE_TYPE } from '@main/enums';
import { Feature } from '@app/app-main/shared/feature-toggle/feature-toggle.model';

@UntilDestroy()
@Component({
    selector: 'common-export-button',
    templateUrl: './common-export-button.component.html',
    styleUrls: ['./common-export-button.component.scss'],
})
export class CommonExportButtonComponent implements OnInit, OnChanges {
    @Input() options: ExportOption[];
    @Input() exportTypes: EXPORT_FORMAT[] = [];
    @Input() fileNameTpl = '';
    @Input() analyticsData: AnalyticsData = {} as AnalyticsData;
    @Input() restrictionActionConfigPath = 'widget.export';

    @Input() buttonType = ROOT_BUTTON_TYPE.TEXT;
    @Input() buttonColor = 'primary';
    @Input() buttonClass: string[];

    @Input() tooltip = 'Export';

    @Input() disabled = false;
    @Input() shown = false;

    @Input() handler: (item: ExportOption) => Observable<string | null>;
    @Input() updateState$ = new Subject<UpdateStateParams>();

    @Output() shownChange = new EventEmitter<boolean>();

    unlimitExportItems$: Observable<ExportItem[]>;
    limitedExportItems$: Observable<ExportItem[]>;
    hasUnlimitedExportItems$: Observable<boolean>;
    hasLimitedExportItems$: Observable<boolean>;
    statusChanged$ = new BehaviorSubject<void>(null);

    exportRestrictionAction: RESTRICTION_ACTION;

    permissions$: Observable<FEATURE_NAME_ENUM[]>;

    get isHiddenByRestriction(): boolean {
        return this.exportRestrictionAction === RESTRICTION_ACTION.REMOVE;
    }

    get isDisabledByRestriction(): boolean {
        return this.exportRestrictionAction === RESTRICTION_ACTION.DISABLE;
    }

    get isButtonTypeText(): boolean {
        return this.buttonType === ROOT_BUTTON_TYPE.TEXT;
    }

    get isButtonTypeIcon(): boolean {
        return this.buttonType === ROOT_BUTTON_TYPE.ICON;
    }

    get exportTooltipText(): string {
        return this.authService.isTrialCustomer
            ? 'The export function cannot be accessed during the trial period'
            : 'Please contact your account manager to enable data export';
    }

    get showDisabledExportButtonTooltip(): boolean {
        return this.config.appInfo.showDisabledExportButtonTooltip;
    }

    get showLimitationLabel(): boolean {
        return this.accountService.hasPermission(FEATURE_NAME_ENUM.EXPORT_SHOW_LIMITS);
    }

    private items$ = new BehaviorSubject<ExportItem[]>([]);

    constructor(
        private elementRef: ElementRef,
        private authService: AuthService,
        private accountService: AccountService,
        private reportService: ReportService,
        private exportService: MainExportService,
        private exportImageService: ExportImageService,
        private featureToggleService: FeatureToggleService,
        private filtersService: FiltersService,
        private config: AppConfig,
        private analyticsService: AnalyticsService,
        private fullscreenViewService: FullscreenViewService,
        private cdr: ChangeDetectorRef,
        @Inject(REPORT_CONFIG) private reportConfig: ReportConfig,
    ) {
        this.setRestrictionAction();
    }

    ngOnInit(): void {
        const updateLimitsTrigger$ = combineLatest([this.items$, this.statusChanged$]).pipe(map(([items]) => items));

        this.unlimitExportItems$ = updateLimitsTrigger$.pipe(map((items) => items.filter((v) => !v.limited)));

        this.limitedExportItems$ = updateLimitsTrigger$.pipe(map((items) => items.filter((v) => v.limited)));

        this.hasUnlimitedExportItems$ = this.unlimitExportItems$.pipe(map((items) => items.length > 0));
        this.hasLimitedExportItems$ = this.limitedExportItems$.pipe(map((items) => items.length > 0));

        this.permissions$ = this.items$.pipe(map((items) => items.map((v) => v.option.permission)));

        this.updateState$.subscribe((params) => {
            const exportItem = this.items$.getValue().find((item) => item.id === params.id);

            if (exportItem) {
                this.updateItemState(exportItem, params);
            }

            this.cdr.markForCheck();
        });

        this.permissions$.pipe(untilDestroyed(this)).subscribe((permissions) => {
            this.updateShownState(permissions);
        });

        combineLatest([this.items$, this.filtersService.period$])
            .pipe(untilDestroyed(this))
            .subscribe(([items]) => {
                this.updateExportsStatus(items);
            });
    }

    onShownChange(value: boolean): void {
        setTimeout(() => {
            this.shown = value;
            this.shownChange.emit(value);
        });
    }

    ngOnChanges({ options, exportTypes }: SimpleChanges): void {
        if (options || exportTypes) {
            this.buildItemsList();
        }
    }

    onRootButtonClick(event): void {
        LayoutHelpers.fixTopScrollForMatMenuButton(event);
    }

    onExportClick(item: ExportItem, event: Event): void {
        event.stopPropagation();

        if (item.limitExceeded) {
            return;
        }

        if (item._disabled || item.busy) {
            return;
        }

        if (item._disabled || item.busy) {
            return;
        }

        this.reportService.trackExportAction(item, this.analyticsData);

        // TODO: add to reportService with less coupled way
        if (this.reportConfig.pageType === PAGE_TYPE.DASHBOARDS && this.analyticsData['Export Type'] === 'Widget') {
            const payload: BusinessActionPayload = {
                Channel: this.filtersService.channel$.getValue()?.title,
                Country: this.filtersService.country$.getValue()?.title,
                Device: this.filtersService.device$.getValue()?.title,
                Period: this.filtersService.period$.getValue()?.title,
                Format: item.option.format,
                ...this.analyticsData,
            };

            if (this.featureToggleService.isEnabled(Feature.TAXONOMY)) {
                payload.Taxonomy = this.filtersService.taxonomyTopic$.getValue()?.name;
            }

            this.analyticsService.trackBusinessAction('[Dashboard] Widget Export', payload);
        }

        if (this.isRemoteItem(item)) {
            this.exportRemoteItem(item).subscribe();
        } else {
            this.exportLocalItem(item).subscribe();
        }
    }

    private updateExportsStatus(items: ExportItem[]): void {
        const remoteItems = items.filter(this.isRemoteItem);

        remoteItems.forEach((item) => {
            this.updateItemState(item, { limitsStatusReady: false });

            this.getRemoteItemExportStatus(item)
                .pipe(untilDestroyed(this))
                .subscribe((status) => {
                    this.updateRemoteExportItemStatus(item, status);
                });
        });
    }

    private buildItemsList(): void {
        const options = (this.options || defaultExportOptions).filter((option) => this.exportTypes.includes(option.id));

        const items = options.map((option) => {
            const showHeader =
                !option.groupId || options.filter((v) => v.groupId === option.groupId).indexOf(option) === 0;
            const hasPermission = !option.permission || this.accountService.hasPermission(option.permission);

            const item: ExportItem = {
                id: option.id,
                option,
                limited: false,
                limitExceeded: false,
                showHeader,
                limitsLabel: null,
                limitsTooltip: null,
                noticeText: null,
                hasPermission,
                notAvailable: false,
                inProgress: false,
                ready: false,
                limitsStatusReady: true,
                disabled: false,
                busy: false,
                tooltip: option.tooltip,
                _tooltip: null,
                _disabled: false,
            };

            this.updateItemState(item, {
                ready: isNil(option.ready) ? true : option.ready,
                inProgress: option.inProgress,
                disabled: option.disabled,
            });

            return item;
        });

        this.items$.next(items);
    }

    private exportLocalItem(item: ExportItem): Observable<void> {
        this.setProgressState(item, true);

        let export$: Observable<void>;

        switch (item.option.format) {
            case EXPORT_FORMAT.WIDGET_IMAGE:
                export$ = this.exportImage(item).pipe(share());
                break;

            default:
                export$ = this.handler(item.option).pipe(
                    share(),
                    map(() => null),
                );
        }

        export$.pipe(untilDestroyed(this)).subscribe(() => {
            this.setProgressState(item, false);
        });

        return export$;
    }

    private exportRemoteItem(item: ExportItem): Observable<string> {
        this.onBeforeRemoteExport(item);

        const handler$ = this.handler(item.option).pipe(first(), share());

        handler$.subscribe(
            () => this.onAfterRemoteExport(item),
            (error) => {
                this.onAfterRemoteExport(item);
                this.handleRemoteDownloadError(item, error);
            },
        );

        return handler$;
    }

    private onBeforeRemoteExport(item: ExportItem): void {
        this.setProgressState(item, true);
    }

    private onAfterRemoteExport(item: ExportItem): void {
        this.updateExportsStatus([item]);
    }

    private exportImage(item: ExportItem): Observable<void> {
        const customCt = item.option.custom?.exportCt as HTMLElement;
        const lookupWidget = !customCt;
        let exportCt: HTMLElement = customCt || this.elementRef.nativeElement;

        if (this.fullscreenViewService.isFullscreenView) {
            exportCt = document.getElementById('viewport-container');
        }

        return this.exportImageService.export(exportCt, this.fileNameTpl, lookupWidget);
    }

    private handleRemoteDownloadError(_item: ExportItem, err: any): void {
        if (err.status === 400) {
            Swal.fire('Error', ExportHelpers.formatRemoteDownloadError(err), 'error');
        } else {
            Swal.fire('Export failed', err.statusText, 'error');
        }
    }

    private getRemoteItemExportStatus(item: ExportItem): Observable<ExportStatus> {
        const featureName = item.option.featureName;
        const downloadType = item.option.downloadType;

        return this.exportService.getExportStatus(downloadType, featureName);
    }

    private setProgressState(item: ExportItem, active: boolean) {
        this.getGroupedItems(item).forEach((v) => {
            this.updateItemState(v, { inProgress: active });
        });
    }

    private updateRemoteExportItemStatus(item: ExportItem, status: ExportStatus): void {
        this.getGroupedItems(item).forEach((v) => {
            const period = this.filtersService.period$.value;

            ExportHelpers.applyExportItemStatusLimits(item, status, period);

            this.updateItemState(v, { limitsStatusReady: true, inProgress: status.inProgress });
        });

        this.statusChanged$.next();
    }

    private getGroupedItems(item: ExportItem): ExportItem[] {
        return item.option.groupId
            ? this.items$.getValue().filter((v) => v.option.groupId === item.option.groupId)
            : [item];
    }

    private setRestrictionAction(): void {
        const configRestrictionActions = this.config.appInfo.override.controlsRestrictionActions;

        this.exportRestrictionAction =
            (configRestrictionActions &&
                configRestrictionActions[this.restrictionActionConfigPath]?.noPermissionAction) ||
            RESTRICTION_ACTION.DISABLE;
    }

    private updateShownState(permissions: FEATURE_NAME_ENUM[]): void {
        const shown =
            this.accountService.hasPermission(permissions) ||
            this.exportRestrictionAction !== RESTRICTION_ACTION.REMOVE;

        setTimeout(() => {
            this.shownChange.emit(shown);
        });
    }

    private updateItemState(item: ExportItem, stateParams: Partial<ExportItem> = {}): void {
        if (!isNil(stateParams.disabled)) {
            item.disabled = stateParams.disabled;
        }

        if (!isNil(stateParams.ready)) {
            item.ready = stateParams.ready;
        }

        if (!isNil(stateParams.limitsStatusReady)) {
            item.limitsStatusReady = stateParams.limitsStatusReady;
        }

        if (!isNil(stateParams.inProgress)) {
            item.inProgress = stateParams.inProgress;
        }

        if (!isNil(stateParams.notAvailable)) {
            item.notAvailable = stateParams.notAvailable;
        }

        item._disabled = item.disabled || !item.hasPermission || item.limitExceeded || item.notAvailable;
        item._tooltip = ExportHelpers.getTooltip(item);
        item.busy = !item._disabled && (item.inProgress || !item.ready || !item.limitsStatusReady);
    }

    private isRemoteItem(item: ExportItem) {
        return Boolean(item.option.downloadType);
    }

    // private getExceededLimitClickState(item: ExportItem): boolean {
    //     const downloadType = this.getExportItemDownloadType(item);
    //     const states = this.persistedSettingsService.get<string[]>(SETTINGS.EXCEEDED_EXPORT_CLICKS) || [];
    //
    //     return states.includes(downloadType);
    // }
    //
    // private setExceededLimitClickState(item: ExportItem): void {
    //     const downloadType = this.getExportItemDownloadType(item);
    //     const states = this.persistedSettingsService.get<string[]>(SETTINGS.EXCEEDED_EXPORT_CLICKS) || [];
    //
    //     states.push(downloadType);
    //     this.persistedSettingsService.set(SETTINGS.EXCEEDED_EXPORT_CLICKS, states);
    // }

    private getExportItemDownloadType(item: ExportItem): DOWNLOAD_TYPE {
        return Array.isArray(item.option.downloadType) ? item.option.downloadType[0] : item.option.downloadType;
    }
}
