import { PACKAGE } from '@app/integrations/semrush/semrush.types';

export const environment = {
    name: 'qa',
    production: true,
    hmr: false,

    mixpanelAnalyticsId: 'f9f759cc231a483ba4fcc2796a5574bd',
    mixpanelDebug: false,

    semrushSdkPath: 'https://static.semrush.com/app-center/sdk.js',
    semrushPackagesIds: {
        [PACKAGE.ALL]: 'f800bb98-f139-4d0e-a8e6-1680e85d1e84',
        [PACKAGE.DISPLAY]: '3fa70c96-c2fe-4233-992f-72c80dd37008',
        [PACKAGE.SOCIAL_VIDEO]: 'a2d7799c-e1a4-40ce-9c94-82fbbae748cf',
    },
    winmoSdkPath: 'https://neo.tldev2.com/sdk/winmo-sdk.js',
    userflowToken: 'ct_slwtj6jkavfrjdstxyq6dr3nzy',
};
