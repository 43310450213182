import { ChangeDetectionStrategy, ChangeDetectorRef, Component, inject, Input, QueryList } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { PerfectScrollbarDirective } from '@app/shared/directives/perfect-scrollbar.directive';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { CommonHelpers } from '@app/shared/helpers/common.helpers';
import { startWith } from 'rxjs';

/**
 * This component display a scroll-up button when the scroll container is scrolled.
 */
@UntilDestroy()
@Component({
    selector: 'scroll-up-button',
    standalone: true,
    imports: [CommonModule, MatIconModule, MatButtonModule],
    templateUrl: './scroll-up-button.component.html',
    styleUrls: ['./scroll-up-button.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ScrollUpButtonComponent {
    @Input({ required: true })
    set scrollBars(value: QueryList<PerfectScrollbarDirective>) {
        if (!value) return;

        this._scrollBars = value;
        this.handleScrollbarsChanges();
    }

    showScrollUpButton = false;

    private _scrollBars: QueryList<PerfectScrollbarDirective>;
    private readonly cdr = inject(ChangeDetectorRef);

    scrollToTop(): void {
        CommonHelpers.resetScrollBars(this._scrollBars, true);
    }

    private handleScrollbarsChanges(): void {
        this._scrollBars.changes.pipe(startWith(null), untilDestroyed(this)).subscribe(() =>
            this._scrollBars.forEach((scrollBar: PerfectScrollbarDirective) => {
                scrollBar.elementRef.nativeElement.addEventListener('scroll', this.onScroll.bind(this));
            }),
        );
    }

    private onScroll(event: Event): void {
        const scrollPosition = (event.target as HTMLElement).scrollTop;
        const scrollPositionToShowButton = 500;
        this.showScrollUpButton = scrollPosition > scrollPositionToShowButton;
        this.cdr.detectChanges();
    }
}
