import { Injectable } from '@angular/core';
import { BehaviorSubject, filter, finalize, Observable, take } from 'rxjs';
import { first, share } from 'rxjs/operators';

import { RequestService } from '@shared/services/request/request.service';
import { INTERVAL_TYPE } from '@main/enums';
import { LIMIT_AREA, LIMIT_TYPE } from '@main/components/limits-notification/limits-notification.config';
import { LimitsService } from '@main/services/limits.service';
import { ViewTokenParams } from './view-report.service.types';
import { ViewReportHelpers } from './view-report.helpers';
import { EntityHelpers } from '../helpers/entity.helpers';

@Injectable({ providedIn: 'root' })
export class ViewReportService {
    viewToken$ = new BehaviorSubject<string>(null);

    get viewToken(): string {
        return this.viewToken$.value;
    }

    private viewTokenParams: ViewTokenParams;
    private isRefreshingToken = false;

    constructor(private requestService: RequestService, private limitsService: LimitsService) {}

    updateToken(params: ViewTokenParams): Observable<string> {
        this.viewTokenParams = { ...params };

        const { entityType, entities, filters, compare, benchmarkInfo } = this.viewTokenParams;

        let payloadEntities = EntityHelpers.isItemsSingleGroup(entities)
            ? EntityHelpers.unwrapSingleGroup(entities)
            : entities;

        // BE expect group GUID for comparison saved group (non-benchmark), and group entities otherwise
        if (
            EntityHelpers.isItemsSingleComparisonGroup(entities) &&
            !EntityHelpers.isItemsVirtualGroup(entities) &&
            !benchmarkInfo
        ) {
            payloadEntities = entities;
        }

        const payload = ViewReportHelpers.composeRequestPayload(
            payloadEntities,
            filters,
            entityType,
            benchmarkInfo,
            compare,
        );

        this.limitsService.showLimitPage$.next(false);

        if (params.dashboardId) {
            payload.dashboardId = params.dashboardId;
            payload.params.channel = undefined;
        }

        const token$ = this.requestService
            .post<string>('security/tokens/report', payload, {
                headers: { Accept: 'text/plain' },
                responseType: 'text',
            })
            .pipe(share());

        token$.pipe(first()).subscribe({
            next: (token) => {
                this.viewToken$.next(token);
            },
            error: () => {
                this.reset();
            },
        });

        return token$;
    }

    refreshToken(): Observable<string> {
        if (!this.isRefreshingToken) {
            this.isRefreshingToken = true;
            this.viewToken$.next(null);

            return this.updateToken(this.viewTokenParams).pipe(
                finalize(() => {
                    this.isRefreshingToken = false;
                }),
            );
        } else {
            return this.viewToken$.pipe(
                filter((token) => token != null),
                take(1),
            );
        }
    }

    reset(): void {
        this.viewToken$.next(null);
        this.viewTokenParams = null;
    }

    showViewLimitMessage(interval: INTERVAL_TYPE): void {
        this.limitsService.showLimitMessage([LIMIT_TYPE.VIEW, LIMIT_AREA.GLOBAL, interval]);
    }
}
