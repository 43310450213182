import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

import { WIDGET_STATUS, WidgetInfo, WidgetData } from '@shared/widgets/core/widgets.types';
import { AnalyticsData, ExportItem } from '@shared/components/common-export-button/common-export-button.types';
import { EntityItem } from '../models';
import { WIDGET } from '../enums';
import { WidgetStatus } from '../services/report.service.types';

@Injectable()
export class ReportServiceBase {
    reportReady$: Observable<boolean>;

    widgets: WidgetInfo[];
    widgetsStatus$ = new BehaviorSubject<WidgetStatus[]>([]);

    widgetsReady$ = new BehaviorSubject(false);

    globalExportReady$ = this.widgetsReady$;

    protected get isViewTokenValid(): boolean {
        throw new Error('Getter not implemented.');
    }

    private widgetsReadyLastState: boolean;

    getWidgetUpdateTrigger(_options: unknown): Observable<WidgetData> {
        throw new Error('Method not implemented.');
    }

    getWidgetByType(widgetType: WIDGET, groupId?: number): WidgetInfo {
        return this.widgets.find((v) => v.type === widgetType && (!groupId || v.wrapperGroupId === groupId));
    }

    setCurrentWidgets(widgets: WidgetInfo[]): void {
        this.widgets = widgets;
    }

    setWidgetStatus(widgetId: string, widgetStatus: WIDGET_STATUS): void {
        const widget = this.widgets.find((v) => v.id === widgetId);

        if (!widget) {
            return;
        }

        widget.status = widgetStatus;

        const statuses = this.widgets.map(({ id, type, status }) => ({ id, type, status }));

        this.widgetsStatus$.next(statuses);

        const ready = statuses.reduce(
            (a, v) => a && [WIDGET_STATUS.SUCCESS, WIDGET_STATUS.ERROR].includes(v.status),
            true,
        );

        if (ready !== this.widgetsReadyLastState) {
            this.widgetsReady$.next(ready);
        }

        this.widgetsReadyLastState = ready;
    }

    getGlobalExportFileNameTpl(): string {
        throw new Error('Method not implemented.');
    }

    getWidgetExportFileNameTpl(_widgetName: string, _entities: EntityItem[]): string {
        throw new Error('Method not implemented.');
    }

    trackExportAction(_item: ExportItem, _analyticsData: AnalyticsData): void {
        throw new Error('Method not implemented.');
    }
}
