import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

import { Notification, NotificationOptions, NOTIFICATION_TYPE } from './top-notification-bar.types';
import { MaintenanceService } from '@main/services/maintenance.service';
import { Maintenance } from '@main/services/maintenance.service.types';

@Injectable({ providedIn: 'root' })
export class SharedTopNotificationBarService {
    items: Notification[] = [];

    onAdd = new Subject<Notification>();
    onRemove = new Subject<Notification>();
    onUpdate = new Subject<void>();
    onClear = new Subject<void>();

    constructor(private maintenanceService: MaintenanceService) {
        setTimeout(() => {
            this.checkGlobalNotification();
        });
    }

    hasNotifications(): boolean {
        return this.items.length > 0;
    }

    getNotifications(): Notification[] {
        return this.items;
    }

    add(type: NOTIFICATION_TYPE, message: string, options: Partial<NotificationOptions> = {}): string {
        const existSharedNotification = this.items.find((v) => v.type === type && v.options.shared);

        if (existSharedNotification && options.shared) {
            existSharedNotification.state.sharedCount++;
            return existSharedNotification.id;
        }

        const id = this.generateGUID();

        const defaultOptions: NotificationOptions = {
            theme: 'default',
            spinner: false,
            closable: true,
            shared: false,
        };

        const item: Notification = {
            id,
            type,
            message,
            options: Object.assign(defaultOptions, options),
            state: {
                hidden: false,
                sharedCount: 0,
            },
        };

        this.items.unshift(item);

        this.emitAddEvent(item);

        return id;
    }

    remove(id: string, ignoreShared = false): void {
        const item = this.items.find((v) => v.id === id);

        if (!item) {
            return;
        }

        if (item.state.sharedCount > 0 && !ignoreShared) {
            item.state.sharedCount--;
            return;
        }

        if (item) {
            this.emitRemoveEvent(item);
            this.items.splice(this.items.indexOf(item), 1);
        }
    }

    removeByType(type: NOTIFICATION_TYPE): void {
        const item = this.items.find((v) => v.type === type);

        if (!item) {
            return;
        }

        this.remove(item.id);
    }

    clear(): void {
        this.items = [];
        this.onClear.next();
    }

    private checkGlobalNotification(): void {
        const globalNotificationEnabled = this.maintenanceService.get<boolean>(
            Maintenance.TOP_NOTIFICATION_MESSAGE_ENABLED,
        );
        const globalNotificationMessage = this.maintenanceService.get<string>(
            Maintenance.TOP_NOTIFICATION_MESSAGE_TEXT,
        );

        if (globalNotificationEnabled) {
            this.add(NOTIFICATION_TYPE.MAINTENANCE, globalNotificationMessage, { theme: 'violet', closable: true });
        }
    }

    private emitAddEvent(item: Notification): void {
        this.onAdd.next(item);
        this.onUpdate.next(undefined);
    }

    private emitRemoveEvent(item: Notification): void {
        this.onRemove.next(item);
        this.onUpdate.next(undefined);
    }

    private generateGUID(): string {
        const S4 = (): string =>
            Math.floor((1 + Math.random()) * 0x10000)
                .toString(16)
                .substring(1);

        return S4() + S4();
    }
}
