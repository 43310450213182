import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { AppConfig } from '@app/app.config';
import { viewReportTokenName } from '@app/config';
import { DatasetRequestParams, RequestBody, RequestOptions } from './request.types';
import { RequestDatasetsHelpers } from './request.datasets.helpers';

@Injectable({ providedIn: 'root' })
export class RequestService {
    constructor(private http: HttpClient, private appConfig: AppConfig) {}

    get<T>(endpoint: string, options?: RequestOptions, version?: string): Observable<T> {
        const url = this.link(endpoint, version);

        return this.http.get(url, options) as Observable<any>;
    }

    delete(endpoint: string, options?: RequestOptions, version?: string): Observable<any> {
        const url = this.link(endpoint, version);

        return this.http.delete(url, options);
    }

    post<T>(endpoint: string, payload?: RequestBody, options?: RequestOptions, version?: string): Observable<T> {
        const url = this.link(endpoint, version);

        return this.http.post(url, payload, options) as Observable<any>;
    }

    put<T>(endpoint: string, payload?: RequestBody, options?: RequestOptions, version?: string): Observable<T> {
        const url = this.link(endpoint, version);

        return this.http.put(url, payload, options) as Observable<any>;
    }

    patch<T>(endpoint: string, payload?: RequestBody, options?: RequestOptions, version?: string): Observable<T> {
        const url = this.link(endpoint, version);

        return this.http.patch(url, payload, options) as Observable<any>;
    }

    head(endpoint: string, options?: RequestOptions, version?: string): Observable<any> {
        const url = this.link(endpoint, version);

        return this.http.head(url, options);
    }

    request<T>(method: string, endpoint: string, options?: RequestOptions, version?: string): Observable<T> {
        const url = this.link(endpoint, version);
        return this.http.request(method, url, options) as Observable<any>;
    }

    pureRequest<T>(method: string, url: string, options?: RequestOptions): Observable<T> {
        return this.http.request<T>(method, url, options) as Observable<any>;
    }

    dataset<R>(params: DatasetRequestParams): Observable<R> {
        const { datasetName, customHeaders, labelEntityType, labelArea } = params;
        const headers = {};

        const serializedRequest = RequestDatasetsHelpers.serialize(params);

        if (serializedRequest.formats) {
            headers['X-Type-Format'] = serializedRequest.formats;
        }

        if (labelEntityType && labelArea) {
            headers['X-Widget-Id'] = RequestDatasetsHelpers.formatRequestLabel(labelEntityType, labelArea);
        }

        if (params.useViewToken) {
            headers[viewReportTokenName] = '';
        }

        if (customHeaders) {
            Object.assign(headers, params.customHeaders);
        }

        const url = this.link(`datasets/${datasetName}`);

        return this.http.get<R>(url, { params: serializedRequest.params, headers });
    }

    link(url: string, version?: string): string {
        const { apiUrl, version: apiVersion } = this.appConfig.settings.apiServer;

        return `${apiUrl}${version || apiVersion}/${url}`;
    }
}
