import { inject, Injectable } from '@angular/core';
import { combineLatest, Observable, of } from 'rxjs';
import { first, map, shareReplay, startWith, switchMap } from 'rxjs/operators';
import { cloneDeep } from 'lodash';

import { CurrencyService, DatasetRequestParams, RequestService } from '@shared/services';
import { FiltersValue } from '@shared/services/filters/filters.types';
import { Currency } from '@shared/models';
import { BUSINESS_ENTITY, WIDGET } from '@main/enums';
import { EntityItem } from '@main/models';
import { EntityHelpers } from '@main/helpers/entity.helpers';
import { AdTypeItem } from '@main/services/ad-types.service.types';
import { ReportService } from '@main/core/report.service';
import { SerializeEntitiesIdsOptions } from '@main/helpers/entity.helpers.types';
import { BenchmarkResponseByEntities, RestResponseByEntities } from '@main/models/channel';
import { BenchmarkInfo } from '@main/core/report.types';
import { WidgetsApiService } from './widgets.api-service';
import { CreativesApiService } from '../../top-ads/creatives.api-service';
import { GetCreativesListParams } from '../../top-ads/creatives.service.types';
import { CreativesItem } from '../../top-ads/creatives.model';
import { CreativesHelpers } from '../../top-ads/creatives.helpers';

@Injectable()
export abstract class WidgetsService {
    protected readonly reportService = inject(ReportService);
    protected readonly currencyService = inject(CurrencyService);
    protected readonly widgetsApiService = inject(WidgetsApiService);
    protected readonly creativesApiService = inject(CreativesApiService);
    protected readonly requestService = inject(RequestService);

    getAdTypes(
        entityType: BUSINESS_ENTITY,
        entities: EntityItem[],
        filters: FiltersValue,
        currency: Currency,
        labelArea: WIDGET,
    ): Observable<AdTypeItem[]> {
        const { isBenchmark } = this.reportService;

        return this.widgetsApiService
            .getAdTypes(entityType, entities, filters, currency, isBenchmark, labelArea)
            .pipe(first(), shareReplay(1));
    }

    getTopAdsList(params: GetCreativesListParams): Observable<CreativesItem[]> {
        const {
            entityType,
            entities,
            filters,
            adTypeFilters,
            publishersFilterIds,
            pageIndex,
            pageSize,
            sortBy,
            showAdvertiser,
            entitiesGlobalParentheses = true,
        } = params;
        const { isBenchmark, benchmarkInfo } = this.reportService;

        let creativesListDatasetName = 'creatives';
        let creativesAdvertisersDatasetName = 'creative-advertisers';

        if (isBenchmark && (entities.length > 1 || entities[0].entityType === benchmarkInfo.entityType)) {
            creativesListDatasetName = this.getBenchmarkAdsListDatasetName(entityType);
            creativesAdvertisersDatasetName = this.getBenchmarkCreativesAdvertisersDatasetName(entityType);
        }

        const entitiesIdsPairs = this.getTopAdsEntitiesIds(
            { globalParentheses: entitiesGlobalParentheses, plain: true },
            entities,
        );
        const currency = this.currencyService.currency$.value;

        return this.creativesApiService
            .getCreativesList({
                entityType,
                datasetName: creativesListDatasetName,
                entitiesIdsPairs,
                pageNumber: pageIndex,
                pageSize,
                filters,
                adTypeFilters,
                publishersFilterIds,
                sortBy,
                currency,
            })
            .pipe(
                switchMap((creativesResponse) => {
                    const creativesElements = creativesResponse?.creatives?.elements;

                    const advertisers$ = showAdvertiser
                        ? this.creativesApiService.fetchCreativesAdvertisers(
                              entityType,
                              creativesAdvertisersDatasetName,
                              filters,
                              entitiesIdsPairs,
                              creativesElements,
                          )
                        : of(null);

                    return advertisers$.pipe(
                        map((advertisersResponse) => ({ creativesResponse, advertisersResponse })),
                    );
                }),
                map(({ creativesResponse, advertisersResponse }) => {
                    if (!creativesResponse) {
                        return;
                    }

                    const totalCreativesCount = creativesResponse?.creatives?.count?.total || 0;
                    const result = CreativesHelpers.deserializeCreativesList(creativesResponse, advertisersResponse);

                    result[Symbol.for('total')] = totalCreativesCount;

                    return result;
                }),
            );
    }

    getTopAdsEntitiesIds(
        options?: Partial<SerializeEntitiesIdsOptions>,
        entities = this.reportService.rootEntity.items,
    ): Record<string, string> {
        return EntityHelpers.serializeEntitiesIdsPairs(entities, options);
    }

    getChannelsComparisonData(
        entityType: BUSINESS_ENTITY,
        entities: EntityItem[],
        viewEntityType: BUSINESS_ENTITY,
        globalShare: boolean,
        benchmarkInfo: BenchmarkInfo,
        labelArea: WIDGET,
        filters: FiltersValue,
    ): Observable<any> {
        const { dataset: viewEntityDataset } = EntityHelpers.getEntityInfo(viewEntityType);

        const [regularEntityItems, benchmarkEntityItem] = EntityHelpers.getGroupedBenchmarkItems(
            entities,
            benchmarkInfo,
        );

        const customParams = EntityHelpers.serializeCommonFilters(
            filters.channel,
            filters.period,
            filters.country,
            filters.device,
            filters.taxonomyTopic,
            this.currencyService.currency$.value,
        );

        if (globalShare) {
            customParams.shareMod = 'GLOBAL_ENTITIES_CHANNEL_SHARE';
        }

        const entitiesIdsPairs = EntityHelpers.serializeEntitiesIdsPairs(regularEntityItems);

        const commonDatasetParams: DatasetRequestParams = {
            datasetName: 'channels',
            entitiesIdsPairs,
            useViewToken: true,
            customParams,
            labelEntityType: entityType,
            labelArea,
        };

        const baseDatasetParams = cloneDeep(commonDatasetParams);
        const creativesDatasetParams = cloneDeep(commonDatasetParams);

        baseDatasetParams.customParams.fetch = `${viewEntityDataset}.count`;
        creativesDatasetParams.customParams.fetch = 'creatives.count';

        let benchmarkStreams: [Observable<any>, Observable<any>] = [of(null), of(null)];

        if (benchmarkInfo) {
            const benchmarkIdsPairs = EntityHelpers.serializeEntitiesIdsPairs([benchmarkEntityItem]);
            const benchmarkDatasetName = this.getBenchmarkChannelsDatasetName(entityType);

            const benchmarkDatasetParams: DatasetRequestParams = {
                datasetName: benchmarkDatasetName,
                entitiesIdsPairs: benchmarkIdsPairs,
                useViewToken: true,
                customParams,
                labelEntityType: benchmarkInfo.entityType,
                labelArea,
            };

            const benchmarkBaseParams = cloneDeep(benchmarkDatasetParams);
            const benchmarkCreativeParams = cloneDeep(benchmarkDatasetParams);

            benchmarkBaseParams.customParams.fetch = `${viewEntityDataset}.count`;
            benchmarkCreativeParams.customParams.fetch = 'creatives.count';

            benchmarkStreams = [
                this.requestService.dataset<RestResponseByEntities>(benchmarkBaseParams),
                this.requestService.dataset<RestResponseByEntities>(benchmarkCreativeParams),
            ];
        }

        const requests = [
            this.requestService.dataset<RestResponseByEntities>(baseDatasetParams).pipe(startWith(null)),
            this.requestService.dataset<RestResponseByEntities>(creativesDatasetParams).pipe(startWith(null)),
            ...benchmarkStreams,
        ];

        return combineLatest(requests).pipe(
            map(([baseResponse, creativesResponse, benchmarkBaseResponse, benchmarkCreativesResponse]) => ({
                baseResponse,
                creativesResponse,
                benchmarkBaseResponse,
                benchmarkCreativesResponse,
            })),
            shareReplay(),
        );
    }

    getChannelsBenchmarkComparisonData(
        entityType: BUSINESS_ENTITY,
        entities: EntityItem[],
        filters: FiltersValue,
        currency: Currency,
        viewEntityType: BUSINESS_ENTITY,
    ): Observable<BenchmarkResponseByEntities> {
        const entitiesIdsPairs = EntityHelpers.serializeEntitiesIdsPairs(entities);

        const datasetForBenchmark = {
            [BUSINESS_ENTITY.Advertiser]: 'benchmark-advertisers-sov',
            [BUSINESS_ENTITY.Brand]: 'benchmark-brands-sov',
        };

        const datasetName = datasetForBenchmark[entityType];

        if (!datasetName) {
            throw new Error(`No dataset name for benchmark comparison for entity type: ${entityType}`);
        }

        const { dataset: viewEntityDataset } = EntityHelpers.getEntityInfo(viewEntityType);

        const datasetParams: DatasetRequestParams = {
            datasetName,
            entitiesIdsPairs,
            useViewToken: true,
            customParams: EntityHelpers.serializeFiltersParams(filters, currency),
            labelEntityType: this.reportService.benchmarkInfo.entityType,
            labelArea: WIDGET.COMPARISON_SOV,
        };

        datasetParams.customParams.fetch = `creatives.count,${viewEntityDataset}.count`;

        return this.requestService.dataset<BenchmarkResponseByEntities>(datasetParams);
    }

    private getBenchmarkChannelsDatasetName(entityType: BUSINESS_ENTITY): string {
        switch (entityType) {
            case BUSINESS_ENTITY.Advertiser:
                return 'benchmark-advertisers-channels';
            case BUSINESS_ENTITY.Brand:
                return 'benchmark-brands-channels';
            default:
                throw new Error(`No benchmark channels dataset name for entity type: ${entityType}`);
        }
    }

    private getBenchmarkAdsListDatasetName(entityType: BUSINESS_ENTITY): string {
        switch (entityType) {
            case BUSINESS_ENTITY.Advertiser:
                return 'benchmark-advertisers-creatives';
            case BUSINESS_ENTITY.Brand:
                return 'benchmark-brands-creatives';
            default:
                throw new Error(`No benchmark ads list dataset name for entity type: ${entityType}`);
        }
    }

    private getBenchmarkCreativesAdvertisersDatasetName(entityType: BUSINESS_ENTITY): string {
        switch (entityType) {
            case BUSINESS_ENTITY.Advertiser:
                return 'benchmark-advertisers-creative-advertisers';
            case BUSINESS_ENTITY.Brand:
                return 'benchmark-brands-creative-advertisers';
            default:
                throw new Error(`Unsupported entity type: ${entityType}`);
        }
    }
}
