import * as _ from 'lodash';
import { Injectable } from '@angular/core';
import { combineLatest, Observable } from 'rxjs';
import { finalize, first, map } from 'rxjs/operators';

import { NOTIFICATION_TYPE } from '@app/modules/top-notification/top-notification-bar.types';
import { SharedTopNotificationBarService } from '@app/modules/top-notification/top-notification-bar.service';
import {
    DOWNLOAD_FORMAT,
    DOWNLOAD_REPORT_TYPE,
    DOWNLOAD_STATUS,
    DOWNLOAD_TYPE,
    DownloadParams,
    IDownloadItem,
    IDownloadsStatus,
    IDownloadsStatusCounters,
} from '@shared/services/download/download.service.types';
import { AccountService } from '@shared/services/account.service';
import { FEATURE_NAME_ENUM } from '@shared/enums';
import { CurrencyService, ReportSettingsService } from '@shared/services';
import { CommonHelpers } from '@shared/helpers/common.helpers';
import { IUserFeatureCounter } from '@shared/interfaces/IUserFeatures';
import { EntityHelpers } from '@main/helpers/entity.helpers';
import { EXPORT_FORMAT, ExportStatus } from './export.service.types';
import { DownloadService } from '@shared/services/download/download.service';
import { FiltersValue } from '@shared/services/filters/filters.types';
import { ReportService } from '@main/core/report.service';
import { BUSINESS_ENTITY, INTERVAL_TYPE, WIDGET } from '@main/enums';
import { LimitsService } from '@main/services/limits.service';
import { LIMIT_TYPE } from '@main/components/limits-notification/limits-notification.config';
import { LimitsHelpers } from '@main/helpers/limits.helpers';

export interface ExportOptions {
    format?: EXPORT_FORMAT;
    downloadType: DOWNLOAD_TYPE;
    widgetType?: WIDGET;
    setReportType?: boolean;
    entityType?: BUSINESS_ENTITY;
    tableReportOnly?: boolean;
    fileNameTpl: string;
    criteria: Record<string, unknown>;
    filters?: FiltersValue;
    customParams?: Record<string, unknown>;
}

@Injectable()
export class MainExportService {
    constructor(
        private downloadService: DownloadService,
        private currencyService: CurrencyService,
        private accountService: AccountService,
        private notificationService: SharedTopNotificationBarService,
        private reportService: ReportService,
        private reportSettingsService: ReportSettingsService,
        private limitsService: LimitsService,
    ) {
        this.initSubscriptions();
    }

    export(options: ExportOptions): Observable<any> {
        const fileName = CommonHelpers.finalizeExportFileNameTpl(options.fileNameTpl);
        const currency = this.currencyService.currency$.getValue();
        const subBrandsFlag = this.reportSettingsService.brands.showSubBrands$.getValue();

        const serializedFilters = options.filters
            ? EntityHelpers.serializeFiltersParams(options.filters, null, { export: true })
            : {};

        const serializedCurrency = EntityHelpers.serializeCurrency(currency);

        const downloadParams: DownloadParams = {
            type: options.downloadType,
            widgetType: options.widgetType,
            name: fileName,
            criteria: {
                ...serializedFilters,
                currency: serializedCurrency,
                ...(options.criteria || {}),
            },
            ...(options.customParams || {}),
            ...(subBrandsFlag ? { subBrands: true } : {}),
        };

        if (options.setReportType) {
            const isBenchmark = this.reportService.isBenchmark;

            downloadParams.reportType = this.mapReportType(options.entityType, isBenchmark);
        }

        if (options.format) {
            downloadParams.format = this.serializeFormat(options.format);
        }

        if (options.tableReportOnly) {
            downloadParams.tableReportOnly = true;
        }

        return new Observable<IDownloadItem>((observer) => {
            this.notificationService.add(NOTIFICATION_TYPE.COMMON_EXPORT, 'Generating export', {
                theme: 'blue',
                spinner: true,
                shared: true,
            });

            this.downloadService
                .download(downloadParams)
                .pipe(
                    finalize(() => {
                        this.notificationService.removeByType(NOTIFICATION_TYPE.COMMON_EXPORT);
                    }),
                )
                .subscribe(
                    (res) => {
                        observer.next(res);
                        observer.complete();
                    },
                    (err) => {
                        observer.error(err);
                    },
                );
        });
    }

    getExportStatus(
        downloadType: DOWNLOAD_TYPE | DOWNLOAD_TYPE[],
        featureName: FEATURE_NAME_ENUM,
    ): Observable<ExportStatus> {
        const $accountFeatures = this.accountService.$features.pipe(first());
        const $downloadsStatus = this.downloadService.downloadsStatus$;

        return combineLatest([$accountFeatures, $downloadsStatus]).pipe(
            map(([accountFeatures, downloadsStatus]) => {
                const featureData = accountFeatures && accountFeatures[featureName];
                const exportStatus = this.buildExportStatus(downloadType, downloadsStatus, featureData);
                const exceededInterval = LimitsHelpers.getExpiredLimitInterval(exportStatus);

                if (exceededInterval) {
                    const limitArea = LimitsHelpers.getLimitsArea(featureName);
                    this.limitsService.showLimitMessage([LIMIT_TYPE.EXPORT, limitArea, exceededInterval]);
                }

                return exportStatus;
            }),
        );
    }

    private initSubscriptions(): void {
        this.downloadService.startedDownloads$.subscribe(() => {
            this.notificationService.add(NOTIFICATION_TYPE.COMMON_EXPORT, 'Generating export', {
                theme: 'blue',
                spinner: true,
                shared: true,
            });
        });

        this.downloadService.completedDownloads$.subscribe(() =>
            this.notificationService.removeByType(NOTIFICATION_TYPE.COMMON_EXPORT),
        );
    }

    getDownloadTypeStatus(downloadsStatus: IDownloadsStatus, type: DOWNLOAD_TYPE, status: DOWNLOAD_STATUS) {
        const downloads = Array.isArray(downloadsStatus.downloads) ? downloadsStatus.downloads : [];
        const downloadCounters = downloads
            .filter((download) => download.type === type)
            .reduce((acc, curr) => [...acc, curr.counters], <IDownloadsStatusCounters[]>[])
            .flat();

        const statusCounters = downloadCounters.filter((counter) => counter.status === status);

        const mappedIntervals: Record<INTERVAL_TYPE, number> = statusCounters.length
            ? statusCounters
                  .flatMap((counter) => counter?.intervals || [])
                  .reduce((acc, curr) => ({ ...acc, [curr.interval]: curr.count }), <Record<INTERVAL_TYPE, number>>{})
            : null;

        return mappedIntervals;
    }

    private buildExportStatus(
        downloadType: DOWNLOAD_TYPE | DOWNLOAD_TYPE[],
        downloadsStatus: IDownloadsStatus,
        featureData: IUserFeatureCounter,
    ): ExportStatus {
        const downloadTypes = Array.isArray(downloadType) ? downloadType : [downloadType];

        const finishedDownloadsCount = downloadTypes.reduce((acc, type) => {
            const statuses = this.getDownloadTypeStatus(downloadsStatus, type, DOWNLOAD_STATUS.READY);
            return _.assignInWith(acc, statuses, (srcVal, objVal) => (srcVal ?? 0) + (objVal ?? 0));
        }, <Record<INTERVAL_TYPE, number>>{});

        const inProgressDownload = downloadsStatus.processing?.find((v) => downloadTypes.includes(v.type));

        return {
            inProgress: !!inProgressDownload,
            currentExportId: inProgressDownload?.downloadId,
            downloadsCount: finishedDownloadsCount,
            limits: {
                downloadsCount: featureData?.maxCountLimit,
                periodMonthsCount: featureData?.maxPeriodMonthsLimit,
            },
        };
    }

    private serializeFormat(exportFormat: EXPORT_FORMAT): DOWNLOAD_FORMAT {
        const mapping = {
            WIDGET_TSV: DOWNLOAD_FORMAT.TSV,
            WIDGET_EXCEL: DOWNLOAD_FORMAT.XLSX,
        };

        const downloadFormat = mapping[exportFormat];

        if (!downloadFormat) {
            console.warn('Unknown download format');
        }

        return downloadFormat;
    }

    private mapReportType(entityType: BUSINESS_ENTITY, isBenchmark: boolean): DOWNLOAD_REPORT_TYPE {
        const defaultMapping = {
            [BUSINESS_ENTITY.Advertiser]: DOWNLOAD_REPORT_TYPE.ADVERTISER_REPORT,
            [BUSINESS_ENTITY.Publisher]: DOWNLOAD_REPORT_TYPE.PUBLISHER_REPORT,
            [BUSINESS_ENTITY.Campaign]: DOWNLOAD_REPORT_TYPE.CAMPAIGN_REPORT,
            [BUSINESS_ENTITY.Category]: DOWNLOAD_REPORT_TYPE.CATEGORY_REPORT,
            [BUSINESS_ENTITY.Keyword]: DOWNLOAD_REPORT_TYPE.CAMPAIGN_REPORT,
            [BUSINESS_ENTITY.Brand]: DOWNLOAD_REPORT_TYPE.BRAND_REPORT,
        };

        const benchmarkMapping = {
            [BUSINESS_ENTITY.Advertiser]: DOWNLOAD_REPORT_TYPE.BENCHMARK_ADVERTISERS_REPORT,
            [BUSINESS_ENTITY.Brand]: DOWNLOAD_REPORT_TYPE.BENCHMARK_BRANDS_REPORT,
        };

        return isBenchmark ? benchmarkMapping[entityType] : defaultMapping[entityType];
    }
}
