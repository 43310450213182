import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { isNil, omitBy } from 'lodash';

import { StorageService } from '@app/core';
import { WidgetInfo } from '@shared/widgets/core/widgets.types';
import { BUSINESS_ENTITY, WIDGET } from '@main/enums';
import { FullscreenNavigationExtraParams } from '@main/shared/widget-full-screen-view';
import { ReportService } from '@main/core/report.service';
import { NavigationService } from '@main/services/navigation.service';
import { WIDGET_VIEW_MODE } from '@main/widgets/shared/widget.types';
import { distinctUntilChanged, map } from 'rxjs';

@UntilDestroy()
@Injectable()
export class FullscreenViewService {
    private readonly reportUrlKey = 'reportUrl';

    private get reportUrl(): string {
        return this.storageService.get<string>(this.reportUrlKey);
    }

    isFullscreenView$ = this.router.events.pipe(
        map(() => this.isFullscreenView),
        distinctUntilChanged(),
        untilDestroyed(this),
    );

    get isFullscreenView(): boolean {
        return !!this.widget;
    }

    get widget(): WIDGET {
        return this.navigationService.getQueryParam('widget');
    }

    constructor(
        private router: Router,
        private storageService: StorageService,
        private reportService: ReportService,
        private navigationService: NavigationService,
    ) {}

    get showBackButton(): boolean {
        return !!this.reportUrl;
    }

    navigateToFullScreenWidget(widgetInfo: WidgetInfo, extraParams: FullscreenNavigationExtraParams = {}): void {
        const params = omitBy(extraParams, isNil);
        const { rootEntity } = this.reportService;

        if (
            rootEntity?.isBenchmark() &&
            rootEntity?.benchmarkEntityType === BUSINESS_ENTITY.Category &&
            rootEntity?.isVirtual()
        ) {
            params.category = this.reportService.benchmarkInfo.entityId;
        }

        const widget = widgetInfo.data.viewMode === WIDGET_VIEW_MODE.DASHBOARD ? widgetInfo.id : widgetInfo.type;

        const queryParams = { widget, ...params };

        const cleanedUrl = this.router.url.replace(/\?.*$/, '');

        void this.router.navigate([location.pathname], { queryParams });

        this.storageService.set(this.reportUrlKey, cleanedUrl);
    }

    goBackToReport(): void {
        void this.router.navigateByUrl(this.reportUrl);
    }
}
