import { SystemColor } from '../../models';

export const systemColors: Record<SystemColor, string> = {
    [SystemColor.ColorDataPurple]: '#9552e0',
    [SystemColor.ColorDataTeal]: '#01cec9',
    [SystemColor.ColorDataYellow]: '#fdca6e',
    [SystemColor.ColorDataPink]: '#ff66cc',
    [SystemColor.ColorDataGraphite]: '#3d4252',
    [SystemColor.ColorDataMint]: '#74c66c',
    [SystemColor.ColorDataCoral]: '#d9715b',
    [SystemColor.ColorDataOlive]: '#9f9560',
    [SystemColor.ColorDataAzure]: '#47b4eb',
    [SystemColor.ColorDataPeach]: '#ff8b94',
    [SystemColor.ColorNeutral10]: '#f5f5fa',
    [SystemColor.ColorNeutral30]: '#ccccd7',
    [SystemColor.ColorNeutral40]: '#9494a1',
};
